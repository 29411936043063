import React from 'react'

import { useCompendiumContext } from './ContextProvider/CompendiumContext'

const CompendiumContent = () => {
  const { sectionContent } = useCompendiumContext()
  return <>{sectionContent}</>
}

export default CompendiumContent
