import React, { useMemo } from 'react'

import { FormControl, FormErrorMessage, Stack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import { districtOptions } from '../../../../constants'
import { FloatingFormField } from '../../../ui/forms'
import { useTagsContext } from '../ContextProvider/TagsContext'

const CreateTagFormInputs = () => {
  const user = useSelector(state => state.auth.user)
  const { tags } = useTagsContext()
  const { setFieldValue, values, errors, touched } = useFormikContext()

  const validateLabel = e => {
    if (
      tags.some(
        t => t.label === e.toUpperCase() && t.district === values.district
      )
    )
      return 'Tag with this label already exists'
  }

  const districts = useMemo(
    () =>
      districtOptions.map(district => ({
        value: district.padStart(2, '0'),
        label: district,
      })),
    [districtOptions]
  )

  return (
    <Stack>
      <FloatingFormField
        field='label'
        label='Tag label'
        isInvalid={errors.label && touched.label}
        errors={errors.label}
        validate={validateLabel}
      />
      {user.is_admin && (
        <FormControl name='district'>
          <Select
            id='district'
            name='district'
            placeholder='District (Admin only)'
            isSearchable
            isClearable
            options={districts}
            useBasicStyles
            isInvalid={errors.district && touched.district}
            onChange={option => {
              if (option?.value) setFieldValue('district', option.value)
              else setFieldValue('district', '')
            }}
          />
          <FormErrorMessage>{errors.district}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  )
}

export default CreateTagFormInputs
