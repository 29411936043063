import React, { useRef } from 'react'

import {
  Flex,
  Icon,
  Stack,
  Text,
  Spacer,
  IconButton,
  Box,
  Tooltip,
  ButtonGroup,
  useBoolean,
  ListItem,
  UnorderedList,
  Collapse,
  useDisclosure,
} from '@chakra-ui/react'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  // EyeIcon,
  // EyeSlashIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import { DeleteActivityDialog } from './actionDialogs'
import ActivityForm from './ActivityForm'
import { activityCategoryIcons } from '../../../constants/statuses'
import { FormattedDate, Person } from '../../ui'
import { compareDates, renderTextLines } from '../../utils'
const ActivityItem = ({
  category,
  created_at,
  description,
  details,
  id,
  //history_version, // to do!
  last_updated,
  //show_public, // for constituent release
  staff,
}) => {
  const user = useSelector(state => state.auth.user)
  const caseworkInstance = useSelector(
    state => state.casework.casework_instance
  )

  const [showDetails, setShowDetails] = useBoolean()
  const [showEdit, setShowEdit] = useBoolean()
  const editButtonRef = useRef(null)

  const {
    isOpen: confirmDeleteIsOpen,
    onOpen: confirmDeleteOnOpen,
    onClose: confirmDeleteOnClose,
  } = useDisclosure()

  const handleShowHide = e => {
    if (!editButtonRef?.current?.contains(e.target) && details.length > 0) {
      setShowDetails.toggle()
    }
  }

  const Date = () => {
    if (last_updated && !compareDates(last_updated, created_at)) {
      return (
        <Stack direction='row'>
          <FormattedDate date={last_updated} hideAge />
          <Text> (Edited) </Text>
        </Stack>
      )
    }
    return <FormattedDate date={created_at} hideAge />
  }

  if (showEdit) {
    return (
      <ActivityForm
        initialValues={{ description: description, category: category, id: id }}
        handleFormSubmit={setShowEdit.off}
        isEditing
      />
    )
  }

  return (
    <>
      <Flex
        align='top'
        onClick={handleShowHide}
        _hover={details.length > 0 && { cursor: 'pointer' }}
      >
        <Stack direction='row' align='top' w='100%'>
          <Icon as={activityCategoryIcons[category]} boxSize={5} mt={0.5} />
          <Stack w='100%'>
            <Box overflowY='auto'>{renderTextLines(description)}</Box>
            <Flex direction='column'>
              <Text color='gray.500' as='em'>
                <Date />
              </Text>
              <Flex direction='row' color='gray.500' as='em'>
                <Text> Staff:&nbsp;</Text>
                <Person
                  name={`${staff.first_name} ${staff.last_name[0]}.`}
                  link={`/directory/staff/${staff.id}`}
                  hideAvatar
                />
              </Flex>
            </Flex>
          </Stack>
        </Stack>
        <Spacer />
        <ButtonGroup>
          <Stack direction='column'>
            {caseworkInstance?.status !== 'Archived' && (
              <>
                {category !== 'Internal' && staff.id === user.id && (
                  <IconButton
                    icon={<PencilSquareIcon />}
                    size='xs'
                    variant='ghost'
                    ref={editButtonRef}
                    onClick={setShowEdit.on}
                  />
                )}
                {staff.id === user.id && category !== 'Internal' && (
                  <IconButton
                    size='xs'
                    icon={<Icon as={TrashIcon} fontSize='22' />}
                    w='10%'
                    variant='ghost'
                    colorScheme='red'
                    onClick={confirmDeleteOnOpen}
                  />
                )}
              </>
            )}
            {details.length > 0 && (
              <Tooltip label='Expand details'>
                <Icon
                  as={showDetails ? ChevronUpIcon : ChevronDownIcon}
                  size='xs'
                  variant='unstyled'
                />
              </Tooltip>
            )}

            {/* <Tooltip
            label={
              show_public
                ? 'This is visible to the constituent'
                : 'This is only visible to council staff. Click to enable visibility to constituent.'
            }
          >
            <IconButton
              icon={show_public ? <EyeIcon /> : <EyeSlashIcon />}
              size='xs'
              variant='unstyled'
            />
          </Tooltip> */}
          </Stack>
        </ButtonGroup>
      </Flex>
      <Collapse in={showDetails} animateOpacity>
        <Box p={3} pb={0}>
          <UnorderedList>
            {details?.map((d, index) => (
              <ListItem key={index}>{renderTextLines(d)}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Collapse>
      <DeleteActivityDialog
        isOpen={confirmDeleteIsOpen}
        onClose={confirmDeleteOnClose}
        activityId={id}
        activityText={description}
      />
    </>
  )
}

export default ActivityItem
