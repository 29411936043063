import React from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Hide,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useColorModeValue,
  useToken,
} from '@chakra-ui/react'

const CaseworkFormStepper = ({
  steps,
  activeStep,
  onChangeStep,
  onNext,
  onPrevious,
}) => {
  const [lightColor, darkColor] = useToken('colors', ['blue.500', 'blue.200'])
  const stepUnderlineBorderColor = useColorModeValue(lightColor, darkColor)

  return (
    <>
      <Stepper index={activeStep}>
        {steps?.map((step, index) => {
          const titleStyles = {
            fontWeight: activeStep === index ? 'bold' : '',
            borderBottom:
              activeStep === index
                ? `2px solid ${stepUnderlineBorderColor}`
                : '',
          }

          return (
            <Step key={index} onClick={() => onChangeStep(index)}>
              <StepIndicator cursor='pointer'>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink='0'>
                <Hide below='md'>
                  <StepTitle {...titleStyles}>{step.title}</StepTitle>
                </Hide>
              </Box>
              <StepSeparator />
            </Step>
          )
        })}
      </Stepper>
      <Box w='100%' mt={4}>
        <Flex align='center'>
          <ChevronLeftIcon cursor='pointer' boxSize={7} onClick={onPrevious} />
          <Spacer />
          <Text as='b' casing='uppercase'>
            {steps[activeStep].description}
          </Text>
          <Spacer />
          <ChevronRightIcon cursor='pointer' boxSize={7} onClick={onNext} />
        </Flex>
      </Box>
    </>
  )
}

export default CaseworkFormStepper
