import React, { useCallback, useMemo } from 'react'

import { FieldArray, useField } from 'formik'

import { ShowHideFormField } from '../../../../ui'

const BaseArrayField = ({ label, field, children }) => {
  const [formikField, , helpers] = useField(field)

  const { value } = formikField
  const { setValue } = helpers

  const defaultShow = useMemo(() => value?.length > 0, [value])

  const onSelect = useCallback((option, push) => push(option), [])
  const onRemove = useCallback(
    removeIndex => {
      setValue(value.toSpliced(removeIndex, 1))
    },
    [setValue, value]
  )
  const onClear = useCallback(() => {
    setValue([])
  }, [setValue])

  return (
    <FieldArray name={field}>
      {({ push }) => (
        <ShowHideFormField heading={label} defaultShow={defaultShow}>
          {typeof children === 'function'
            ? children({
                onSelect: option => onSelect(option, push),
                onRemove,
                onClear,
              })
            : children}
        </ShowHideFormField>
      )}
    </FieldArray>
  )
}

export default BaseArrayField
