import React from 'react'

import {
  Divider,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Stack,
  useBoolean,
} from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import {
  BookTextIcon,
  CaseUpperIcon,
  ClipboardPenIcon,
  PanelLeftCloseIcon,
  PanelRightCloseIcon,
  TagsIcon,
} from 'lucide-react'

import SectionButton from './SectionButton'

const Sidebar = () => {
  const [expanded, setExpanded] = useBoolean(true)
  return (
    <Flex
      w={expanded ? '300px' : '50px'}
      transition='width 0.8s ease'
      transitionDelay='0.2s ease'
      h='100%'
      direction='column'
      align='start'
      gap={3}
    >
      <Flex align='center' w='100%' gap={3} h='40px'>
        <AnimatePresence>
          {expanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ enter: { delay: 0.5 }, exit: { delay: 0.5 } }}
            >
              <Heading fontSize='2xl'>Compendium</Heading>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>

      <Divider />
      <Stack color='nyccBlue.800' w='100%'>
        <SectionButton icon={<ClipboardPenIcon />} hideText={!expanded}>
          Topics
        </SectionButton>
        <SectionButton icon={<TagsIcon />} hideText={!expanded}>
          Tags
        </SectionButton>
        <SectionButton icon={<CaseUpperIcon />} hideText={!expanded}>
          Glossary
        </SectionButton>
        <SectionButton icon={<BookTextIcon />} hideText={!expanded}>
          Manual
        </SectionButton>
      </Stack>
      <Spacer />

      <IconButton
        icon={expanded ? <PanelLeftCloseIcon /> : <PanelRightCloseIcon />}
        variant='ghost'
        colorScheme='gray'
        color='nyccBlue.800'
        onClick={setExpanded.toggle}
      />
    </Flex>
  )
}

export default Sidebar
