import React, { useCallback, useMemo } from 'react'

import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tooltip,
} from '@chakra-ui/react'
import { BarsArrowDownIcon } from '@heroicons/react/24/solid'
const AssignedCaseworkSortMenu = ({ orderBy, setOrderBy }) => {
  const handleOrderChange = useCallback(
    value => {
      const category = orderBy[0] === '-' ? orderBy.slice(1) : orderBy
      setOrderBy(value + category)
    },
    [orderBy, setOrderBy]
  )
  const handleCategoryChange = useCallback(
    value => {
      const updatedOrderBy = `${orderBy[0] === '-' ? '-' : ''}${value}`
      setOrderBy(updatedOrderBy)
    },
    [orderBy, setOrderBy]
  )
  const categoryValue = useMemo(
    () =>
      orderBy.length > 0 && orderBy[0] === '-' ? orderBy.slice(1) : orderBy,
    [orderBy]
  )

  const orderByValue = useMemo(
    () => (orderBy.length > 0 && orderBy[0] === '-' ? '-' : ''),
    [orderBy]
  )

  return (
    <Menu closeOnSelect={false}>
      <Tooltip label='Sorting options'>
        <MenuButton
          as={IconButton}
          icon={<BarsArrowDownIcon />}
          p={1}
          variant='unstyled'
          aria-label='Sorting options for casework'
        />
      </Tooltip>
      <MenuList>
        <MenuOptionGroup
          title='Order'
          type='radio'
          value={orderByValue}
          onChange={value => handleOrderChange(value)}
        >
          <MenuItemOption value=''> Ascending </MenuItemOption>
          <MenuItemOption value='-'> Descending </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          title='Category'
          type='radio'
          value={categoryValue}
          onChange={value => handleCategoryChange(value)}
        >
          <MenuItemOption value='case_num'> Case number </MenuItemOption>
          <MenuItemOption value='status'> Status </MenuItemOption>
          <MenuItemOption value='priority'> Priority </MenuItemOption>
          <MenuItemOption value='last_updated'> Last Updated </MenuItemOption>
          <MenuItemOption value='constituent__name'>
            Constituent Name
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

export default AssignedCaseworkSortMenu
