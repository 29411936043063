import React, { useMemo, useState } from 'react'

import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'

import TagRow from './TagRow'
import { sortValues, toTitleCase } from '../../../../utils'
import { Loading } from '../../../ui'
import { useTagsContext } from '../ContextProvider/TagsContext'

const TagTable = ({ tags }) => {
  const { isLoading, mode } = useTagsContext()
  const [sortAsc, setSortAsc] = useState(false)
  const [sortCategory, setSortCategory] = useState('tag')

  const selectSort = category => {
    if (sortCategory === category) setSortAsc(!sortAsc)
    else {
      setSortCategory(category)
      setSortAsc(true)
    }
  }
  const sortedTags = useMemo(() => {
    return sortValues(tags, sortCategory, sortAsc)
  }, [tags, sortCategory, sortAsc])

  function SortableTh({ category, children, ...props }) {
    return (
      <Th cursor='pointer' onClick={() => selectSort(category)} {...props}>
        <Flex>
          <Text>{children}</Text>
          {sortCategory === category &&
            (sortAsc ? (
              <ChevronUpIcon size='16' />
            ) : (
              <ChevronDownIcon size='16' />
            ))}
        </Flex>
      </Th>
    )
  }
  if (isLoading) return <Loading />
  return (
    <TableContainer>
      <Table>
        <Thead h='40px'>
          <Tr>
            <SortableTh category='label'>Tag</SortableTh>
            <SortableTh category='count' w='150px'>
              {toTitleCase(mode)}
            </SortableTh>

            <Th w='200px'>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedTags.map(tag => (
            <TagRow key={`tag-${tag.id}`} {...tag} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default TagTable
