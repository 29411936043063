import React from 'react'

import { Box, Button } from '@chakra-ui/react'
import { Form, useFormikContext } from 'formik'

import { ConstituentFormInputs } from '../form'

const MergeConstituentForm = () => {
  const { isSubmitting } = useFormikContext()

  return (
    <Form>
      <Box overflowY='auto' h='80vh' pb={8}>
        <ConstituentFormInputs />
      </Box>

      <Box mx='auto' my={2} position='sticky' bottom={0} px={3}>
        <Button
          w='100%'
          size='lg'
          isLoading={isSubmitting}
          type='submit'
          colorScheme='green'
          variant='solid'
        >
          Merge Constituents
        </Button>
      </Box>
    </Form>
  )
}

export default MergeConstituentForm
