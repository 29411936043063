import React, { useState } from 'react'

import {
  Box,
  Card,
  CardBody,
  Flex,
  Spacer,
  Text,
  Center,
  useColorMode,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts'

import RecolorButton from './RecolorButton'
import ReportSectionHeading from './ReportSectionHeading'
import { generateShades } from '../../utils'

const DateBreakdown = ({ dateBreakdown }) => {
  if (!dateBreakdown || dateBreakdown.length === 0) return <></>
  const { colorMode } = useColorMode()
  const [colors, setColors] = useState(generateShades(3, colorMode))
  const altColor = useColorModeValue('black', '#fff')

  const legendText = value => {
    return <Text color={altColor}>{value}</Text>
  }

  const tooltipBox = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      const opened = payload[0]
      const closed = payload[1]
      const updated = payload[2]

      return (
        <Card bg='white' variant='outline'>
          <CardBody color='black'>
            <Text>{label}</Text>
            <Stack direction='row' align='center'>
              <Box h={3} w={3} bg={opened.color} />
              <Text>{opened.value}&nbsp;casework opened</Text>
            </Stack>
            <Stack direction='row' align='center'>
              <Box h={3} w={3} bg={closed.color} />
              <Text>{closed.value}&nbsp;casework closed</Text>
            </Stack>
            <Stack direction='row' align='center'>
              <Box h={3} w={3} bg={updated.color} />
              <Text>{updated.value}&nbsp;casework updated</Text>
            </Stack>
          </CardBody>
        </Card>
      )
    }
    return null
  }

  return (
    <Card h='100%' w='100%'>
      <CardBody>
        <Flex align='center'>
          <ReportSectionHeading>Date Breakdown</ReportSectionHeading>
          <Spacer />
          <RecolorButton setColors={setColors} numShades={2} />
        </Flex>
        <Center py={3} h='100%'>
          <BarChart width={600} height={400} data={dateBreakdown}>
            <XAxis dataKey='label' type='category' stroke={altColor} />
            <YAxis stroke={altColor} />
            <Tooltip content={tooltipBox} />
            <Legend formatter={legendText} />
            <Bar dataKey='opened' fill={colors[0]} />
            <Bar dataKey='closed' fill={colors[1]} />
            <Bar dataKey='updated' fill={colors[2]} />
          </BarChart>
        </Center>
      </CardBody>
    </Card>
  )
}

export default DateBreakdown
