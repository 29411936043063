import React, { useCallback, useMemo } from 'react'

import { Box, Checkbox } from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import { formatDistrict } from '../../../../../utils'

const DistrictLockField = ({ field = 'visible_to_district_only' }) => {
  const user = useSelector(state => state.auth.user)

  const { values, setFieldValue } = useFormikContext()
  const [formikField] = useField(field)

  const showDistrictLock = useMemo(
    () =>
      !values.district || values.district === user.district || user.is_admin,
    [values.district, user.district, user.is_admin]
  )
  const handleDistrictLock = useCallback(
    e => setFieldValue(field, e.target.checked),
    [setFieldValue, field]
  )

  return (
    <Box>
      {showDistrictLock && (
        <Checkbox
          id={`constituent-district-lock-${field}`}
          isChecked={formikField.value}
          onChange={handleDistrictLock}
        >
          Set visible to District {formatDistrict(user.district)} Only
        </Checkbox>
      )}
    </Box>
  )
}

export default DistrictLockField
