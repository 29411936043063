import React, { useEffect, useMemo } from 'react'

import { Box, HStack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import CaseworkSortMenu from './CaseworkSortMenu'
import {
  districtOptions,
  intakeMethodOptions,
  priorityOptions,
  statusOptions,
} from '../../../constants'
import { loadAgenciesData } from '../../../redux/features/agenciesSlice'
import { setCaseworkFilters } from '../../../redux/features/caseworkSlice'
import { AdvancedFilterInput, FilterInput, FilterMenuBase } from '../../ui'
import { ConstituentSelect, StaffSelect, TopicSelect } from '../../ui/select'
import CaseworkTagSelect from '../../ui/select/CaseworkTagSelect'

const CaseworkFilterMenu = ({ isOpen, onClose, filterButtonRef }) => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  const tabIndex = useSelector(state => state.casework.tab_index)
  const agencyOptions = useSelector(state => state.agencies.data)
  const topics = useSelector(state => state.casework.topics) || []

  useEffect(() => {
    dispatch(loadAgenciesData())
  }, [])

  const issueOptions = useMemo(
    () => topics?.map(topic => topic.issue).filter(issue => !!issue) || [],
    [topics]
  )

  return (
    <FilterMenuBase
      heading='Casework Filters'
      onClose={onClose}
      isOpen={isOpen}
      buttonRef={filterButtonRef}
      store='casework'
      sortMenu={<CaseworkSortMenu />}
    >
      {tabIndex === 0 && (
        <FilterInput
          label='Status'
          filterKey='status__in'
          store='casework'
          placeholder='Filter by status'
          options={statusOptions}
        />
      )}
      <FilterInput
        label='Priority'
        filterKey='priority__in'
        store='casework'
        placeholder='Filter by priority'
        options={priorityOptions}
      />
      <FilterInput
        label='Address'
        filterKey='address'
        store='casework'
        placeholder='Filter by address (city, state, zipcode, etc)'
      />
      <FilterInput
        label='Intake Method'
        filterKey='intake_method__in'
        store='casework'
        placeholder='Filter by intake method'
        options={intakeMethodOptions}
      />
      {agencyOptions && (
        <FilterInput
          label='City Agencies'
          filterKey='agencies'
          store='casework'
          placeholder='Filter by agency'
          options={Object.keys(agencyOptions)}
        />
      )}
      <FilterInput
        label='Issue'
        filterKey='issues'
        store='casework'
        placeholder='Filter by issue'
        options={issueOptions}
      />
      <Box>
        <Text fontSize='lg'>Topics</Text>
        <AdvancedFilterInput
          store='casework'
          setFilters={setCaseworkFilters}
          filterKey='topics'
          labelKey='name'
        >
          <TopicSelect
            showUnassigned
            inputProps={{
              id: 'topics-filter',
              placeholder: 'Filter by topic(s)',
            }}
          />
        </AdvancedFilterInput>
      </Box>
      <Box>
        <Text fontSize='lg'>Tags</Text>
        <AdvancedFilterInput
          store='casework'
          setFilters={setCaseworkFilters}
          filterKey='tags'
          labelKey='label'
        >
          <CaseworkTagSelect
            inputProps={{
              id: 'tags-filter',
              placeholder: 'Filter by tags(s)',
            }}
            preventCreate
          />
        </AdvancedFilterInput>
      </Box>
      <Box>
        <Text fontSize='lg'>Assigned Staff</Text>
        <AdvancedFilterInput
          store='casework'
          setFilters={setCaseworkFilters}
          filterKey='assigned_staff'
          labelKey='full_name'
        >
          <StaffSelect
            districtOnly={!user.is_admin}
            inputProps={{
              id: 'assigned-staff-filter',
              placeholder: 'Filter by assigned staff',
            }}
            showUnassigned
          />
        </AdvancedFilterInput>
      </Box>
      <Box>
        <Text fontSize='lg'>Intake By</Text>
        <AdvancedFilterInput
          store='casework'
          setFilters={setCaseworkFilters}
          filterKey='intake_staff'
          labelKey='full_name'
        >
          <StaffSelect
            districtOnly={!user.is_admin}
            inputProps={{
              id: 'intake-staff-filter',
              placeholder: 'Filter by Intake By',
            }}
          />
        </AdvancedFilterInput>
      </Box>
      <Box>
        <Text fontSize='lg'>Constituent</Text>
        <AdvancedFilterInput
          store='casework'
          setFilters={setCaseworkFilters}
          filterKey='constituents'
          labelKey='identifier'
        >
          <ConstituentSelect
            districtOnly={!user.is_admin}
            inputProps={{
              id: 'constituent-filter',
              placeholder: 'Filter by constituent(s)',
            }}
          />
        </AdvancedFilterInput>
      </Box>
      {user?.is_admin && (
        <FilterInput
          label='District'
          filterKey='district__in'
          store='casework'
          placeholder='Filter by district'
          options={districtOptions}
        />
      )}
      <HStack>
        <Box w='100%'>
          <FilterInput
            label='Opened on or after'
            filterKey='opened_after'
            store='casework'
            inputType='date'
          />
        </Box>
        <Box w='100%'>
          <FilterInput
            label='Opened before'
            filterKey='opened_before'
            store='casework'
            inputType='date'
          />
        </Box>
      </HStack>
      <HStack>
        <Box w='100%'>
          <FilterInput
            label='Closed on or after'
            filterKey='closed_after'
            store='casework'
            inputType='date'
          />
        </Box>
        <Box w='100%'>
          <FilterInput
            label='Closed before'
            filterKey='closed_before'
            store='casework'
            inputType='date'
          />
        </Box>
      </HStack>
      <HStack>
        <Box w='100%'>
          <FilterInput
            label='Last updated on or after'
            filterKey='updated_after'
            store='casework'
            inputType='date'
          />
        </Box>
        <Box w='100%'>
          <FilterInput
            label='Last updated before'
            filterKey='updated_before'
            store='casework'
            inputType='date'
          />
        </Box>
      </HStack>
    </FilterMenuBase>
  )
}

export default CaseworkFilterMenu
