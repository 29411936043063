import React from 'react'

import { Text } from '@chakra-ui/react'

import { useTopicsContext } from '../ContextProvider/TopicsContext'

const TopicCount = () => {
  const { filteredTopics } = useTopicsContext()
  return (
    <Text variant='subheading-gray'>
      Showing {filteredTopics.length} topics
    </Text>
  )
}

export default TopicCount
