import React from 'react'

import { Box, Select, Stack, Text, Textarea } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { ReferralStaffField } from './fields'
import {
  intakeMethodOptions,
  referralCategoryOptions,
} from '../../../../constants'
import { FormField, Person } from '../../../ui'

const IntakeInfoPage = () => {
  const { values } = useFormikContext()

  return (
    <>
      <Stack gap={3}>
        <Box>
          <Box>
            <Text as='b'>Intake By</Text>
            <Box py={1}>
              <Person name={values.intake_staff?.full_name} />
            </Box>
          </Box>
          <Text as='b'>Intake Method</Text>
          <FormField
            field='intake_method'
            inputType={Select}
            options={intakeMethodOptions}
          />
        </Box>
        <Box>
          <Text as='b'>Reference Numbers</Text>
          <FormField field='reference_numbers' inputType={Textarea} />
        </Box>
        <Box>
          <Text as='b'>Referral By</Text>
          <ReferralStaffField />
        </Box>
        <Box>
          <Text as='b'>Referral Category</Text>
          <FormField
            field='referral_category'
            inputType={Select}
            placeholder='Select category of Referral By'
            options={referralCategoryOptions}
          />
        </Box>
        <Box>
          <Text as='b'>Comments</Text>
          <FormField
            field='comments'
            placeholder='Record any additional comments'
            inputType={Textarea}
          />
        </Box>
        <Box>
          <Stack direction='row'>
            <Stack w='100%'>
              <Text as='b'>Opened At</Text>
              <FormField field='opened_at' type='datetime-local' />
            </Stack>
            <Stack w='100%'>
              <Text as='b'>Closed At</Text>
              <FormField field='closed_at' type='datetime-local' />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default IntakeInfoPage
