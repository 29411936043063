import React, { useCallback, useEffect } from 'react'

import { Stack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'
import {
  clearStaffOptions,
  loadStaffOptions,
  paginateStaffOptions,
} from '../../../redux/features/staffSlice'
import { formatDistrict } from '../../../utils'

const StaffSelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  district,
  districtOnly,
  initialSelected,
  inputProps = {},
  showUnassigned,
}) => {
  const dispatch = useDispatch()

  const staffOptions = useSelector(state => state.staff.options) || []
  const searchLoading = useSelector(state => state.staff.options_loading)
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    dispatch(clearStaffOptions())
    dispatch(
      loadStaffOptions({
        value: '',
        districtOnly: districtOnly,
        district: district,
      })
    )
    return () => {
      dispatch(clearStaffOptions())
    }
  }, [])

  const debouncedHandleChange = useDebouncedCallback(e => {
    dispatch(clearStaffOptions())
    dispatch(
      loadStaffOptions({
        value: e.target.value,
        districtOnly: districtOnly,
        district: district,
      })
    )
  }, 200)

  const loadMoreResults = useCallback(() => {
    dispatch(paginateStaffOptions())
  }, [])

  const filterOptions = useCallback(
    (searchTerm, selectedOptions) => {
      let unselectedOptions = staffOptions.filter(
        ({ id }) => !selectedOptions.find(({ value }) => value.id === id)
      )

      if (searchTerm)
        unselectedOptions = unselectedOptions.filter(({ full_name }) =>
          full_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      if (
        unselectedOptions.length === 0 ||
        (unselectedOptions.length === 1 && unselectedOptions[0].id === '-1')
      )
        return null

      return unselectedOptions
    },
    [staffOptions]
  )

  return (
    <SearchSelect>
      <SearchOptions
        loadMoreOptions={loadMoreResults}
        onClear={onClear}
        onSearch={debouncedHandleChange}
        isLoading={searchLoading}
        initialSelected={initialSelected}
        inputProps={inputProps}
      >
        {({ searchTerm, selectedOptions }) =>
          filterOptions(searchTerm, selectedOptions)?.map(
            (staff, index) =>
              (staff.id !== '-1' || (staff.id === '-1' && showUnassigned)) && (
                <SearchOption
                  key={`staff-option-${index}`}
                  option={{ label: staff.full_name, value: staff }}
                  onSelect={onSelect}
                >
                  <Stack gap={0}>
                    <Text align='left'>{staff.full_name}</Text>
                    {(staff.role || staff.district) && !district && (
                      <Text align='left' fontSize='sm' color='gray.500'>
                        {staff.role}
                        {staff.district !== user.district &&
                          !district &&
                          ` (District ${formatDistrict(staff.district)})`}
                      </Text>
                    )}
                  </Stack>
                </SearchOption>
              )
          ) || <NoSearchResults> No staff found </NoSearchResults>
        }
      </SearchOptions>
      <SearchSelectedTags onRemove={onRemove} />
    </SearchSelect>
  )
}

export default StaffSelect
