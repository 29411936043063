import React from 'react'

import {
  Badge,
  Button,
  Card,
  CardBody,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { formatDistrict } from '../../../../utils'

const VoteModal = ({ upvotes, downvotes }) => {
  function Vote({ full_name, district }) {
    return (
      <Flex gap={2} align='center'>
        <Text>{full_name}</Text>
        <Badge>District {formatDistrict(district)}</Badge>
      </Flex>
    )
  }

  function NoVotes() {
    return (
      <Text as='i' variant='subheading-sm'>
        None
      </Text>
    )
  }

  function VoteCard({ heading, votes }) {
    return (
      <Card>
        <CardBody>
          <Flex>
            <Text variant='subheading-blue'>{heading}</Text>
            <Spacer />
            <Tag size='sm'>{votes.length}</Tag>
          </Flex>
          {votes.length > 0 ? (
            votes?.map(({ id, staff }) => (
              <Vote key={`vote-${id}`} {...staff} />
            ))
          ) : (
            <NoVotes />
          )}
        </CardBody>
      </Card>
    )
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button size='sm' mr={3} onClick={onOpen}>
        See votes
      </Button>
      <Modal
        size='xl'
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Votes</ModalHeader>
          <ModalBody>
            <Stack>
              <VoteCard heading='Upvotes' votes={upvotes} />
              <VoteCard heading='Downvotes' votes={downvotes} />
            </Stack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}

export default VoteModal
