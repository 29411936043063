import React, { createElement, useEffect, useRef, useState } from 'react'

import {
  ButtonGroup,
  IconButton,
  Input,
  Td,
  Tr,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  DeleteIcon,
  PencilIcon,
  PencilOffIcon,
  SaveIcon,
  UserRoundPen,
} from 'lucide-react'

import ConfirmDeleteTagDialog from './ConfirmDeleteTagDialog'
import { useTagsContext } from '../ContextProvider/TagsContext'

const TagRow = ({ id, label, count, district }) => {
  const toast = useToast()
  const { updateTag, deleteTag, tags, mode, openConstituentsDrawer } =
    useTagsContext()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()

  const [isEditing, setIsEditing] = useBoolean(false)
  const [editValue, setEditValue] = useState(label)

  const labelRef = useRef(null)
  const editTagRef = useRef(null)

  useEffect(() => {
    if (isEditing && editTagRef.current) editTagRef.current.focus()
  }, [isEditing, editTagRef.current])

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleUpdateTag()
    } else if (e.key === 'Escape') {
      setEditValue(label)
      setIsEditing.off()
    }
  }

  const handleUpdateTag = () => {
    if (
      tags
        .filter(t => t.district === district && t.id !== id)
        .some(t => {
          return t.label === editValue
        })
    ) {
      setEditValue(label)
      toast({
        title: 'Tag with this label already exists',
        status: 'warning',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      })
    } else if (editValue !== label) updateTag({ id, label: editValue })

    setIsEditing.off()
  }

  const handleDeleteTag = () => {
    deleteTag({ id, label })
    deleteOnClose()
  }

  function ActionButton({ icon, ...props }) {
    return (
      <IconButton
        icon={createElement(icon, {
          strokeWidth: 1.5,
          size: '20',
        })}
        {...props}
      />
    )
  }

  return (
    <Tr>
      <Td ref={labelRef} minW={300} h='75px'>
        {isEditing ? (
          <Input
            ref={editTagRef}
            id={`edit-tag-${id}`}
            value={editValue}
            onChange={e => setEditValue(e.target.value.toUpperCase())}
            onBlur={handleUpdateTag}
            onKeyDown={handleKeyDown}
          />
        ) : (
          editValue
        )}
      </Td>
      <Td>{count}</Td>
      <Td>
        <ButtonGroup variant='ghost'>
          {isEditing ? (
            <>
              <ActionButton icon={SaveIcon} onClick={setIsEditing.off} />
              <ActionButton icon={PencilOffIcon} onClick={setIsEditing.off} />
            </>
          ) : (
            <ActionButton icon={PencilIcon} onClick={setIsEditing.on} />
          )}
          {mode === 'constituents' && (
            <ActionButton
              icon={UserRoundPen}
              onClick={() => openConstituentsDrawer({ id, label, count })}
            />
          )}
          <ActionButton
            icon={DeleteIcon}
            colorScheme='red'
            onClick={deleteOnOpen}
          />
        </ButtonGroup>
        {deleteIsOpen && (
          <ConfirmDeleteTagDialog
            isOpen={deleteIsOpen}
            onClose={deleteOnClose}
            onConfirm={handleDeleteTag}
            leastDestructiveRef={labelRef}
            scheme='delete'
            label={label}
            count={count}
          />
        )}
      </Td>
    </Tr>
  )
}

export default TagRow
