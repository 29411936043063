import React, { useState, useEffect, useRef } from 'react'

import {
  Tr,
  Td,
  Tooltip,
  useMediaQuery,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'

import StaffCard from './StaffCard'
import {
  clearStaff,
  loadStaff,
  paginate,
} from '../../../redux/features/staffSlice'
import { formatDistrict } from '../../../utils'
import { NoResults, Loading, NoValue, ResizableTable } from '../../ui'
import { throttle } from '../../utils'
import { tableRowStyles } from '../styles/directoryTable'

const openStaff = (e, staff) => {
  const url = `/directory/staff/${staff.user?.id}`
  if (e.metaKey || e.ctrlKey) {
    window.open(url, '_blank')
  } else if (window.getSelection().toString().length > 0) {
    e.preventDefault()
  } else {
    document.location.href = url
  }
}

const handleMenuItemClick = (e, action) => {
  e.stopPropagation()
  action()
}

const OptionsMenu = ({ url, onClose }) => {
  const { isOpen, onOpen, onClose: closeMenu } = useDisclosure()
  const {
    isOpen: isTooltipOpen,
    onOpen: openTooltip,
    onClose: closeTooltip,
  } = useDisclosure()

  useEffect(() => {
    if (onClose) {
      closeMenu()
      closeTooltip()
    }
    return () => {}
  }, [onClose, closeMenu, closeTooltip])
  return (
    <Menu isOpen={isOpen} onClose={closeMenu}>
      <Tooltip
        hasArrow
        label='Options'
        aria-label='Options'
        isOpen={isTooltipOpen}
      >
        <MenuButton
          as={IconButton}
          aria-label='See options menu for staff'
          variant='unstyled'
          colorScheme='gray'
          onClick={() => {
            onOpen()
            closeTooltip()
          }}
          onMouseEnter={openTooltip}
          onMouseLeave={closeTooltip}
          icon={<EllipsisVerticalIcon height={25} />}
        ></MenuButton>
      </Tooltip>
      <Portal>
        <MenuList>
          <MenuItem
            as='a'
            href={url}
            target='_blank'
            onClick={e => handleMenuItemClick(e, () => {})}
          >
            Open in a new tab
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

const TableRow = ({ staff, onClose }) => {
  const optionsMenuRef = useRef(null)
  const url = `/directory/staff/${staff.user?.id}`

  return (
    <Tr
      onClick={e => {
        if (optionsMenuRef.current?.contains(e.target)) return
        openStaff(e, staff)
      }}
      sx={{ ...tableRowStyles }}
    >
      <Td>
        {`${staff.user?.last_name}, ${staff.user?.first_name} ${
          staff.user?.middle_name && `${staff.user?.middle_name?.charAt(0)}.`
        }`}
      </Td>
      <Td>{staff.user?.email}</Td>
      <Td>{staff.role || <NoValue />}</Td>
      <Td>{formatDistrict(staff?.district) || <NoValue />}</Td>
      <Td ref={optionsMenuRef} className='options-column'>
        <OptionsMenu url={url} onClose={onClose} />
      </Td>
    </Tr>
  )
}

const StaffTable = ({ tableRef }) => {
  const dispatch = useDispatch()
  const [onClose, setOnClose] = useState(false)

  const loading = useSelector(state => state.staff.loading)
  const staff = useSelector(state => state.staff.data)

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const handleScroll = () => {
    const container = tableRef.current
    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight - 50
    ) {
      dispatch(paginate())
    }
  }

  const headers = [
    { name: 'Name', value: 'last_name' },
    { name: 'Email', value: 'email' },
    { name: 'Role', value: 'role' },
    { name: 'District', value: 'district' },
    { name: ' ', value: '' },
  ]

  useEffect(() => {
    dispatch(loadStaff())

    // Load more data when reaching the end of the container
    const throttledHandleScroll = throttle(handleScroll, 200) // Adjust the delay as needed

    const container = tableRef.current
    container?.addEventListener('scroll', throttledHandleScroll, false)
    return () => {
      container?.removeEventListener('scroll', throttledHandleScroll, false)
      dispatch(clearStaff())
    }
  }, [])

  useEffect(() => {
    const closeOptionsOnScroll = () => {
      setOnClose(true)
    }
    const endCloseOptionsOnScroll = () => {
      setOnClose(false)
    }

    const container = tableRef.current
    container?.addEventListener('scroll', closeOptionsOnScroll, true)
    container?.addEventListener('scrollend', endCloseOptionsOnScroll, true)
    return () => {
      container?.removeEventListener('scroll', closeOptionsOnScroll, true)
      container?.removeEventListener('scrollend', endCloseOptionsOnScroll, true)
    }
  }, [])

  const tableContent = staff.map((s, index) => (
    <TableRow staff={s} key={index} onClose={onClose} />
  ))

  if (!staff) return <NoResults />
  return (
    <>
      {isLargerThan768 ? (
        <ResizableTable
          localStorageKeyName={'StaffTable'}
          headers={headers}
          minCellWidth={150}
          tableContent={tableContent}
        />
      ) : (
        staff?.map((s, index) => (
          <StaffCard
            openStaff={openStaff}
            staff={s}
            index={index}
            key={index}
          />
        ))
      )}
      {loading && <Loading />}
      {!loading && staff?.length === 0 && <NoResults />}
    </>
  )
}

export default StaffTable
