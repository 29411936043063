import React, { cloneElement } from 'react'

import { useDisclosure } from '@chakra-ui/react'

import CreateOrEditConstituentDrawer from './CreateOrEditConstituentDrawer'

const EditConstituent = ({ button, constituent }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      {cloneElement(button, {
        onClick: e => {
          e.stopPropagation()
          onOpen()
        },
      })}
      <CreateOrEditConstituentDrawer
        initialValues={constituent}
        mode='edit'
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export default EditConstituent
