import React from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Center,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Text,
  useBoolean,
} from '@chakra-ui/react'

const PatchNotesSection = ({ title, description, preview }) => {
  const [show, setShow] = useBoolean(false)
  return (
    <Box>
      <Flex
        align='center'
        onClick={preview && setShow.toggle}
        cursor={preview && 'pointer'}
      >
        <Text variant='subheading-blue'>{title}</Text>
        {preview && (
          <>
            <Spacer />
            <Badge>Preview available</Badge>
            <IconButton
              icon={
                <Icon
                  as={show ? ChevronUpIcon : ChevronDownIcon}
                  color='blue.500'
                  fontSize='2xl'
                />
              }
              variant='unstyled'
            />
          </>
        )}
      </Flex>
      {typeof description === 'string' ? (
        <Text>{description}</Text>
      ) : (
        description
      )}

      {preview && (
        <Collapse in={show}>
          <Center py={2}>
            <Box
              borderRadius='xl'
              border='1px solid rgba(0, 0, 0, 0.25)'
              shadow='md'
              p={3}
              my={1}
            >
              {preview}
            </Box>
          </Center>
        </Collapse>
      )}
    </Box>
  )
}

export default PatchNotesSection
