import React from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  HStack,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import {
  AgenciesField,
  AssignedStaffField,
  TagsField,
  TopicField,
} from './fields'
import { priorityOptions, statusOptions } from '../../../../constants'
import { formatDistrict } from '../../../../utils'
import { FormField } from '../../../ui'
import { isAddressEmpty } from '../../../utils'

const SectionHeading = ({ children, isRequired }) => (
  <Text as='b'>
    {children}
    {isRequired && (
      <Text as='span' color='red'>
        &nbsp;*
      </Text>
    )}
  </Text>
)

const CaseworkInfoPage = ({ constituentMode = 'select' }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext()

  const user = useSelector(state => state.auth.user)

  const constituent =
    constituentMode === 'select'
      ? values.constituent
      : constituentMode === 'create'
      ? values.newConstituent
      : null

  const autofillAddress = field => {
    const { line1, line2, city, state, zipcode } = constituent[field]
    setFieldValue('address', {
      line1,
      line2,
      city,
      state,
      zipcode,
    })
  }

  const addressIsValid = address => {
    if (!address) return false
    return !isAddressEmpty(address) && address.state === 'NY'
  }
  const allowAutofill =
    addressIsValid(constituent?.home_address) ||
    addressIsValid(constituent?.business_address)

  const selfAssigned = values.assigned_staff
    ?.map(staff => staff.id)
    ?.includes(user.id)

  const constituentDistrictLocked = constituent?.visible_to_district_only

  return (
    <Stack gap={3}>
      <Box>
        <SectionHeading isRequired={values.status === 'Closed'}>
          Topic
        </SectionHeading>
        <Box>
          {values.status === 'Closed' && (
            <Text pt={1} as='em'>
              Note: Topic is required to close a case.
            </Text>
          )}
        </Box>
        <TopicField />
      </Box>
      <Box>
        <SectionHeading isRequired>Details</SectionHeading>
        <FormField
          field='details'
          placeholder='Provide details for this casework'
          inputType={Textarea}
          isRequired
          otherProps={{ height: 150 }}
          isInvalid={errors.details && touched.details}
        />
      </Box>
      <Box>
        <Flex>
          <SectionHeading>Assign Staff to Casework</SectionHeading>

          <Spacer />
          {!selfAssigned && (
            <Button
              variant='link'
              onClick={() => {
                setFieldValue('assigned_staff', [
                  ...values.assigned_staff,
                  user,
                ])
              }}
            >
              Assign self
            </Button>
          )}
        </Flex>
        <AssignedStaffField />
      </Box>

      <Box>
        <SectionHeading> Relevant City Agencies </SectionHeading>
        <AgenciesField />
      </Box>
      <Box>
        <SectionHeading> Tags </SectionHeading>
        <TagsField />
      </Box>
      <Box>
        <SectionHeading> Status </SectionHeading>
        <FormField
          field='status'
          placeholder={null}
          options={statusOptions.filter(s => !s.includes('Transfer'))}
          inputType={Select}
        />
      </Box>

      <Box>
        <SectionHeading> Priority </SectionHeading>
        <FormField
          field='priority'
          placeholder={null}
          options={priorityOptions}
          inputType={Select}
        />
      </Box>

      <Box pb={3}>
        <Flex align='center'>
          <SectionHeading> Reporting Address </SectionHeading>
          <Spacer />
          {allowAutofill && (
            <HStack>
              <Text>Autofill with address:</Text>
              <ButtonGroup>
                {!isAddressEmpty(constituent.home_address) && (
                  <Button
                    variant='link'
                    onClick={() => autofillAddress('home_address')}
                  >
                    Home
                  </Button>
                )}
                {!isAddressEmpty(constituent.business_address) && (
                  <Button
                    variant='link'
                    onClick={() => autofillAddress('business_address')}
                  >
                    Business
                  </Button>
                )}
              </ButtonGroup>
            </HStack>
          )}
        </Flex>
        <Stack as={Flex} direction='column' spacing={3} flex='2'>
          <FormField field='address.line1' placeholder='Line 1' />
          <FormField field='address.line2' placeholder='Line 2' />
          <Stack direction='row'>
            <FormField field='address.city' placeholder='City' />
            <FormField
              field='address.state'
              placeholder='State'
              inputType={Select}
              options={['NY']}
              isDisabled
            />
            <FormField field='address.zipcode' placeholder='Zipcode' />
          </Stack>
        </Stack>
      </Box>
      <Box>
        <SectionHeading>Privacy</SectionHeading>
        <Box>
          <Checkbox
            id='district-lock'
            isChecked={
              constituentDistrictLocked || values.visible_to_district_only
            }
            isDisabled={constituentDistrictLocked}
            onChange={e =>
              setFieldValue('visible_to_district_only', e.target.checked)
            }
          >
            Casework visible to District {formatDistrict(user.district)} only
          </Checkbox>
          <Text fontStyle='italic'>
            Note: Casework information is hidden from other districts by
            default. This option prevents other districts from seeing this
            casework exists at all.
            {constituentDistrictLocked &&
              ' This option is checked by default because the constituent is visible to district only.'}
          </Text>
        </Box>
      </Box>
    </Stack>
  )
}

export default CaseworkInfoPage
