import React, { useEffect } from 'react'

import { Box, Stack, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import TopicSuggestion from './TopicSuggestion'
import {
  clearTopicSuggestions,
  loadTopicSuggestions,
} from '../../../../redux/features/topicsSlice'

const TopicSuggestionsList = () => {
  const dispatch = useDispatch()

  const suggestions = useSelector(state => state.topics.suggestions)

  useEffect(() => {
    dispatch(loadTopicSuggestions())

    return () => {
      dispatch(clearTopicSuggestions())
    }
  }, [])

  if (!suggestions || suggestions.length === 0) return null
  return (
    <Box w='100%'>
      <Text variant='subheading-blue' fontSize='xl'>
        Suggestions
      </Text>
      <Box maxH='45vh' overflowY='auto'>
        <Stack gap={3} mt={1}>
          {suggestions.map(suggestion => (
            <TopicSuggestion key={suggestion.id} {...suggestion} />
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default TopicSuggestionsList
