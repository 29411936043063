import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useToast } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  createTopic,
  loadTopics,
  setTopics,
  startExportTopics,
  updateTopic,
} from '../../../../redux/features/topicsSlice'

const TopicsContext = createContext()

export const useTopicsContext = () => useContext(TopicsContext)

export const TopicsContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [searchValue, setSearchValue] = useState('')
  const [showActiveOnly, setShowActiveOnly] = useState(true)

  const user = useSelector(state => state.auth.user)
  const topics = useSelector(state => state.topics.data) || []
  const isLoading = useSelector(state => state.topics.loading) || false
  const isExporting = useSelector(state => state.topics.exporting) || false

  const filteredTopics = useMemo(() => {
    if (!topics) return []
    let filtered = topics
    if (showActiveOnly && user.is_admin)
      filtered = filtered.filter(t => t.is_active)
    if (!searchValue) return filtered

    let lowerSearch = searchValue.toLowerCase()

    return filtered.filter(
      ({ issue, name }) =>
        name?.toLowerCase().includes(lowerSearch) ||
        issue?.toLowerCase().includes(lowerSearch)
    )
  }, [topics, searchValue, showActiveOnly])

  const topicsByIssue = useMemo(
    () =>
      filteredTopics.reduce((groupedTopics, topic) => {
        const { issue } = topic
        if (!groupedTopics[issue]) {
          groupedTopics[issue] = []
        }
        groupedTopics[issue].push(topic)
        return groupedTopics
      }, {}),
    [filteredTopics]
  )

  const issues = Object.keys(topicsByIssue) || []

  const getTopics = useCallback(() => {
    dispatch(
      loadTopics({
        params: {
          show_all: user.is_admin,
        },
      })
    )
  }, [user])

  useEffect(() => {
    getTopics()
  }, [])

  const updateTopicsInStore = useCallback(updatedTopics => {
    dispatch(setTopics({ topics: updatedTopics }))
  }, [])

  const toastMessage = (message, description = '', status) => {
    toast({
      title: message,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    })
  }

  const handleCreateTopic = useCallback((topic, onCreate) => {
    const onSuccess = () => {
      toastMessage(`${topic.name} added to topics`, '', 'success')
      onCreate()
      getTopics()
    }

    const onFailure = error => {
      toastMessage('Failed to add topic', error, 'error')
    }

    const payload = {
      topic,
      callbackSuccess: onSuccess,
      callbackFailure: onFailure,
    }

    dispatch(createTopic(payload))
  }, [])

  const handleUpdateTopic = useCallback(
    (topic, onUpdate) => {
      const onSuccess = () => {
        toastMessage(`${topic.name} saved`, '', 'success')
        const updatedTopics = topics.map(t =>
          t.id === topic.id ? { ...t, ...topic } : t
        )

        updateTopicsInStore(updatedTopics)
        onUpdate()
      }

      const onFailure = () => {
        toastMessage('Failed to edit topic', 'error')
      }

      const payload = {
        topic,
        callbackSuccess: onSuccess,
        callbackFailure: onFailure,
      }
      dispatch(updateTopic(payload))
    },
    [topics]
  )

  const handleExportTopics = useCallback(() => {
    const payload = showActiveOnly
      ? {
          params: { is_active: true },
        }
      : undefined
    dispatch(startExportTopics(payload))
  }, [showActiveOnly])

  return (
    <TopicsContext.Provider
      value={{
        searchValue,
        setSearchValue,

        showActiveOnly,
        setShowActiveOnly,

        topics,
        topicsByIssue,
        issues,
        filteredTopics,
        isLoading,
        isExporting,

        getTopics,
        handleCreateTopic,
        handleUpdateTopic,
        handleExportTopics,
      }}
    >
      {children}
    </TopicsContext.Provider>
  )
}
