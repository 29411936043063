import React, { useEffect, useState } from 'react'

import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Button,
  Text,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Spacer,
  Center,
  useToast,
  Stack,
  useDisclosure,
  Box,
  useColorModeValue,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmDeactivateDialog from './ConfirmDeactivateDialog'
import {
  clearStaffInstance,
  deactivateStaff,
  loadStaffInstance,
  updateStaff,
} from '../../redux/features/staffSlice'
import { StaffForm } from '../directory'
import { StaffSelect } from '../ui/select'

const ManageExistingStaff = () => {
  const dispatch = useDispatch()
  const toast = useToast()

  const [selectedStaff, setSelectedStaff] = useState(null)
  const staffInstance = useSelector(state => state.staff.instance)

  const cardHeaderBg = useColorModeValue('blue.300', 'blue.600')
  const cardBodyBorder = useColorModeValue('gray.300', 'gray.600')
  const cardBodyBg = useColorModeValue('white', 'gray.700')

  /** States for alert dialog to confirm deactivation */
  const { isOpen, onOpen, onClose } = useDisclosure()

  /** On every selection, pull data from backend */
  useEffect(() => {
    if (selectedStaff) dispatch(loadStaffInstance({ id: selectedStaff.id }))
    else dispatch(clearStaffInstance())

    return () => {
      dispatch(clearStaffInstance())
    }
  }, [selectedStaff, dispatch])

  const deactivateSuccess = () => {
    toast({
      title: 'Staff deactivated',
      description: `Staff was deactivated. Their previous casework have not been changed, but they will no longer be able to login.`,
      status: 'success',
    })
    onClose()
    dispatch(clearStaffInstance())
  }

  const handleDeactivate = () => {
    dispatch(deactivateStaff({ callbackSuccess: deactivateSuccess }))
  }

  return (
    <>
      {!staffInstance ? (
        <StaffSelect
          onSelect={setSelectedStaff}
          initialSelected={
            selectedStaff
              ? [{ label: selectedStaff.full_name, value: selectedStaff }]
              : []
          }
          inputProps={{
            id: `staff-select`,
            placeholder: 'Search for specific staff',
          }}
        />
      ) : (
        <Box>
          <Button
            variant='link'
            onClick={() => {
              setSelectedStaff(null)
            }}
            leftIcon={<ChevronLeftIcon />}
          >
            Search again
          </Button>
        </Box>
      )}

      {staffInstance ? (
        <Card mt={3}>
          <CardHeader bg={cardHeaderBg}>
            <Flex align='center'>
              <Text variant='subheading' color='white'>
                {staffInstance.user.full_name} | Staff Profile
              </Text>
              <Spacer />

              <Button colorScheme='red' onClick={onOpen}>
                Deactivate
              </Button>
            </Flex>
          </CardHeader>
          <CardBody
            border='1px'
            borderTop='0'
            borderColor={cardBodyBorder}
            bg={cardBodyBg}
            p={4}
          >
            <Formik
              initialValues={staffInstance}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, { setSubmitting }) => {
                const updateSuccess = () => {
                  toast({
                    title: 'Staff update success!',
                    status: 'success',
                  })
                  setSubmitting(false)
                  dispatch(loadStaffInstance({ id: staffInstance.user.id }))
                }

                const updateFailure = () => {
                  toast({
                    title: 'Staff update error!',
                    status: 'error',
                  })
                  setSubmitting(false)
                }

                dispatch(
                  updateStaff({
                    id: staffInstance.user.id,
                    values,
                    callbackSuccess: updateSuccess,
                    callbackFailure: updateFailure,
                  })
                )
              }}
            >
              {({ handleSubmit, setSubmitting, isSubmitting }) => (
                <Stack gap={3}>
                  <StaffForm />
                  <Button
                    onClick={() => {
                      setSubmitting(true)
                      handleSubmit()
                    }}
                    isLoading={isSubmitting}
                  >
                    Save Changes
                  </Button>
                </Stack>
              )}
            </Formik>
          </CardBody>
          <ConfirmDeactivateDialog
            onConfirm={handleDeactivate}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        </Card>
      ) : (
        <Center my='auto' flexGrow={1} overflowY='auto'>
          <Text textAlign='center'>
            Search for a staff to view, edit, or deactivate them here.
          </Text>
        </Center>
      )}
    </>
  )
}

export default ManageExistingStaff
