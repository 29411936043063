import React from 'react'

import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'

const EmployeesTable = ({ employees }) => {
  if (!employees) {
    return <></>
  }
  const headerBg = useColorModeValue('blue.100', 'blue.700')
  const headerStyles = {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    bg: headerBg,
  }
  return (
    <Table variant='striped'>
      <Thead>
        <Tr>
          <Th {...headerStyles}> Name </Th>
          <Th {...headerStyles}> Office Title </Th>
          <Th {...headerStyles}> Division </Th>
          <Th {...headerStyles}> Phone 1 </Th>
          <Th {...headerStyles}> Phone 2 </Th>
        </Tr>
      </Thead>
      <Tbody>
        {employees?.map((employee, index) => (
          <Tr
            key={index}
            css={{ '&:nth-of-type(odd)': { backgroundColor: '#EFEFEF' } }}
          >
            <Td>
              {`${employee.first_name} ${
                employee.m_i ? employee.m_i + '. ' : ' '
              }${employee.last_name}${
                employee.name_suffix ? ' ' + employee.name_suffix : ''
              }`}
            </Td>
            <Td maxW={300}>{employee.office_title}</Td>
            <Td maxW={300}>{employee.division_name}</Td>
            <Td whiteSpace='nowrap'>{employee.phone_1}</Td>
            <Td whiteSpace='nowrap'>{employee.phone_2}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default EmployeesTable
