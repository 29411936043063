import React, { useEffect, useRef } from 'react'

import {
  Badge,
  Box,
  Divider,
  Stack,
  Tag,
  TagLabel,
  Text,
  Wrap,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { ConstituentCard } from '../../../directory'
import { AddressText, NoValue, Person } from '../../../ui'
import { formatDate } from '../../../utils'
import Attachments from '../Attachments'

const Section = ({ title, children, showRequired }) => (
  <Box>
    <Text as='b'>
      {title}{' '}
      {showRequired && (
        <Badge colorScheme='red' size='lg'>
          Required Field!
        </Badge>
      )}
    </Text>
    <Box>{children}</Box>
  </Box>
)

const ReviewPage = ({ creatingConstituent }) => {
  const { values, errors } = useFormikContext()
  const { newConstituent } = values
  const initialFocusRef = useRef(null)

  useEffect(() => {
    initialFocusRef.current.focus()
  }, [initialFocusRef])

  return (
    <>
      <Stack gap={3} ref={initialFocusRef} px={2}>
        <Section
          title={
            creatingConstituent ? (
              <Text>
                Constituent <Badge colorScheme='green'> New </Badge>
              </Text>
            ) : (
              'Constituent'
            )
          }
          showRequired={
            (!values.constituent && !creatingConstituent) ||
            (!values.newConstituent && creatingConstituent)
          }
        >
          {creatingConstituent ? (
            <ConstituentCard
              constituent={newConstituent}
              showDescriptions
              hideOpenConstituent
              showDistrict={false}
            />
          ) : values.constituent ? (
            <ConstituentCard
              constituent={values.constituent}
              showDescriptions
              showDistrict={false}
              variant='selected'
            />
          ) : (
            <NoValue />
          )}
        </Section>
        <Divider />
        <Section
          title='Topic(s)'
          showRequired={
            (!!values.closed_at || values.status === 'Closed') &&
            values.topics.length === 0
          }
        >
          {values.topics?.length > 0 ? (
            <Wrap>
              {values.topics.map((topic, index) => (
                <Tag key={index}>{topic.name}</Tag>
              ))}
            </Wrap>
          ) : (
            <NoValue />
          )}
          {(!!values.closed_at || values.status === 'Closed') &&
            values.topics.length === 0 && (
              <Text color='red'>Topic required to close casework</Text>
            )}
        </Section>
        <Section title='Details' showRequired={errors.details}>
          {values.details || <NoValue />}
        </Section>
        <Section title='Reporting Address'>
          <AddressText address={values.address} />
        </Section>
        <Section title='Assigned Staff'>
          {values.assigned_staff?.length > 0 ? (
            <Wrap>
              {values.assigned_staff.map((staff, index) => (
                <Tag key={index}>{staff.full_name}</Tag>
              ))}
            </Wrap>
          ) : (
            <NoValue />
          )}
        </Section>
        <Section title='Relevant City Agencies'>
          {values.agencies?.length > 0 ? (
            <Wrap>
              {values.agencies.map((agency, index) => (
                <Tag key={index}>{agency}</Tag>
              ))}
            </Wrap>
          ) : (
            <NoValue />
          )}
        </Section>

        <Section title='Tags'>
          {values.tags?.length > 0 ? (
            <Wrap>
              {values.tags.map((tag, index) => (
                <Tag key={index}>
                  <TagLabel>{tag.label}</TagLabel>
                </Tag>
              ))}
            </Wrap>
          ) : (
            <NoValue />
          )}
        </Section>

        <Section title='Priority'>{values.priority} </Section>

        <Divider />
        <Section title='Intake By'>
          <Tag>{values.intake_staff?.full_name}</Tag>
        </Section>
        <Section title='Intake Method'>
          {values.intake_method || <NoValue />}
        </Section>
        <Section title='Reference Numbers'>
          {values.reference_numbers || <NoValue />}
        </Section>
        <Section title='Referral By'>
          {values.referral_staff ? (
            <Person
              link={`/directory/staff/${values.referral_staff.id}`}
              name={values.referral_staff.full_name}
            />
          ) : (
            <NoValue />
          )}
        </Section>
        <Section title='Referral Category'>
          {values.referral_category || <NoValue />}
        </Section>
        <Section title='Comments'>{values.comments || <NoValue />}</Section>
        {values.opened_at && (
          <Section title='Opened At'> {formatDate(values.opened_at)} </Section>
        )}
        {values.closed_at && (
          <Section title='Closed At'>
            <Text>{formatDate(values.closed_at)}</Text>
            <Badge colorScheme='red'>
              Entering this date will set this case to closed.
            </Badge>
          </Section>
        )}
        {values.attachments?.length > 0 && (
          <Section title='Attachments'>
            <Attachments attachments={values.attachments} />
          </Section>
        )}
      </Stack>
    </>
  )
}

export default ReviewPage
