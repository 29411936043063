import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'

import CaseworkTagSelect from '../../../../ui/select/CaseworkTagSelect'

const TagsField = () => {
  const caseworkTags = useSelector(state => state.casework.tag_options) || []
  const { values, setFieldValue } = useFormikContext()

  const onTagSelect = useCallback(
    option => setFieldValue('tags', [...values.tags, option]),
    [setFieldValue, values.tags]
  )

  const onTagRemove = useCallback(
    removeIndex => setFieldValue('tags', values.tags.toSpliced(removeIndex, 1)),
    [setFieldValue, values.tags]
  )

  const onTagClear = useCallback(
    () => setFieldValue('tags', []),
    [setFieldValue]
  )

  const selectedTags = useMemo(
    () =>
      values?.tags?.map(tag => ({
        label: tag.label,
        value: tag,
      })) || [],
    [values.tags]
  )

  return (
    <CaseworkTagSelect
      tagOptions={caseworkTags}
      onSelect={onTagSelect}
      onRemove={onTagRemove}
      onClear={onTagClear}
      initialSelected={selectedTags}
      inputProps={{
        id: 'tag-select',
      }}
    />
  )
}
export default TagsField
