import React, { useEffect, useRef, useState } from 'react'

import { Tr, Td, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { CreateOrEditGroupModal } from './CreateOrEditGroup'
import GroupCard from './GroupCard'
import { GroupDetailDrawer } from './GroupDetail'
import GroupsOptionsMenu from './GroupsOptionsMenu'
import {
  clearGroups,
  loadGroups,
  paginate,
} from '../../../redux/features/groupsSlice'
import { formatDistrict } from '../../../utils'
import { NoValue, Loading, NoResults, ResizableTable } from '../../ui'
import { throttle } from '../../utils'
import { tableRowStyles } from '../styles/directoryTable'

const TableRow = ({ openEditModal, openDetailDrawer, group, onClose }) => {
  const user = useSelector(state => state.auth.user)
  const optionsMenuRef = useRef(null)
  const hoverStyles = {
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s',
  }

  return (
    <Tr
      sx={tableRowStyles}
      _hover={hoverStyles}
      onClick={e => {
        if (optionsMenuRef.current.contains(e.target)) return
        openDetailDrawer(group)
      }}
    >
      <Td>{group.name || <NoValue />}</Td>
      <Td> {group.alias || <NoValue />} </Td>
      <Td> {group.description || <NoValue />} </Td>
      <Td> {group.member_count} </Td>
      {user.is_admin && <Td>{formatDistrict(group.district)}</Td>}
      <Td className='options-column' ref={optionsMenuRef}>
        <GroupsOptionsMenu
          openEditModal={openEditModal}
          group={group}
          onClose={onClose}
        />
      </Td>
    </Tr>
  )
}

const GroupsTable = ({ tableRef }) => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.groups.loading)
  const groups = useSelector(state => state.groups.data)
  const user = useSelector(state => state.auth.user)

  const {
    isOpen: editIsOpen,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure()
  const {
    isOpen: detailIsOpen,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure()
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [group, setGroup] = useState(null)
  const [onClose, setOnClose] = useState(false)

  const handleScroll = () => {
    const container = tableRef.current
    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight - 50
    ) {
      dispatch(paginate())
    }
  }

  // Define table headers for ResizableTable
  const headers = [
    { name: 'Name', value: 'name' },
    { name: 'Alias', value: 'alias' },
    { name: 'Description', value: 'description' },
    { name: 'Constituents in Group', value: 'count' },
    ...(user.is_admin ? [{ name: 'District', value: 'district' }] : []),
    { name: ' ', value: '' },
  ]

  useEffect(() => {
    dispatch(loadGroups())

    // Load more data when reaching the end of the container
    const throttledHandleScroll = throttle(handleScroll, 200) // Adjust the delay as needed

    const container = tableRef.current
    container?.addEventListener('scroll', throttledHandleScroll, false)
    return () => {
      container?.removeEventListener('scroll', throttledHandleScroll, false)
      dispatch(clearGroups())
    }
    // eslint-disable-next-line
  }, [])

  const openEditModal = group => {
    setGroup(group)
    onOpenEdit()
  }

  const openDetailDrawer = group => {
    setGroup(group)
    onOpenDetail()
  }

  useEffect(() => {
    const closeOptionsOnScroll = () => {
      setOnClose(true)
    }
    const endCloseOptionsOnScroll = () => {
      setOnClose(false)
    }

    const container = tableRef.current
    container?.addEventListener('scroll', closeOptionsOnScroll, true)
    container?.addEventListener('scrollend', endCloseOptionsOnScroll, true)
    return () => {
      container?.removeEventListener('scroll', closeOptionsOnScroll, true)
      container?.removeEventListener('scrollend', endCloseOptionsOnScroll, true)
    }
  }, [])

  const tableContent = groups?.map((group, index) => (
    <TableRow
      key={index}
      index={index}
      openEditModal={openEditModal}
      openDetailDrawer={openDetailDrawer}
      group={group}
      onClose={onClose}
    />
  ))

  return (
    <>
      {isLargerThan768 ? (
        <ResizableTable
          localStorageKeyName={'GroupsTable'}
          headers={headers}
          minCellWidth={150}
          tableContent={tableContent}
        />
      ) : (
        groups.map((group, index) => (
          <GroupCard
            key={index}
            group={group}
            openEditModal={openEditModal}
            openDetailDrawer={openDetailDrawer}
            onClose={onClose}
          />
        ))
      )}
      {loading && <Loading />}
      {!loading && groups?.length === 0 && <NoResults />}
      {editIsOpen && (
        <CreateOrEditGroupModal
          initialValues={group}
          mode='edit'
          isOpen={editIsOpen}
          onClose={onCloseEdit}
        />
      )}
      {detailIsOpen && (
        <GroupDetailDrawer
          id={group.id}
          isOpen={detailIsOpen}
          onClose={onCloseDetail}
        />
      )}
    </>
  )
}

export default GroupsTable
