import React from 'react'

import { Box, useColorModeValue } from '@chakra-ui/react'

const Topic = ({ children }) => {
  const hoveredBgColor = useColorModeValue(
    'rgba(0, 0, 0, 0.2)',
    'rgba(255, 255, 255, 0.2)'
  )

  return (
    <Box
      verticalAlign='center'
      _hover={{
        shadow: 'base',
        borderColor: hoveredBgColor,
      }}
      my={0.5}
      p={2.5}
      border='1px solid transparent'
      borderRadius='lg'
    >
      {children}
    </Box>
  )
}

export default Topic
