import React, { useMemo, useState } from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useFormikContext } from 'formik'

import { FloatingFormField } from '../../../ui/forms'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const SuggestTopicFormInputs = () => {
  const { setFieldValue, setFieldTouched, values, errors, touched, resetForm } =
    useFormikContext()
  const { issues } = useTopicsContext()
  const issueOptions = useMemo(
    () => issues.map(issue => ({ value: issue, label: issue })),
    [issues]
  )
  const [issueMode, setIssueMode] = useState('existing')

  const handleResetIssue = () => {
    resetForm({
      values: {
        ...values,
        issue: '',
      },
    })
  }
  const toggleMode = () => {
    setIssueMode(issueMode === 'existing' ? 'new' : 'existing')
    handleResetIssue()
  }

  const issueIsInvalid = useMemo(
    () => errors.issue && touched.issue,
    [errors, touched]
  )

  return (
    <Stack gap={4}>
      <Flex justify='end' align='center' direction='row' gap={3}>
        <Text>Existing Issue</Text>
        <Switch
          isChecked={issueMode === 'new'}
          onChange={toggleMode}
          id='issue-switch'
        />
        <Text>New Issue</Text>
      </Flex>
      {issueMode === 'existing' ? (
        <Box zIndex={3}>
          <FormControl id='issue' isInvalid={issueIsInvalid}>
            <Select
              placeholder='Existing Issue'
              isSearchable
              isClearable
              options={issueOptions}
              useBasicStyles
              isInvalid={issueIsInvalid}
              value={
                values.issue && { label: values.issue, value: values.issue }
              }
              onChange={option => {
                if (option?.value) setFieldValue('issue', option.value)
                else setFieldValue('issue', '')
              }}
              onBlur={() => setFieldTouched('issue', true)}
            />
            <FormErrorMessage>{errors.issue}</FormErrorMessage>
          </FormControl>
        </Box>
      ) : (
        <FloatingFormField
          field='issue'
          label='New Issue'
          isInvalid={issueIsInvalid}
          errors={errors.issue}
          onChange={e => setFieldValue('issue', e.target.value)}
        />
      )}
      <FloatingFormField
        field='topic'
        label='Topic Name'
        isInvalid={errors.topic && touched.topic}
        errors={errors.topic}
      />
      <FloatingFormField
        field='description'
        inputType={Textarea}
        isInvalid={errors.description && touched.description}
        errors={errors.description}
      />
    </Stack>
  )
}

export default SuggestTopicFormInputs
