import * as Yup from 'yup'

export const initialTopicValues = {
  issue: '',
  name: '',
  is_active: true,
}

export const topicValidationSchema = Yup.object().shape({
  issue: Yup.string().required('Issue is required'),
  name: Yup.string().required('Name is required'),
  is_active: Yup.bool(),
})

export const initialTopicSuggestionValues = {
  issue: '',
  topic: '',
  description: '',
}

export const topicSuggestionValidationSchema = Yup.object().shape({
  issue: Yup.string().required('Issue is required'),
  topic: Yup.string().required('Topic is required'),
  description: Yup.string(),
})
