import React from 'react'

import { IconButton, Tooltip } from '@chakra-ui/react'
import { DownloadIcon } from 'lucide-react'
import { useSelector } from 'react-redux'

import { ExportLoading } from '../../../ui'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const ExportTopicsButton = () => {
  const user = useSelector(state => state.auth.user)
  const { handleExportTopics, isExporting } = useTopicsContext()
  if (!user.is_admin) return null
  return (
    <>
      <Tooltip label='Export topics'>
        <IconButton icon={<DownloadIcon />} onClick={handleExportTopics} />
      </Tooltip>
      <ExportLoading isLoading={isExporting} />
    </>
  )
}

export default ExportTopicsButton
