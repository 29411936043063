import React, { useMemo, useRef } from 'react'

import {
  Badge,
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { BadgePlusIcon, Trash2Icon } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmDeleteTopicSuggestion from './ConfirmDeleteTopicSuggestion'
import CreateTopicModalButton from './CreateTopicModalButton'
import VoteModal from './VoteModal'
import {
  deleteTopicSuggestion,
  removeTopicSuggestion,
  voteTopicSuggestion,
} from '../../../../redux/features/topicsSlice'
import { formatDistrict } from '../../../../utils'
import { Downvote, Upvote } from '../../../ui/polls'

const TopicSuggestion = ({
  id,
  issue,
  topic,
  description,
  staff,
  upvotes,
  downvotes,
}) => {
  const dispatch = useDispatch()
  const topicRef = useRef(null)
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure()
  const user = useSelector(state => state.auth.user)
  const voted = useMemo(() => {
    if (upvotes?.find(vote => vote.staff.id === user.id)) return 'upvoted'
    if (downvotes?.find(vote => vote.staff.id === user.id)) return 'downvoted'
  }, [user, upvotes, downvotes])

  const handleVote = voteType => dispatch(voteTopicSuggestion({ id, voteType }))
  const handleDeleteSuggestion = () => dispatch(deleteTopicSuggestion({ id }))

  const handleCreateTopic = () => dispatch(removeTopicSuggestion({ id }))

  return (
    <Card>
      <CardBody>
        <Flex align='center'>
          <Stack gap={0}>
            <Text variant='subheading' ref={topicRef}>
              {topic}
            </Text>
            <Text variant='subheading-sm'>{issue}</Text>
          </Stack>
          <Spacer />
          <ButtonGroup>
            {user.is_admin && (upvotes.length > 0 || downvotes.length > 0) && (
              <VoteModal upvotes={upvotes} downvotes={downvotes} />
            )}
            <Upvote
              count={upvotes.length}
              onClick={() => handleVote('upvote')}
              isActive={voted === 'upvoted'}
            />
            <Downvote
              count={downvotes.length}
              onClick={() => handleVote('downvote')}
              isActive={voted === 'downvoted'}
            />
          </ButtonGroup>
        </Flex>

        <Text>{description}</Text>
        <Flex align='baseline'>
          <Box>
            <Badge>
              Suggested by District {formatDistrict(staff.district)}{' '}
              {user.is_admin && staff.full_name}
            </Badge>
          </Box>
          <Spacer />
          {user.is_admin && (
            <ButtonGroup>
              <CreateTopicModalButton
                initialValues={{ name: topic, issue }}
                onCreate={handleCreateTopic}
                button={
                  <IconButton
                    icon={<Icon as={BadgePlusIcon} />}
                    variant='ghost'
                    fontSize='xl'
                    colorScheme='green'
                  />
                }
              />
              <IconButton
                icon={<Icon as={Trash2Icon} />}
                onClick={deleteOnOpen}
                variant='ghost'
                fontSize='xl'
                colorScheme='red'
              />
              <ConfirmDeleteTopicSuggestion
                isOpen={deleteIsOpen}
                onClose={deleteOnClose}
                onConfirm={handleDeleteSuggestion}
                leastDestructiveRef={topicRef}
                topic={topic}
              />
            </ButtonGroup>
          )}
        </Flex>
      </CardBody>
    </Card>
  )
}

export default TopicSuggestion
