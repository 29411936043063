import React, { useRef } from 'react'

import {
  Box,
  Icon,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  HStack,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import ConstituentOptionsMenu from './ConstituentOptionsMenu'
import PrimaryAddress from './PrimaryAddress'
import ActiveStarIcon from '../../../../assets/icons/ActiveStarIcon'
import { formatDistrict } from '../../../../utils'
import { AddressText, AnonymousPlaceholder, NoValue } from '../../../ui'
import { tableRowStyles } from '../../styles/directoryTable'

const openConstituent = (e, constituent) => {
  const url = `/directory/constituents/${constituent.id}`
  if (e.metaKey || e.ctrlKey) {
    window.open(url, '_blank')
  } else if (window.getSelection().toString().length > 0) {
    e.preventDefault()
  } else {
    document.location.href = url
  }
}

const truncateArrayToString = (arr, maxLength = 2) => {
  if (!arr || arr.length === 0) return ''

  return arr.length > maxLength
    ? arr.slice(0, maxLength).join(', ') + ` +${arr.length - maxLength} others`
    : arr.join(', ')
}

const TdLabel = ({ children }) =>
  typeof children === 'string' ? (
    <Text variant='subheading-gray'>{children}</Text>
  ) : (
    children
  )

const TableRow = ({ constituent, index, onClose }) => {
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []
  const optionsMenuRef = useRef(null)
  const url = `/directory/constituents/${constituent.id}`

  const emails = constituent.contact_info.filter(contact =>
    contact.contact_type.includes('Email')
  )

  const phoneNumbers = constituent.contact_info.filter(contact =>
    contact.contact_type.includes('Phone')
  )

  const isPrimaryAddress = address =>
    constituent.primary_address === address &&
    constituent.home_address &&
    constituent.business_address
  const selectedBg = useColorModeValue('yellow.100', 'yellow.900')
  return (
    <Tr
      onClick={e => {
        if (optionsMenuRef.current.contains(e.target)) return
        openConstituent(e, constituent)
      }}
      sx={{
        ...tableRowStyles,
        'td': {
          backgroundColor: selectedConstituents.includes(index) && selectedBg,
          alignContent: 'center',
        },
      }}
    >
      {/* Name */}
      <Td>
        <Flex align='center'>
          <Stack gap={0}>
            {constituent.title && (
              <TdLabel>
                <Tooltip
                  label={constituent.title?.length > 30 && constituent.title}
                >
                  <Text variant='subheading-sm'>
                    {constituent.title?.length > 30
                      ? `${constituent.title?.slice(0, 30)}...`
                      : constituent.title}
                  </Text>
                </Tooltip>
              </TdLabel>
            )}
            {constituent.name ? (
              <Text>{constituent.name}</Text>
            ) : (
              <AnonymousPlaceholder />
            )}
          </Stack>
          {constituent.council_stat_id && (
            <Tooltip label='Transferred from CouncilSTAT'>
              <Icon as={DocumentDuplicateIcon} color='gray' ml={2} />
            </Tooltip>
          )}
        </Flex>
      </Td>
      {/* Address */}

      <Td>
        {isPrimaryAddress('home') && <PrimaryAddress />}
        <AddressText address={constituent.home_address} />
      </Td>
      <Td>
        {isPrimaryAddress('business') && <PrimaryAddress />}
        <AddressText address={constituent.business_address} />
      </Td>
      {/* Email */}
      <Td>
        {emails.length > 0 ? (
          <Stack>
            {emails.map((contact, index) => (
              <HStack key={index}>
                {contact.is_preferred_method && <ActiveStarIcon size={13} />}
                <Text>{contact.contact_data}</Text>
              </HStack>
            ))}
          </Stack>
        ) : (
          <NoValue />
        )}
      </Td>
      {/* Phone */}
      <Td>
        {phoneNumbers.length > 0 ? (
          phoneNumbers.map((contact, index) => (
            <Box key={index}>
              <TdLabel>
                <HStack gap={0.5}>
                  <Text variant='subheading-sm'>{contact.contact_type}</Text>
                  {contact.is_preferred_method && (
                    <Tooltip label='Preferred Method of Communication'>
                      <Box>
                        <ActiveStarIcon size={13} />
                      </Box>
                    </Tooltip>
                  )}
                </HStack>
              </TdLabel>
              <Text>{contact.contact_data}</Text>
            </Box>
          ))
        ) : (
          <NoValue />
        )}
      </Td>
      {/* Groups */}
      <Td>
        <Box>
          {truncateArrayToString(constituent.groups?.map(g => g.name)) || (
            <NoValue />
          )}
        </Box>
      </Td>
      {/* Associated topics */}
      <Td>
        <Box>
          {truncateArrayToString(
            constituent.associated_topics.map(topic => topic.name)
          ) || <NoValue />}
        </Box>
      </Td>
      {/* District */}
      <Td>{formatDistrict(constituent.district) || <NoValue />}</Td>
      {/* Options Menu */}
      <Td
        className='options-column'
        ref={optionsMenuRef}
        bgColor={
          selectedConstituents.includes(index) && `${selectedBg} !important`
        }
      >
        <Box>
          <ConstituentOptionsMenu
            url={url}
            constituentIndex={index}
            constituent={constituent}
            onClose={onClose}
          />
        </Box>
      </Td>
    </Tr>
  )
}

export default TableRow
