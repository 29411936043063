import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadExcelFile, getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  completeExportTopics,
  createTopic,
  createTopicSuggestion,
  deleteTopicSuggestion,
  loadIssues,
  loadTopicInstance,
  loadTopics,
  loadTopicSuggestions,
  startExportTopics,
  storeIssues,
  storeTopicInstance,
  storeTopics,
  storeTopicSuggestions,
  updateTopic,
  voteTopicSuggestion,
} from '../features/topicsSlice'

const TOPICS_ENDPOINT = '/api/casework/topics/'
function* loadTopicsSaga(action) {
  try {
    const params = action.payload?.params
    let url = TOPICS_ENDPOINT + 'all/'
    if (params) url += `?${getFilterQueryString(params)}`

    const response = yield call(() => {
      return backendAPI.get(url)
    })

    yield put(storeTopics(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* loadTopicInstanceSaga(action) {
  try {
    const { id } = action.payload

    const response = yield call(() => {
      return backendAPI.get(TOPICS_ENDPOINT + `${id}/`)
    })

    yield put(storeTopicInstance(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* loadTopicSuggestionsSaga() {
  try {
    const response = yield call(() => {
      return backendAPI.get(TOPICS_ENDPOINT + 'suggestions/')
    })

    yield put(storeTopicSuggestions(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* loadIssuesSaga(action) {
  try {
    const params = action.payload?.params
    let url = TOPICS_ENDPOINT + 'issues/'
    if (params) url += `?${getFilterQueryString(params)}`

    const response = yield call(() => {
      return backendAPI.get(url)
    })

    yield put(storeIssues(response.data))
  } catch (error) {
    console.error(error)
  }
}

function* createTopicSaga(action) {
  const { topic, callbackSuccess, callbackFailure } = action.payload

  try {
    yield call(() => {
      return backendAPI.post(TOPICS_ENDPOINT, topic)
    })

    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (callbackFailure) yield call(callbackFailure, error?.response?.data?.[0])
  }
}

function* updateTopicSaga(action) {
  const { topic, callbackSuccess, callbackFailure } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.patch(TOPICS_ENDPOINT + `${topic.id}/`, topic)
    })

    if (callbackSuccess) yield call(callbackSuccess, response.data)
  } catch (error) {
    if (callbackFailure) yield call(callbackFailure)
    console.error(error)
  }
}

function* startExportTopicsSaga(action) {
  try {
    let url = TOPICS_ENDPOINT + 'export/'
    if (action.payload) {
      const { params } = action.payload
      if (params) url += `?${getFilterQueryString(params)}`
    }

    const response = yield call(() => {
      return backendAPI.get(url, { responseType: 'arraybuffer' })
    })

    yield call(downloadExcelFile, response.data, 'topics.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExportTopics())
}

function* voteTopicSuggestionSaga(action) {
  const { id, voteType } = action.payload
  try {
    let url = TOPICS_ENDPOINT + 'suggestions/' + id + '/' + voteType + '/'
    const response = yield call(() => backendAPI.post(url))
    const suggestions = yield select(state => state.topics.suggestions)
    yield put(
      storeTopicSuggestions({
        results: suggestions.map(suggestion =>
          suggestion.id === response.data?.id ? response.data : suggestion
        ),
      })
    )
  } catch (error) {
    console.error(error)
  }
}

function* createTopicSuggestionSaga(action) {
  const { suggestion, callbackSuccess, callbackFailure } = action.payload
  try {
    const response = yield call(() =>
      backendAPI.post(TOPICS_ENDPOINT + 'suggestions/', { ...suggestion })
    )
    if (callbackSuccess) yield call(callbackSuccess)
    const suggestions = yield select(state => state.topics.suggestions)
    let updatedSuggestions = [response.data, ...suggestions]
    yield put(storeTopicSuggestions({ results: updatedSuggestions }))
  } catch (error) {
    console.error(error)

    if (callbackFailure) yield call(callbackFailure, error.response.data)
  }
}

function* deleteTopicSuggestionSaga(action) {
  const { id } = action.payload
  try {
    yield call(() => backendAPI.delete(TOPICS_ENDPOINT + `suggestions/${id}/`))
    const suggestions = yield select(state => state.topics.suggestions)
    let updatedSuggestions = suggestions.filter(
      suggestion => suggestion.id !== id
    )
    yield put(storeTopicSuggestions({ results: updatedSuggestions }))
  } catch (error) {
    console.error(error)
  }
}

function* topicsAPI() {
  yield takeLatest(loadTopics, loadTopicsSaga)
  yield takeLatest(loadTopicInstance, loadTopicInstanceSaga)
  yield takeLatest(loadTopicSuggestions, loadTopicSuggestionsSaga)
  yield takeLatest(loadIssues, loadIssuesSaga)
  yield takeLatest(createTopic, createTopicSaga)
  yield takeLatest(updateTopic, updateTopicSaga)
  yield takeLatest(startExportTopics, startExportTopicsSaga)
  yield takeLatest(createTopicSuggestion, createTopicSuggestionSaga)
  yield takeLatest(deleteTopicSuggestion, deleteTopicSuggestionSaga)
  yield takeLatest(voteTopicSuggestion, voteTopicSuggestionSaga)
}

export default topicsAPI
