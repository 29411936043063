import React, { useRef } from 'react'

import {
  Badge,
  Box,
  Card,
  CardBody,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

import { darkModeStatusColors, statusColors } from '../../constants'
import { CaseworkPriorityIndicator, NoValue } from '../ui'
import { dayAgeString, renderTextLines } from '../utils'

const AssignedCaseworkCard = ({
  case_num,
  priority,
  status,
  details,
  topics,
  constituent,
  last_updated,
  formattedCaseNum,
}) => {
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure()
  const bgColor = useColorModeValue('', 'gray.600')

  const menuButtonRef = useRef(null)
  const menuRef = useRef(null)

  const handleExpandCasework = e => {
    if (
      menuButtonRef.current?.contains(e.target) ||
      menuRef.current?.contains(e.target)
    )
      return
    onToggle()
  }

  return (
    <Card bg={bgColor}>
      <CardBody cursor='pointer' onClick={handleExpandCasework}>
        <Flex align='top'>
          <Stack direction='row'>
            <CaseworkPriorityIndicator priority={priority} />

            <Stack gap={1}>
              <Text as='b' fontSize='lg'>
                {constituent?.name || <NoValue />}
              </Text>
              <Box>
                <Badge
                  bgColor={statusColors[status.toUpperCase()]}
                  _dark={{
                    bgColor: darkModeStatusColors[status.toUpperCase()],
                  }}
                  color='offWhite'
                  mr={2}
                  p={1}
                >
                  {status}
                </Badge>
              </Box>
            </Stack>
          </Stack>
          <Spacer />
          <Menu>
            <MenuButton
              ref={menuButtonRef}
              as={IconButton}
              icon={<Icon as={EllipsisHorizontalIcon} fontSize='3xl' />}
              variant='ghost'
              colorScheme='gray'
            />
            <Portal>
              <MenuList ref={menuRef}>
                <MenuItem onClick={() => navigate(`/casework/${case_num}`)}>
                  View
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/casework/${case_num}/edit`)}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => window.open(`/casework/${case_num}`, 'blank')}
                >
                  Open in new tab
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Box mt={3} mb={2}>
            <Stack gap={0} mb={2}>
              <Text variant='subheading-sm'>Topic</Text>
              <Box>
                {topics?.length > 0 ? (
                  <Wrap>
                    {topics.map(({ name }, index) => (
                      <WrapItem key={`casework-topic-${case_num}-${index}`}>
                        <Tag variant='outline' size='sm'>
                          {name}
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <Tag variant='outline' size='sm' colorScheme='yellow'>
                    Unassigned
                  </Tag>
                )}
              </Box>
            </Stack>
            <Stack gap={0}>
              <Text variant='subheading-sm'>Details</Text>
              <Box maxH={200} overflow='hidden' overflowY='auto'>
                {renderTextLines(details)}
              </Box>
            </Stack>
          </Box>
        </Collapse>

        <Flex size='sm' mt={1} direction={{ base: 'column', sm: 'row' }}>
          <Text variant='subheading' fontSize='xs'>
            Last updated {dayAgeString(last_updated)}
          </Text>
          <Spacer />
          <Text variant='subheading-blue' fontSize='xs'>
            #{formattedCaseNum}
          </Text>
        </Flex>
      </CardBody>
    </Card>
  )
}

export default AssignedCaseworkCard
