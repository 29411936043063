import React, { useState } from 'react'

import {
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { districtOptions } from '../../../../constants'
import {
  loadCaseworkInstance,
  transferCaseworkInstance,
} from '../../../../redux/features/caseworkSlice'
import { formatDistrict } from '../../../../utils'
import { ConfirmActionDialog } from '../../../ui'

const TransferDialog = ({ case_num, ...props }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const [selectedDistrict, setSelectedDistrict] = useState('')
  const [transferReason, setTransferReason] = useState('')
  const casework = useSelector(state => state.casework.casework_instance)

  const handleConfirm = () => {
    if (!selectedDistrict || !transferReason) return

    dispatch(
      transferCaseworkInstance({
        values: {
          case_num,
          transfer_reason: transferReason,
          district_to: selectedDistrict,
        },
        callbackSuccess: () => {
          toast({
            title: `Succesfully transferred casework to district ${selectedDistrict}`,
            status: 'info',
          })
          dispatch(loadCaseworkInstance({ case_num }))
        },
        callbackFailure: () => {
          toast({
            title: `Something went wrong while transferring casework to district ${selectedDistrict}`,
            status: 'error',
          })
        },
      })
    )
    props.onClose()
  }

  return (
    <ConfirmActionDialog {...props} onConfirm={handleConfirm}>
      <Text as='b' fontSize='lg'>
        Transferring casework to another district...
      </Text>
      <Text>
        <b>Warning:</b> The constituent will be made visible to all districts.
      </Text>
      <Stack mt={2}>
        <Input
          as={Select}
          placeholder='District (Required)'
          value={selectedDistrict}
          onChange={e => setSelectedDistrict(e.target.value)}
        >
          {districtOptions
            ?.filter(district => district !== formatDistrict(casework.district))
            ?.map((district, index) => (
              <option value={district.padStart(2, '0')} key={index}>
                {district}
              </option>
            ))}
        </Input>

        <Textarea
          placeholder='Transfer reason (Required)'
          value={transferReason}
          onChange={e => setTransferReason(e.target.value)}
        />
      </Stack>
    </ConfirmActionDialog>
  )
}

export default TransferDialog
