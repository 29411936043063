import React from 'react'

import {
  Card,
  CardHeader,
  Divider,
  Flex,
  HStack,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { FormattedDate, NotFound, Person } from '../../../ui'

const ConstituentChangelog = () => {
  const constituent = useSelector(state => state.constituents.instance)

  const headerBgColor = useColorModeValue('blue.100', 'blue.700')
  const dividerColor = useColorModeValue('gray.600', 'gray.300')

  if (!constituent) {
    return <NotFound />
  }

  return constituent.changelog?.map((change, index) => {
    if (!change.history_user) return null
    return (
      <Card key={index} my={2} maxW='7xl' mx='auto'>
        <CardHeader borderRadius='md' bg={headerBgColor}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <Text as='b' casing='uppercase'>
              {change.history_type === '+'
                ? 'Constituent created'
                : change.history_type === '~'
                ? 'Constituent edited'
                : ''}
            </Text>
            <Spacer />
            <HStack>
              <Text as='b'>Timestamp:</Text>
              <FormattedDate date={change.history_date} hideAge />
            </HStack>
            <Divider
              mx='3'
              h={5}
              orientation='vertical'
              borderColor={dividerColor}
              display={{ base: 'none', md: 'block' }}
            />
            <HStack>
              <Text as='b'>Staff:</Text>
              {change.history_user ? (
                <Person
                  link={`/directory/staff/${change.history_user?.id}`}
                  name={change.history_user?.full_name}
                  hideAvatar
                />
              ) : (
                <Text>Web Development Unit</Text>
              )}
            </HStack>
          </Flex>
        </CardHeader>
      </Card>
    )
  })
}

export default ConstituentChangelog
