import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Badge,
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Input,
  Spacer,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react'
import { CheckIcon, LightbulbOffIcon, PencilIcon, XIcon } from 'lucide-react'

import ActiveStatusChangeModalSwitch from './ActiveStatusChangeModalSwitch'
import Topic from './Topic'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const EditableTopic = ({ id, issue, name, is_active }) => {
  const { handleUpdateTopic, topics } = useTopicsContext()
  const [topicNameValue, setTopicNameValue] = useState(name)
  const [isActive, setIsActive] = useBoolean(is_active)
  const [hovered, setHovered] = useBoolean(false)
  const [isEditing, setIsEditing] = useBoolean(false)

  useEffect(() => {
    setTopicNameValue(name)
  }, [setTopicNameValue, name])

  useEffect(() => {
    if (is_active) setIsActive.on()
    else setIsActive.off()
  }, [setIsActive, is_active])

  const handleCloseEdit = useCallback(() => {
    setTopicNameValue(name)
    setIsEditing.off()
    setHovered.off()
  }, [setIsEditing, setHovered])

  const handleSaveEdits = useCallback(() => {
    handleUpdateTopic({ id, name: topicNameValue }, handleCloseEdit)
  }, [handleUpdateTopic, topicNameValue, handleCloseEdit])

  const isInvalid = useMemo(
    () =>
      topics.some(
        t => t.name === topicNameValue && t.issue === issue && t.id !== id
      ),
    [topicNameValue, topics]
  )

  return (
    <Topic>
      {isEditing ? (
        <Box align='end'>
          <Input
            id={`topic-name-${id}`}
            value={topicNameValue}
            onChange={e => setTopicNameValue(e.target.value)}
            placeholder={name}
            isInvalid={isInvalid}
          />
          <Flex w='100%' align='center' mt={0.5}>
            {isInvalid && (
              <Badge colorScheme='red' variant='subtle'>
                Topic already exists
              </Badge>
            )}
            <Spacer />
            <Box>
              <ActiveStatusChangeModalSwitch
                id={id}
                isActive={isActive}
                setIsActive={setIsActive}
                initialChecked={is_active}
                cancelEditing={handleCloseEdit}
              />

              <IconButton
                ml={1}
                icon={<CheckIcon />}
                onClick={handleSaveEdits}
                variant='ghost'
                isDisabled={isInvalid}
              />
              <IconButton
                icon={<XIcon />}
                onClick={handleCloseEdit}
                colorScheme='red'
                variant='ghost'
              />
            </Box>
          </Flex>
        </Box>
      ) : (
        <Flex
          align='center'
          onMouseOver={setHovered.on}
          onMouseOut={setHovered.off}
        >
          {!isActive && (
            <Tooltip label='Inactive topic' shouldWrapChildren={true}>
              <Center>
                <Icon as={LightbulbOffIcon} color='gray.500' mr={2} />
              </Center>
            </Tooltip>
          )}
          <Text color={!isActive && 'gray.500'}>{topicNameValue}</Text>
          <Spacer />
          {hovered && (
            <Center verticalAlign='center'>
              <Icon
                as={PencilIcon}
                fontSize='24'
                strokeWidth='1.5'
                color='blue.500'
                cursor='pointer'
                onClick={setIsEditing.on}
              />
            </Center>
          )}
        </Flex>
      )}
    </Topic>
  )
}

export default EditableTopic
