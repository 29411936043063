import React, { useCallback, useMemo } from 'react'

import { useField } from 'formik'

import { AddressForm } from '../../../../ui'
import { isAddressEmpty } from '../../../../utils'

const AddressField = ({ field = '', type = 'home' }) => {
  const fieldName = type + '_address'

  const [addressField] = useField(field + fieldName)
  const { value: address } = addressField

  const [primaryAddressField, , helpers] = useField(field + 'primary_address')
  const { setValue: setPrimaryAddress } = helpers
  const { value: primaryAddress } = primaryAddressField

  const handleSetPrimaryAddress = useCallback(
    value => setPrimaryAddress(value),
    [setPrimaryAddress]
  )

  const isAddressPrimary = useMemo(
    () => primaryAddress === type && !isAddressEmpty(address),
    [primaryAddress, isAddressEmpty, address]
  )

  return (
    <AddressForm
      field={field + fieldName}
      isPrimary={isAddressPrimary}
      setPrimary={() => handleSetPrimaryAddress(type)}
    />
  )
}

export default AddressField
