import React from 'react'

import { ConfirmActionDialog } from '../../../ui'

const ConfirmDeleteTagDialog = ({ label, count, ...props }) => {
  return (
    <ConfirmActionDialog {...props}>
      <b>{label}</b> will be removed from <b>{count}</b> casework with this tag
      and permanently deleted. Are you sure you want to continue?
    </ConfirmActionDialog>
  )
}

export default ConfirmDeleteTagDialog
