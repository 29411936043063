import * as Yup from 'yup'

import { formatContactInfo } from '../components/casework/casework/caseworkForm/utils'
import { isAddressEmpty } from '../components/utils'

export const languages = [
  'Amharic, Somali, or other Afro-Asiatic languages',
  'Arabic',
  'Armenian',
  'Bengali',
  'Cajun',
  'Chinese (Cantonese)',
  'Chinese (Mandarin)',
  'Chinese (Other)',
  'Creole - Haitian',
  'Creole - Other',
  'English',
  'French',
  'German',
  'Greek',
  'Gujarati',
  'Hebrew',
  'Hindi',
  'Japanese',
  'Korean',
  'Malayalam, Kannada, or other Dravidian languages',
  'Nepali, Marathi, or other Indic languages',
  'Other African languages',
  'Other Slavic languages',
  'Persian (incl. Farsi, Dari)',
  'Polish',
  'Portuguese',
  'Russian',
  'Serbo-Croatian',
  'Spanish',
  'Swahili or other languages of Central, Eastern, and Southern Africa',
  'Tagalog (incl. Filipino)',
  'Tamil',
  'Telugu',
  'Thai, Lao, or other Tai-Kadai languages',
  'Ukrainian or other Slavic languages',
  'Urdu',
  'Vietnamese',
  'Yiddish, Pennsylvania Dutch or other West Germanic languages',
  'Yoruba, Twi, Igbo, or other languages of Western Africa',
]

export const intakeMethodOptions = [
  'Called in',
  'Emailed',
  'Event',
  'Letter',
  'Mail',
  'Social Media',
  'Text Message',
  'Walk in',
]

export const referralCategoryOptions = ['Council Member', 'Elected Official']

export const statusOptions = [
  'Pending',
  'In Progress',
  'On Hold - Agency',
  'On Hold - Constituent',
  'Closed',
  'Transferred',
  'New Transfer',
]

export const priorityOptions = ['Low', 'Medium', 'High']

export const districtOptions = Array.from({ length: 51 }, (_, i) =>
  String(i + 1)
).concat('Central Staff')

export const contactTypeOptions = [
  'Email',
  'Cell Phone',
  'Work Phone',
  'Home Phone',
  'Other',
]

export const categoryOptions = ['Individual', 'Business']
export const initialAddressValues = {
  label: '',
  line1: '',
  line2: '',
  po_box: '',
  city: '',
  state: 'NY',
  zipcode: '',
  verified: false,
}

export const initialConstituentValues = {
  category: 'Individual',
  title: '',
  name: '',
  preferred_name: '',
  pronouns: '',
  home_address: initialAddressValues,
  business_address: initialAddressValues,
  primary_address: 'home',
  contact_info: [
    {
      contact_type: 'Email',
      contact_data: '',
      description: '',
      is_preferred_method: true,
    },
    {
      contact_type: 'Cell Phone',
      contact_data: '',
      description: '',
      is_preferred_method: false,
    },
  ],
  languages: [],
  tags: [],
  groups: [],
  notes: '',
  visible_to_district_only: true,
}

export const initializeConstituentValues = constituent => {
  let initValues = {
    ...initialConstituentValues,
    ...constituent,
  }

  if (!initValues.home_address) {
    initValues.home_address = initialAddressValues
  }
  if (!initValues.business_address) {
    initValues.business_address = initialAddressValues
  }

  return { ...initValues }
}

export const addressValidationSchema = Yup.object().shape({
  label: Yup.string(),
  line1: Yup.string(),
  line2: Yup.string(),
  po_box: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipcode: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^\d{5}$/, 'Invalid 5 digit zipcode'),
})

const contactInfoShape = Yup.object().shape({
  contact_type: Yup.string(),
  contact_data: Yup.string(),
  description: Yup.string(),
  is_preferred_method: Yup.boolean(),
})

export const constituentShape = {
  title: Yup.string(),
  name: Yup.string(),
  preferred_name: Yup.string(),
  suffix: Yup.string(),
  pronouns: Yup.string(),
  home_address: addressValidationSchema,
  business_address: addressValidationSchema,
  primary_address: Yup.string(),
  languages: Yup.array().of(Yup.string()),
  contact_info: Yup.array().of(contactInfoShape),
  tags: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      district: Yup.string(),
    })
  ),
  groups: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      district: Yup.string(),
    })
  ),
  notes: Yup.string(),
  visible_to_district_only: Yup.bool(),
}

export const constituentValidationSchema = Yup.object().shape(constituentShape)

export const formatConstituentValues = values => {
  const formattedValues = { ...values }

  formattedValues.contact_info = formatContactInfo(formattedValues.contact_info)

  if (isAddressEmpty(formattedValues.home_address))
    formattedValues.home_address = null

  if (isAddressEmpty(formattedValues.business_address))
    formattedValues.business_address = null

  if (!formattedValues.home_address && formattedValues.business_address)
    formattedValues.primary_address = 'business'

  if (!formattedValues.business_address && formattedValues.home_address)
    formattedValues.primary_address = 'home'

  return { ...formattedValues }
}

export const groupValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  alias: Yup.string(),
  description: Yup.string(),
  role: Yup.string(),
  constituents: Yup.array().of(Yup.object()),
})

export const editStaffValidationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string().email(),
    first_name: Yup.string().required('First name is required'),
    middle_name: Yup.string(),
    last_name: Yup.string().required('Last name is required'),
    preferred_name: Yup.string(),
    suffix: Yup.string(),
    pronouns: Yup.string(),
  }),
  role: Yup.string(),
  district: Yup.string(),
})
