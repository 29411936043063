import React from 'react'

import { Card, CardBody } from '@chakra-ui/react'

import ReportSectionHeading from './ReportSectionHeading'
import SortableTable from './SortableTable'

const StaffBreakdown = ({ staffBreakdown }) => {
  if (!staffBreakdown || staffBreakdown.length === 0) return <></>

  const valueKeys = [
    {
      label: 'Staff Name',
      value: 'staff',
    },
    {
      label: 'Opened',
      value: 'opened',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Updated',
      value: 'updated',
    },
    { label: 'Intake Done', value: 'intake_done' },
  ]
  return (
    <Card maxH={750} overflowY='auto'>
      <CardBody>
        <ReportSectionHeading>Staff Breakdown</ReportSectionHeading>

        <SortableTable
          values={staffBreakdown}
          valueKeys={valueKeys}
          defaultSort='opened'
        />
      </CardBody>
    </Card>
  )
}
export default StaffBreakdown
