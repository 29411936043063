import React, { useMemo, useRef } from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'

import { paginate } from '../../../redux/features/caseworkSlice'

const CaseworkPagination = ({ pageSize = 15, type, params }) => {
  const dispatch = useDispatch()
  const count = useSelector(state => state.casework.count)
  const currentPage = useSelector(state => state.casework.page)

  const totalPages = Math.ceil(count / pageSize)

  const jumpToRef = useRef(null)

  const handleSelectPage = page => {
    if (page !== currentPage) dispatch(paginate({ page, type, params }))
  }

  function PageButton({ page, ...props }) {
    const onClick =
      typeof page === 'number' ? () => handleSelectPage(page) : () => {}
    return (
      <Button onClick={onClick} isActive={currentPage === page} {...props}>
        {page}
      </Button>
    )
  }

  const pagesRemaining = totalPages - currentPage
  const MIN_PAGES = 5
  const pages = useMemo(() => {
    if (totalPages <= MIN_PAGES) {
      return Array.from({ length: totalPages }, (_, i) => i + 1)
    }
    if (pagesRemaining + 1 <= MIN_PAGES && totalPages >= MIN_PAGES) {
      return Array.from(
        { length: MIN_PAGES },
        (_, i) => totalPages - i
      ).reverse()
    }

    if (pagesRemaining > MIN_PAGES && totalPages >= MIN_PAGES) {
      return [
        currentPage,
        currentPage + 1,
        currentPage + 2,
        '...',
        totalPages - 1,
        totalPages,
      ]
    }

    let arr = []

    for (let i = 0; i < MIN_PAGES; i++) {
      if (i < 3) {
        if (pagesRemaining > MIN_PAGES) arr.push(currentPage + i)
        else {
          let page = totalPages - MIN_PAGES + i
          if (0 < page && page < totalPages) arr.push(page)
        }
      }

      if (arr.length === 3 && pagesRemaining >= MIN_PAGES) {
        arr.push('...')
      }

      if (i >= 3) {
        let page = totalPages - MIN_PAGES + 1 + i
        if (0 < page && page <= totalPages) {
          arr.push(page)
        }
      }
    }

    return arr
  }, [currentPage, totalPages, pagesRemaining])

  const handleJumpTo = e => {
    if (e.key === 'Enter') {
      const page = Number(e.target.value)
      if (typeof page === 'number') {
        if (page < 1) handleSelectPage(1)
        else if (page > totalPages) handleSelectPage(totalPages)
        else handleSelectPage(page)
        e.target.value = ''
        e.target.blur()
      }
    }
  }

  const buttonColorScheme = useColorModeValue('blackAlpha', 'gray')
  if (!totalPages) return null
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      maxW='7xl'
      p={2}
      mx='auto'
    >
      <Box my='auto'>
        <Text variant='subheading-gray'> {count} Results </Text>
      </Box>
      <ButtonGroup isAttached shadow='base' colorScheme={buttonColorScheme}>
        <Button
          onClick={() => handleSelectPage(1)}
          isDisabled={currentPage === 1}
        >
          First
        </Button>

        <IconButton
          icon={<ChevronLeftIcon size='16' />}
          onClick={() => handleSelectPage('previous')}
          isDisabled={currentPage === 1}
        />

        {pages?.map(page => (
          <PageButton key={`page-${page}`} page={page} />
        ))}

        <IconButton
          icon={<ChevronRightIcon size='16' />}
          onClick={() => handleSelectPage('next')}
          isDisabled={currentPage === totalPages}
        />

        <PageButton
          page='Last'
          onClick={() => handleSelectPage(totalPages)}
          isDisabled={currentPage === totalPages}
        />
      </ButtonGroup>

      <ButtonGroup
        isAttached
        colorScheme={buttonColorScheme}
        shadow='base'
        visibility={totalPages > MIN_PAGES ? 'visible' : 'hidden'}
      >
        <Button isActive borderRight='1px solid white'>
          Jump To
        </Button>
        <Button onClick={() => jumpToRef.current?.focus()} cursor='text'>
          <Input
            id='jumpToPage'
            variant='unstyled'
            w='40px'
            p={0}
            onKeyDown={handleJumpTo}
            ref={jumpToRef}
          />
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default CaseworkPagination
