import React, { useCallback, useMemo } from 'react'

import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import {
  ButtonGroup,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Select,
  Tooltip,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  DocumentMinusIcon,
  DocumentPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useField } from 'formik'

import FormField from './FormField'
import ActiveStarIcon from '../../../assets/icons/ActiveStarIcon'
import InactiveStarIcon from '../../../assets/icons/InactiveStarIcon'
import { contactTypeOptions } from '../../../constants'
import { formatPhoneNumber } from '../../utils'

const ContactInfoForm = ({
  index,
  field = 'contact_info',
  contact_type,

  description,
  remove,
  replace,
}) => {
  const [showDescField, setShowDescField] = useBoolean(description)

  const [formikField] = useField(field)
  const { value } = formikField

  const contactButtonColor = useColorModeValue('gray.300', 'gray.400')
  const contactButtonHoverColor = useColorModeValue('black', 'gray.100')

  const contactButtonStyles = {
    variant: 'unstyled',
    size: 'sm',
    _hover: { color: contactButtonHoverColor },
    color: contactButtonColor,
  }

  const contactTypeValue = useMemo(
    () =>
      contact_type.includes('Phone')
        ? 'tel'
        : contact_type.includes('Email')
        ? 'email'
        : // default
          'text',
    [contact_type]
  )

  const validateEmail = useCallback(
    emailToValidate => {
      if (!contact_type.includes('Email') || !emailToValidate) return
      let errorMessage
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailToValidate)) {
        errorMessage = 'Invalid email address'
      }
      return errorMessage
    },
    [contact_type]
  )

  const onContactDataBlur = useCallback(
    e => {
      let contactData = e.target.value
      if (contact_type.includes('Phone')) {
        replace(index, {
          ...value[index],
          contact_data: formatPhoneNumber(contactData),
        })
      }
    },
    [contact_type, value, replace]
  )

  const handleRemoveDescription = useCallback(() => {
    setShowDescField.off()
    replace(index, { ...value[index], description: '' })
  }, [replace, value, index])

  const handleRemoveContactInfo = useCallback(() => {
    remove(index)
  }, [remove, index])

  const contactDataPlaceholderIcon = useMemo(
    () =>
      contact_type.includes('Phone')
        ? PhoneIcon
        : contact_type.includes('Email')
        ? EmailIcon
        : null,
    [contact_type]
  )

  const handleMarkPreferred = useCallback(() => {
    replace(index, {
      ...value[index],
      is_preferred_method: !value[index].is_preferred_method,
    })
  }, [replace, value])

  return (
    <Grid
      columnGap={2}
      gridTemplateColumns={{ sm: '1fr', md: 'auto 1fr' }}
      gap={1}
      templateAreas={{
        base: `
        "type"
        "data"
        "description"
        "menu"
        `,
        md: `
        "type data menu"
        "type description description"
      `,
      }}
    >
      <GridItem area='type'>
        <FormField
          field={`${field}.${index}.contact_type`}
          placeholder='Category *'
          inputType={Select}
          options={contactTypeOptions}
        />
      </GridItem>
      <GridItem area='data'>
        <FormField
          field={`${field}.${index}.contact_data`}
          isDisabled={!contact_type}
          placeholder={!contact_type ? 'Select category first' : 'Contact Data'}
          placeholderIcon={contactDataPlaceholderIcon}
          type={contactTypeValue}
          onBlur={onContactDataBlur}
          validate={validateEmail}
        />
      </GridItem>
      <GridItem area='menu' align='end'>
        <ButtonGroup pt={2.5} spacing={0}>
          <Tooltip label='Mark as preferred method of communication'>
            <IconButton
              {...contactButtonStyles}
              aria-label='Mark preferred method of communication'
              icon={
                value[index].is_preferred_method ? (
                  <ActiveStarIcon />
                ) : (
                  <InactiveStarIcon />
                )
              }
              onClick={handleMarkPreferred}
            />
          </Tooltip>
          {showDescField ? (
            <Tooltip label='Remove description'>
              <IconButton
                {...contactButtonStyles}
                aria-label='Remove contact info description'
                icon={<Icon as={DocumentMinusIcon} fontSize={24} />}
                onClick={handleRemoveDescription}
              />
            </Tooltip>
          ) : (
            <Tooltip label='Add description'>
              <IconButton
                {...contactButtonStyles}
                aria-label='Add contact info description'
                icon={<Icon as={DocumentPlusIcon} fontSize={24} />}
                onClick={setShowDescField.on}
              />
            </Tooltip>
          )}

          <Tooltip label='Remove contact info'>
            <IconButton
              {...contactButtonStyles}
              aria-label='Remove contact info'
              icon={<Icon as={XMarkIcon} fontSize={28} />}
              onClick={handleRemoveContactInfo}
            />
          </Tooltip>
        </ButtonGroup>
      </GridItem>
      {showDescField && (
        <GridItem area='description'>
          <FormField
            field={`${field}.${index}.description`}
            placeholder='Description'
            variant='flushed'
            otherProps={{
              borderTop: 0,
              borderX: 0,
              paddingLeft: 4,
            }}
          />
        </GridItem>
      )}
    </Grid>
  )
}

export default ContactInfoForm
