import React, { useCallback, useMemo } from 'react'

import { Box, Heading, Stack } from '@chakra-ui/react'
import { FieldArray, useField } from 'formik'

import Constituent from './Constituent'
import { ConstituentSelect } from '../../../ui/select'

const AddRemoveConstituents = () => {
  const [field, ,] = useField('constituents')
  const { value: constituents } = field

  const removeConstituent = useCallback(
    (index, remove, replace) => {
      if (constituents[index].status === 'new') {
        return remove(index)
      }

      let updatedConstituent = { ...constituents[index] }
      if (constituents[index].status === 'removed') {
        updatedConstituent.status = undefined
        return replace(index, updatedConstituent)
      }

      updatedConstituent.status = 'removed'
      return replace(index, updatedConstituent)
    },
    [constituents]
  )

  const selectedConstituents = useMemo(
    () =>
      constituents?.map(c => ({
        label: c.name,
        value: c,
      })) || [],
    [constituents]
  )

  return (
    <Box mt={3} w='100%'>
      <Heading size='sm' mb={2} color='blue.500'>
        Add/Remove Constituents
      </Heading>
      <FieldArray name='constituents'>
        {({ unshift, remove, replace }) => (
          <>
            <ConstituentSelect
              inputProps={{
                id: 'add-constituent-select',
                placeholder: 'Search for a constituent',
              }}
              districtOnly
              initialSelected={selectedConstituents}
              enableClear={false}
              onSelect={constituent =>
                unshift({ ...constituent, status: 'new', role: '' })
              }
              showTags={false}
            />

            <Stack maxH='50vh' overflowY='auto' my={2}>
              {constituents?.map((c, index) => (
                <Constituent
                  {...c}
                  key={index}
                  index={index}
                  onClick={() => removeConstituent(index, remove, replace)}
                />
              ))}
            </Stack>
          </>
        )}
      </FieldArray>
    </Box>
  )
}

export default AddRemoveConstituents
