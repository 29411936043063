import React, { useState } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Link,
  Box,
  Spacer,
  Tag,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { daysSinceDate } from '../utils'

const PasswordChangeAlert = () => {
  const user = useSelector(state => state.auth.user)
  const [showBanner, setShowBanner] = useState(
    user?.password_last_changed === null ||
      daysSinceDate(user?.password_last_changed) > 83
  )

  if (!showBanner) return <></>
  return (
    <Box
      display='flex'
      justifyContent='center'
      w='full'
      px={{ base: '2', lg: '6' }}
      position='absolute'
      zIndex='sticky'
    >
      <Alert
        as={Tag}
        variant='solid'
        colorScheme='yellow'
        status='warning'
        w={{ base: 'full', lg: '7xl' }}
        borderRadius='md'
      >
        <AlertIcon />
        <AlertTitle> Action Needed! </AlertTitle>
        <AlertDescription>
          Please change your password under your profile to secure your
          account.&nbsp;
          <Link href={`/directory/staff/${user.id}`}>
            Click here to navigate to your profile.
          </Link>
        </AlertDescription>

        <Spacer />
        <CloseButton onClick={() => setShowBanner(false)} />
      </Alert>
    </Box>
  )
}

export default PasswordChangeAlert
