import { isAddressEmpty } from '../../../utils'

export const validateForm = values => {
  if (!values) return {}
  const errors = {}
  let blanks = []

  let fields = [
    'name',
    'preferred_name',
    'contact_info',
    'home_address',
    'business_address',
  ]

  if (values.category === 'business') {
    fields = ['name', 'contact_info', 'business_address']
  }
  for (let key of fields) {
    if (
      !values[key] ||
      (typeof values[key] === 'string' && !values[key].trim())
    ) {
      blanks.push(key)
    } else if (key === 'contact_info' && values[key].length) {
      if (values[key].every(cinfo => !cinfo.contact_data)) blanks.push(key)
    } else if (key.includes('address') && isAddressEmpty(values[key]))
      blanks.push(key)
  }
  if (blanks.length === fields.length) {
    for (let blank of blanks)
      errors[blank] = `Please fill out at least one identifying field.`
  }
  return errors
}
