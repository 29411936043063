import React from 'react'

import {
  Box,
  Card,
  CardBody,
  Center,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const Tools = () => {
  document.title = 'Tools | Council Connect'
  const navigate = useNavigate()

  function ToolCard({ image, label, onClick = () => {} }) {
    return (
      <Card
        w='400px'
        h='550px'
        onClick={onClick}
        cursor='pointer'
        bg='white'
        _hover={{
          shadow: 'lg',
          transform: 'translateY(-0.5em)',
        }}
      >
        <CardBody>
          <Center h='100%'>
            <Stack>
              <Center h='450px'>{image}</Center>
              <Text
                fontSize='xl'
                variant='subheading-blue'
                w='100%'
                align='center'
                textDecoration='none'
              >
                {label}
              </Text>
            </Stack>
          </Center>
        </CardBody>
      </Card>
    )
  }
  return (
    <Box bg='blackAlpha.50' h='100%' w='100%'>
      <HStack
        direction={{ base: 'column', md: 'row' }}
        justify='center'
        gap={5}
        pt={5}
      >
        <ToolCard
          image=<Image src='/assets/tools/reports.jpg' alt='Reports tool' />
          label='Reports'
          onClick={() => navigate('/tools/reports')}
        />
        <ToolCard
          image=<Image
            src='/assets/tools/address-lookup.jpg'
            alt='Address Lookup tool'
          />
          onClick={() => navigate('/tools/address-lookup')}
          label='Address Lookup'
        />
        <ToolCard
          image=<Image
            src='/assets/tools/compendium.jpg'
            alt='Compendium tool'
          />
          onClick={() => navigate('/tools/compendium')}
          label='Compendium'
        />
      </HStack>
    </Box>
  )
}

export default Tools
