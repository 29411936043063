import React from 'react'

import {
  Badge,
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Spacer,
  Text,
} from '@chakra-ui/react'
import { InfoIcon } from 'lucide-react'

import { AttachmentsField } from '../../../ui'

const AttachmentsPage = () => {
  return (
    <Flex direction='column' h='100%'>
      <AttachmentsField />
      <Spacer />
      <Card size='sm' position='sticky' bottom={0}>
        <CardBody>
          <HStack>
            <Icon as={InfoIcon} />
            <Text>
              Files that contain PII will be&nbsp;
              <Badge colorScheme='red' fontSize='md'>
                deleted
              </Badge>
              &nbsp;from Council Connect once this casework is closed.
            </Text>
          </HStack>
        </CardBody>
      </Card>
    </Flex>
  )
}

export default AttachmentsPage
