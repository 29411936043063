import React from 'react'

import { Center, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import {
  AtSignIcon,
  Building2Icon,
  FileIcon,
  HouseIcon,
  PhoneIcon,
} from 'lucide-react'

import ActiveStarIcon from '../../../../../assets/icons/ActiveStarIcon'

const ContactInfoLabel = ({ category, isPref }) => {
  const categoryIcon = () => {
    if (category.includes('Phone')) return PhoneIcon
    if (category.includes('Email')) return AtSignIcon
    if (category.includes('Business')) return Building2Icon
    if (category.includes('Home')) return HouseIcon
    return FileIcon
  }

  return (
    <Flex direction='row' gap={1} align='center'>
      <Icon as={categoryIcon()} />
      <Text as='b'> {category} </Text>
      {isPref && (
        <Tooltip label='Preferred method of communication'>
          <Center>
            <ActiveStarIcon size={16} />
          </Center>
        </Tooltip>
      )}
    </Flex>
  )
}

export default ContactInfoLabel
