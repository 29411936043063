import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const topicsSlice = createSlice({
  initialState,
  name: 'topics',
  reducers: {
    loadTopics(state) {
      state.loading = true
    },
    loadTopicInstance() {},
    loadTopicSuggestions() {},

    setTopics(state, action) {
      const { topics } = action.payload
      if (topics) state.data = topics
    },
    loadIssues() {},

    updateTopic() {},
    createTopic() {},

    storeTopics(state, action) {
      state.loading = false
      state.data = action.payload
    },
    storeTopicInstance(state, action) {
      state.instance = action.payload
    },
    storeIssues(state, action) {
      state.issues = action.payload
    },
    storeTopicSuggestions(state, action) {
      const { results, next, previous, count } = action.payload
      if (results) state.suggestions = [...results]
      if (next) state.suggestions_next = next
      if (previous) state.suggestions_previous = previous
      if (count) state.suggestions_count = count
    },

    voteTopicSuggestion() {},
    createTopicSuggestion() {},
    deleteTopicSuggestion() {},
    removeTopicSuggestion(state, action) {
      const { id } = action.payload
      const index = state.suggestions.findIndex(
        suggestion => suggestion.id === id
      )
      if (index !== -1) state.suggestions.splice(index, 1)
    },
    clearTopicSuggestions(state) {
      state.suggestions = []
      delete state.suggestions_next
      delete state.suggestions_previous
      delete state.suggestions_count
    },

    startExportTopics(state) {
      state.exporting = true
    },
    completeExportTopics(state) {
      delete state.exporting
    },
  },
})

export default topicsSlice.reducer

export const {
  loadTopics,
  loadTopicInstance,
  loadTopicSuggestions,
  loadIssues,

  updateTopic,
  createTopic,

  setTopics,
  storeTopics,
  storeTopicInstance,
  storeTopicSuggestions,
  storeIssues,

  voteTopicSuggestion,
  createTopicSuggestion,
  deleteTopicSuggestion,
  removeTopicSuggestion,
  clearTopicSuggestions,

  startExportTopics,
  completeExportTopics,
} = topicsSlice.actions
