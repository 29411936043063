import React from 'react'

import {
  Badge,
  Box,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'

import {
  loadCaseworkInstance,
  updateCaseworkInstance,
} from '../../../../redux/features/caseworkSlice'
import { calculateMS } from '../../../../utils'
import { ConfirmActionDialog } from '../../../ui'

const CloseDialog = ({ case_num, ...props }) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const handleConfirm = () => {
    dispatch(
      updateCaseworkInstance({
        values: {
          case_num,
          status: 'Closed',
        },
        callbackSuccess: () => {
          dispatch(loadCaseworkInstance({ case_num }))
          toast({
            title: 'Successfully closed casework',
            status: 'info',
            duration: calculateMS(3),
          })
        },
        callbackFailure: () => {
          toast({
            title: 'Something went wrong trying to close this casework.',
            status: 'error',
            duration: calculateMS(3),
          })
        },
      })
    )
    props.onClose()
  }

  return (
    <ConfirmActionDialog {...props} onConfirm={handleConfirm}>
      <Text as='b'> This casework will be closed. </Text>
      <Box>
        <UnorderedList>
          <ListItem>
            Further edits will be disabled until casework is reopened.
          </ListItem>
          <ListItem>Activity can still be created and edited.</ListItem>
          <ListItem>
            This case will still be viewable under the casework list.
          </ListItem>
          <ListItem>
            Cases older than 90 days can only be reopened by your
            district&apos;s Chief of Staff or Director of Constituent Services.
          </ListItem>
          <ListItem>
            <Badge colorScheme='green' mr={1}>
              New
            </Badge>
            Any attachments containing PII will be <b>deleted</b> in compliance
            with OGC.
          </ListItem>
          <ListItem>
            If case is currently <b>Pending</b>, opened date will automatically
            be set to closed date.
          </ListItem>
        </UnorderedList>
      </Box>
    </ConfirmActionDialog>
  )
}
export default CloseDialog
