import React from 'react'

import {
  Box,
  Text,
  Flex,
  Spacer,
  Heading,
  Icon,
  HStack,
  Badge,
} from '@chakra-ui/react'
import { UserIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'

import GroupsOptionsMenu from './GroupsOptionsMenu'
import { formatDistrict } from '../../../utils'
import { renderTextLines } from '../../utils'

const GroupCard = ({ group, openDetailDrawer, openEditModal, onClose }) => {
  const user = useSelector(state => state.auth.user)
  return (
    <Box
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      p={3}
      my='2'
      cursor='pointer'
      onClick={() => openDetailDrawer(group)}
      position='relative'
    >
      <Flex>
        <Text
          as={Heading}
          variant='subheading-blue'
          casing='inherit'
          fontSize={{ base: 'md', sm: 'lg' }}
        >
          {group.name} {group.alias && `(${group.alias})`}
        </Text>

        <Spacer />

        <GroupsOptionsMenu
          openEditModal={openEditModal}
          group={group}
          onClose={onClose}
        />
      </Flex>
      <HStack align='center' gap={1}>
        <Icon as={UserIcon} />
        <Text variant='subheading-sm'>{group.member_count}</Text>
      </HStack>
      {user.is_admin && (
        <Box>
          <Badge>District {formatDistrict(group.district)}</Badge>
        </Box>
      )}
      {group.description && renderTextLines(group.description)}
    </Box>
  )
}

export default GroupCard
