import React, { useCallback, useMemo } from 'react'

import { useFormikContext } from 'formik'

import { AgencySelect } from '../../../../ui/select'

const AgenciesField = () => {
  const { values, setFieldValue } = useFormikContext()

  const onAgencySelect = useCallback(
    option => {
      setFieldValue('agencies', [...values.agencies, option])
    },
    [setFieldValue, values.agencies]
  )

  const onAgencyRemove = useCallback(
    removeIndex => {
      setFieldValue('agencies', values.agencies.toSpliced(removeIndex, 1))
    },
    [setFieldValue, values.agencies]
  )

  const onAgencyClear = useCallback(() => {
    setFieldValue('agencies', [])
  }, [setFieldValue])

  const selectedAgencies = useMemo(
    () =>
      values?.agencies?.map(agency => ({
        label: agency,
        value: agency,
      })) || [],
    [values.agencies]
  )

  return (
    <AgencySelect
      inputProps={{
        id: 'agency-select',
        placeholder: 'Search for city agency',
      }}
      initialSelected={selectedAgencies}
      onSelect={onAgencySelect}
      onRemove={onAgencyRemove}
      onClear={onAgencyClear}
    />
  )
}

export default AgenciesField
