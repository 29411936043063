import React from 'react'

import { Icon } from '@chakra-ui/react'
import { StarIcon } from '@heroicons/react/24/solid'

const ActiveStarIcon = ({ size = 24 }) => {
  return <Icon as={StarIcon} fontSize={size} color='blue.400' />
}

export default ActiveStarIcon
