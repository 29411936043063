import React, { useCallback } from 'react'

import { AddIcon } from '@chakra-ui/icons'
import { Button, Stack } from '@chakra-ui/react'
import { FastField, FieldArray, useField } from 'formik'

import { ContactInfoForm } from '../../../../ui'

const ContactInfoField = ({ field = 'contact_info' }) => {
  const [formikField] = useField(field)
  const { value } = formikField
  const handleAddContactInfo = useCallback(
    push =>
      push({
        contact_type: '',
        contact_data: '',
        description: '',
        is_preferred_method: false,
      }),
    []
  )

  return (
    <Stack>
      <FastField name={field}>
        {() => (
          <FieldArray name={field}>
            {({ push, ...arrayHelpers }) => (
              <>
                {value?.map((contact, index) => (
                  <ContactInfoForm
                    field={field}
                    key={index}
                    index={index}
                    {...contact}
                    {...arrayHelpers}
                  />
                ))}
                {/* ADD CONTACT INFO BUTTON */}
                <Button
                  variant='link'
                  onClick={() => handleAddContactInfo(push)}
                  leftIcon={<AddIcon />}
                  h='2rem'
                >
                  Add phone number or email
                </Button>
              </>
            )}
          </FieldArray>
        )}
      </FastField>
    </Stack>
  )
}

export default ContactInfoField
