// This function compares two objects and returns true if objects are the same and false otherwise
export function isEqualObject(obj1, obj2) {
  if (obj1 === obj2) {
    return true
  }

  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  ) {
    return false
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!isEqualObject(obj1[i], obj2[i])) {
        return false
      }
    }
    return true
  }

  if (Array.isArray(obj1) !== Array.isArray(obj2)) {
    return false
  }

  let keys1 = Object.keys(obj1)
  let keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false
    }
    if (!isEqualObject(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const isMatchingObject = (lhs, rhs, keys) =>
  lhs && rhs && keys.every(key => lhs[key] === rhs[key])

export function capitalize(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function toTitleCase(str) {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .split(' ') // Split the string into an array of words
    .map(
      (
        word // Map over each word in the array
      ) =>
        word.charAt(0).toUpperCase() + // Capitalize the first letter of each word
        word.slice(1) // Append the rest of the word in lowercase
    )
    .join(' ') // Join the words back into a single string
}

export function getFilterQueryString(filters) {
  if (!filters) return ''

  return Object.keys(filters)
    .map(key => {
      let value = filters[key]
      if (typeof value !== 'string' && Array.isArray(value)) {
        value = value.map(x =>
          typeof x === 'string'
            ? x
            : typeof x === 'boolean'
            ? x.toString()
            : x.id
        )
        if (key === 'agencies') value = value.join(';')
        else if (key === 'district__in')
          value = value.map(value => value.padStart(2, '0'))
        else value = value.join(',')
      }
      return `${key}=${value}`
    })
    .join('&')
}

/** This function calculates the number of milliseconds
 * Modes:
 * - s: ms to seconds (default)
 * - m: ms to minutes
 * - h: ms to hours
 */
export function calculateMS(number, type = 's') {
  const s = 1000
  const m = s * 60
  const h = m * 60
  if (type === 'm') {
    return m * number
  } else if (type === 'h') {
    return h * number
  }

  return s * number
}

export function formatDateToString(date) {
  const year = date.getFullYear().toString() // Get full year
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Get month (+1 because months are zero-indexed) and pad with leading zero if necessary
  const day = date.getDate().toString().padStart(2, '0') // Get day and pad with leading zero if necessary

  return `${year}-${month}-${day}`
}

export function sortValues(values, sortCategory, sortAsc) {
  return [...values].sort((a, b) => {
    const valueA = a[sortCategory]
    const valueB = b[sortCategory]

    // Handle null, undefined, or empty string values
    if (valueA === null || valueA === undefined || valueA === '') return 1
    if (valueB === null || valueB === undefined || valueB === '') return -1

    // Convert to lowercase if values are strings for case-insensitive comparison
    const valueAToCompare =
      typeof valueA === 'string' ? valueA.toLowerCase() : valueA
    const valueBToCompare =
      typeof valueB === 'string' ? valueB.toLowerCase() : valueB

    if (sortAsc) {
      return valueAToCompare < valueBToCompare
        ? -1
        : valueAToCompare > valueBToCompare
        ? 1
        : 0
    } else {
      return valueBToCompare < valueAToCompare
        ? -1
        : valueBToCompare > valueAToCompare
        ? 1
        : 0
    }
  })
}

export function downloadExcelFile(fileData, fileName) {
  const blob = new Blob([fileData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  downloadFile(blob, fileName)
}

export function downloadFile(file, fileName) {
  const url =
    typeof file === 'object' && typeof file !== 'string'
      ? window.URL.createObjectURL(file)
      : file

  const a = document.createElement('a')
  a.href = url
  a.download = fileName

  document.body.appendChild(a)
  a.click()

  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export function formatDistrict(district) {
  if (/^\d+$/.test(district) && district.charAt(0) === '0') {
    // Remove leading zero
    return district.slice(1)
  }
  // Return district unchanged if it's not numeric or doesn't have a leading zero
  return district
}
