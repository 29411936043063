import React from 'react'

import { Td, Tr } from '@chakra-ui/react'

import ContactInfoLabel from './ContactInfoLabel'
const ContactInfoRow = ({ category, children, isPref }) => (
  <Tr>
    <Td
      sx={{
        verticalAlign: 'top',
        w: '30%',
        px: 0,
      }}
    >
      <ContactInfoLabel category={category} isPref={isPref} />
    </Td>
    <Td
      sx={{
        px: 0,
        py: 3,
      }}
    >
      {children}
    </Td>
  </Tr>
)

export default ContactInfoRow
