import React from 'react'

import {
  Card,
  CardBody,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { formatDistrict } from '../../../../utils'
import { NoValue, NotFound } from '../../../ui'

const DistrictRow = ({ category, district }) => (
  <Tr>
    <Td>{category}</Td>
    <Td>{formatDistrict(district) || <NoValue />}</Td>
  </Tr>
)

const DistrictInfo = ({ address }) => (
  <Table w='100%'>
    <Tbody>
      <DistrictRow
        category='City Council District'
        district={address?.council_district}
      />
      <DistrictRow category='BBL' district={address?.boro_block_lot} />
      <DistrictRow
        category='Congressional District'
        district={address?.congressional_district}
      />
      <DistrictRow
        category='State Senate District'
        district={address?.state_senate_district}
      />
      <DistrictRow
        category='State Assembly District'
        district={address?.state_assembly_district}
      />
      <DistrictRow
        category='Community Board District'
        district={address?.community_board_district}
      />
      <DistrictRow
        category='Election District'
        district={address?.election_district}
      />
      <DistrictRow
        category='School District'
        district={address?.school_district}
      />
      <DistrictRow
        category='Police Precinct'
        district={address?.police_precinct}
      />
      <DistrictRow category='Longitude' district={address?.longitude} />
      <DistrictRow category='Latitude' district={address?.latitude} />
    </Tbody>
  </Table>
)

const DistrictBox = () => {
  const constituent = useSelector(state => state.constituents.instance)
  if (!constituent) return <NotFound />

  const { home_address, business_address } = constituent
  return (
    <Card>
      <CardBody>
        <Heading size='md' mb={4} color='blue.400'>
          District Information
        </Heading>
        {home_address || business_address ? (
          <Tabs>
            <TabList>
              {home_address && <Tab>Home Address</Tab>}
              {business_address && <Tab>Business Address</Tab>}
            </TabList>
            <TabPanels>
              {home_address && (
                <TabPanel p={0}>
                  <DistrictInfo address={home_address} />
                </TabPanel>
              )}
              {business_address && (
                <TabPanel p={0}>
                  <DistrictInfo address={business_address} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        ) : (
          <Text>No district information for this constituent.</Text>
        )}
      </CardBody>
    </Card>
  )
}

export default DistrictBox
