import React from 'react'

import { Box, useMediaQuery } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  clearCaseworkSearch,
  setCaseworkSearch,
  setTabIndex,
} from '../../redux/features/caseworkSlice'
import {
  CaseworkActionMenu,
  CaseworkFilterMenu,
  CaseworkList,
  CaseworkPagination,
} from '../casework'
import NewCaseworkButton from '../casework/casework/NewCaseworkButton'
import { TabsSearchFilter } from '../ui'

const Casework = () => {
  document.title = 'Casework | Council Connect'
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tabIndex = useSelector(state => state.casework.tab_index)
  const filters = useSelector(state => state.casework.filters)

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const selectTab = index => {
    dispatch(setTabIndex(index))
  }

  const updateSearch = searchInput => {
    dispatch(setCaseworkSearch(searchInput))
  }

  const clearSearch = () => {
    dispatch(clearCaseworkSearch())
  }

  return (
    <Box
      h='100%'
      overflowY='hidden'
      sx={{
        '.tabs': {
          boxShadow: '0px 5px 5px rgba(0, 0, 0, .1)',
        },
      }}
    >
      <TabsSearchFilter
        tabs={['Casework', 'Archived']}
        tabIndex={tabIndex}
        selectTab={selectTab}
        updateSearch={updateSearch}
        clearSearch={clearSearch}
        filterMenu=<CaseworkFilterMenu />
        actionMenu=<CaseworkActionMenu />
        createComponent=<NewCaseworkButton
          onClick={
            tabIndex === 0 ? () => navigate('/casework/create') : undefined
          }
        />
        activeFilters={
          Object.keys(filters).filter(
            key => !['order_by', 'search'].includes(key)
          ).length
        }
      />
      <Box bg='blackAlpha.100' h='100%'>
        <Box
          height={isLargerThan768 && 'calc(100vh - 233px)'}
          overflowY='auto'
          overflowX='hidden'
          px={5}
          py={3}
        >
          <CaseworkList />
        </Box>

        <CaseworkPagination />
      </Box>
    </Box>
  )
}

export default Casework
