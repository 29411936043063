import React, { useCallback } from 'react'

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  HStack,
  Heading,
  Icon,
  Button,
  DrawerFooter,
} from '@chakra-ui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { validateForm } from './utils'
import { ConstituentFormInputs } from '../..'
import {
  constituentValidationSchema,
  formatConstituentValues,
  initializeConstituentValues,
} from '../../../../constants'
import {
  createConstituent,
  setErrors,
  updateConstituent,
} from '../../../../redux/features/constituentsSlice'
import { calculateMS } from '../../../../utils'

const CreateOrEditConstituentDrawer = ({
  initialValues,
  mode,
  isOpen,
  onClose,
  finalFocusRef,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  initialValues = initializeConstituentValues(initialValues || {})

  const handleSubmitCallback = useCallback(id => {
    if (mode === 'edit') {
      onClose()
      toast({
        title: 'Constituent saved',
        status: 'success',
        isClosable: true,
        duration: calculateMS(2.5),
      })
    } else {
      navigate(`/directory/constituents/${id}`)
      toast({
        title: 'Constituent added',
        status: 'success',
        isClosable: true,
        duration: calculateMS(2.5),
      })
    }
  }, [])

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      setSubmitting(true)

      const formattedValues = formatConstituentValues(values)

      const callbackSuccess = id => {
        handleSubmitCallback(id)
        setSubmitting(false)
      }

      const callbackFailure = errors => {
        setSubmitting(false)
        setErrors(errors)
      }

      if (mode === 'edit') {
        dispatch(
          updateConstituent({
            id: formattedValues.id,
            values: formattedValues,
            callbackSuccess,
            callbackFailure,
          })
        )
      } else {
        dispatch(
          createConstituent({
            values: formattedValues,
            callbackSuccess,
            callbackFailure,
          })
        )
      }
    },
    [mode]
  )

  if (!mode) return null
  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={() => {
          onClose()
          dispatch(setErrors(null))
        }}
        size='xl'
        finalFocusRef={finalFocusRef}
      >
        <DrawerOverlay />
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          validate={validateForm}
          onSubmit={handleSubmit}
          validationSchema={constituentValidationSchema}
        >
          {({ dirty, isSubmitting }) => (
            <Form>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader my={2}>
                  <HStack>
                    <Heading size='lg'>
                      {mode === 'edit'
                        ? 'Edit Constituent'
                        : 'Create New Constituent'}
                    </Heading>
                    <Icon as={UserCircleIcon} fontSize='4xl' />
                  </HStack>
                </DrawerHeader>

                <DrawerBody>
                  <ConstituentFormInputs />
                </DrawerBody>
                <DrawerFooter>
                  <Button
                    isLoading={isSubmitting}
                    variant='solid'
                    type='submit'
                    isDisabled={!dirty}
                    mt={5}
                    width='100%'
                  >
                    {mode === 'edit'
                      ? 'Save Constituent'
                      : 'Create Constituent'}
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  )
}

export default CreateOrEditConstituentDrawer
