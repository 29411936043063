import React, { useState } from 'react'

import {
  Card,
  CardBody,
  HStack,
  Heading,
  Icon,
  Stack,
  Tag,
  Text,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
} from '@chakra-ui/react'
import { UsersIcon } from '@heroicons/react/24/outline'
import { StarIcon } from 'lucide-react'
import { useSelector } from 'react-redux'

import { ConstituentCard, ContactInfoBox } from '..'
import { NotFound } from '../../../ui'
import { renderTextLines } from '../../../utils'
import DetailDrawer from '../../groups/GroupDetail/DetailDrawer'

const GroupLabel = ({ group_name, role = '', onClick }) => (
  <HStack onClick={onClick} _hover={{ cursor: 'pointer' }}>
    <Icon as={UsersIcon} boxSize={6} />
    <Text as='b'> {group_name} </Text>
    <Text as='em' color='gray.400'>
      {role?.toUpperCase()}
    </Text>
  </HStack>
)

const InfoCard = ({ heading, children }) => (
  <Card my={3}>
    <CardBody>
      <Heading size='md' mb={1} color='blue.400'>
        {heading}
      </Heading>
      {children}
    </CardBody>
  </Card>
)

const ConstituentDetailsBox = () => {
  const constituent = useSelector(state => state.constituents.instance)
  if (!constituent) return <NotFound />

  const {
    groups,
    associated_topics,
    notes,
    tags,
    contacts,
    preferred_name,
    pronouns,
    languages,
  } = constituent
  const {
    isOpen: groupDetailIsOpen,
    onOpen: onOpenGroupDetail,
    onClose: onCloseGroupDetail,
  } = useDisclosure()
  const [group, setGroup] = useState(null)

  return (
    <>
      {(preferred_name || pronouns) && (
        <Card mb={3}>
          <CardBody>
            {preferred_name && (
              <HStack>
                <Text fontWeight='bold'>Preferred Name: </Text>
                <Text>{preferred_name}</Text>
              </HStack>
            )}
            {pronouns && (
              <HStack>
                <Text fontWeight='bold'>Pronouns: </Text>
                <Text>{constituent.pronouns}</Text>
              </HStack>
            )}
          </CardBody>
        </Card>
      )}
      <InfoCard heading='Contact Information'>
        <ContactInfoBox {...constituent} />
      </InfoCard>
      {contacts?.length > 0 && (
        <InfoCard heading='Contacts'>
          <Stack>
            {contacts.map((contact, index) => (
              <ConstituentCard
                cardStyles={{
                  variant: 'filled',
                  bg: 'gray.100',
                }}
                constituent={contact}
                key={`contact-${index}`}
              />
            ))}
          </Stack>
        </InfoCard>
      )}
      {languages?.length > 0 && (
        <InfoCard heading='Languages'>
          <Wrap gap={2} mt={1}>
            {languages.map((language, index) => (
              <WrapItem key={index} userSelect='none'>
                {index === 0 ? (
                  <Tooltip label='Preferred language'>
                    <Tag colorScheme='yellow' gap={1}>
                      <Icon as={StarIcon} />
                      {language}
                    </Tag>
                  </Tooltip>
                ) : (
                  <Tag>{language}</Tag>
                )}
              </WrapItem>
            ))}
          </Wrap>
        </InfoCard>
      )}
      {notes && (
        <InfoCard heading='Notes' overflowY='auto'>
          {renderTextLines(notes)}
        </InfoCard>
      )}
      {groups?.length > 0 && (
        <InfoCard heading='Groups'>
          <Stack>
            {groups.map((g, index) => (
              <GroupLabel
                key={index}
                group_name={g.name}
                role={g.role}
                onClick={() => {
                  setGroup(g)
                  onOpenGroupDetail()
                }}
              />
            ))}
          </Stack>

          {group && (
            <DetailDrawer
              id={group.id}
              isOpen={groupDetailIsOpen}
              onClose={onCloseGroupDetail}
            />
          )}
        </InfoCard>
      )}
      {tags.length > 0 && (
        <InfoCard heading='Tags'>
          <Wrap gap={2} mt={1}>
            {tags.map((tag, index) => (
              <WrapItem key={index}>
                <Tag>{tag.label}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        </InfoCard>
      )}
      {associated_topics && (
        <InfoCard heading='Associated Topics'>
          {associated_topics.length ? (
            <Wrap>
              {associated_topics.map((topic, index) => (
                <WrapItem key={index}>
                  <Tooltip label={topic.issue} hasArrow>
                    <Tag p={2}>{topic.name}</Tag>
                  </Tooltip>
                </WrapItem>
              ))}
            </Wrap>
          ) : (
            <Text as='i' color='gray.400'>
              This constituent does not have any relevant casework.
            </Text>
          )}
        </InfoCard>
      )}
    </>
  )
}

export default ConstituentDetailsBox
