import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys)
const baseStyle = definePartsStyle({
  container: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    _dark: {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
})

export const cardTheme = defineMultiStyleConfig({ baseStyle })
