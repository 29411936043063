import React from 'react'

import {
  Box,
  Card,
  CardBody,
  Flex,
  Spacer,
  Text,
  Select,
} from '@chakra-ui/react'

import ReportSectionHeading from './ReportSectionHeading'
import SortableTable from './SortableTable'
import { toTitleCase } from '../../../utils'
import { extractCaseNum } from '../../utils'

const CaseworkBreakdown = ({ cases, caseType, setCaseType }) => {
  if (!cases) return null

  const valueKeys = [
    {
      label: 'Case Number',
      value: 'case_num',
      onClick: case_num => window.open(`/casework/${extractCaseNum(case_num)}`),
    },
    {
      label: 'Current Status',
      value: 'status',
    },
    {
      label: 'Opened Date',
      value: 'opened_at',
    },
    {
      label: 'Closed Date',
      value: 'closed_at',
    },
    {
      label: 'Last Updated',
      value: 'last_updated',
    },
  ]

  return (
    <Card maxH={625}>
      <CardBody>
        <Flex align='center' pb={1}>
          <ReportSectionHeading w='60%'>
            {caseType === 'cases'
              ? 'Case Breakdown'
              : toTitleCase(caseType.replace('_', ' '))}
          </ReportSectionHeading>
          <Spacer />
          <Select
            id='report-case-filter'
            size='md'
            value={caseType}
            onChange={e => setCaseType(e.target.value)}
          >
            <option value='cases'>Show all cases during period </option>
            <option value='cases_opened'>Show cases opened </option>
            <option value='cases_closed'>Show cases closed </option>
            <option value='cases_updated'>Show cases updated </option>
          </Select>
        </Flex>
        <Box pt={2}>
          {cases.length === 0 ? (
            <Text>No cases to show</Text>
          ) : (
            <SortableTable
              values={cases}
              valueKeys={valueKeys}
              defaultSort='status'
            />
          )}
        </Box>
      </CardBody>
    </Card>
  )
}

export default CaseworkBreakdown
