import React from 'react'

import { MenuItem, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  deselectConstituent,
  startExportConstituents,
} from '../../../../redux/features/constituentsSlice'
import { ExportLoading } from '../../../ui'
import { MergeConstituentModal } from '../merge'

const ConstituentActionMenu = () => {
  const dispatch = useDispatch()
  const isExporting = useSelector(state => state.constituents.exporting)
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []
  const user = useSelector(state => state.auth.user)

  const {
    isOpen: mergeIsOpen,
    onOpen: mergeOnOpen,
    onClose: mergeOnClose,
  } = useDisclosure()

  const minimumSelected = selectedConstituents.length >= 2

  return (
    <>
      {user.is_admin && (
        <MenuItem
          onClick={() =>
            dispatch(startExportConstituents({ useFilters: true }))
          }
        >
          Export current view
        </MenuItem>
      )}
      <Tooltip
        hasArrow
        label={
          !minimumSelected
            ? 'Must select at least two constituents to merge'
            : ''
        }
      >
        <MenuItem isDisabled={!minimumSelected} onClick={mergeOnOpen}>
          Merge selected constituents
          {minimumSelected && `(${selectedConstituents.length})`}
        </MenuItem>
      </Tooltip>
      {selectedConstituents.length > 0 && (
        <MenuItem
          onClick={() => {
            selectedConstituents.forEach(c => dispatch(deselectConstituent(c)))
          }}
        >
          Deselect all constituents
        </MenuItem>
      )}
      <ExportLoading isLoading={isExporting} />
      {mergeIsOpen && (
        <MergeConstituentModal isOpen={mergeIsOpen} onClose={mergeOnClose} />
      )}
    </>
  )
}

export default ConstituentActionMenu
