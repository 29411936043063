import React, { useRef } from 'react'

import {
  Card,
  CardBody,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Input,
  Progress,
  Spacer,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react'
import {
  CircleCheckIcon,
  FileLock2Icon,
  FileTextIcon,
  PencilIcon,
  PencilOffIcon,
  Trash2Icon,
} from 'lucide-react'

const File = ({
  index,
  file,
  containsPII,
  onClick,
  fileName = 'File name unknown',
  onButtonClick,
  onFlagChange,
  onNameChange,
  isEditing,
  textColor,
  textWeight,
  bgColor,
  strikethrough,
  width,
  uploading,
  uploadComplete,
  removing,
  removeComplete,
}) => {
  const [nameEditing, setNameEditing] = useBoolean(false)
  const nameRef = useRef(null)

  if (!file) return <></>

  const fileSize = file.file?.size || file?.file_size

  const convertFileSizeToString = bytes => {
    if (bytes < 1024) {
      // Convert to bytes
      return Math.floor(bytes) + ' B'
    } else if (bytes < 1024 * 1024) {
      // Convert to kilobytes
      return Math.floor(bytes / 1024) + ' KB'
    } else {
      // Convert to megabytes
      return Math.floor(bytes / (1024 * 1024)) + ' MB'
    }
  }

  const onNameClick = () => {
    if (!isEditing) return
    if (file.status === 'remove') return
    setNameEditing.on()
  }

  const onNameBlur = e => {
    setNameEditing.off()
    onNameChange(e.target.value, index)
  }

  function ContainsPIIIndicator() {
    if (containsPII)
      return (
        <Tooltip label='Contains PII'>
          <Icon as={FileLock2Icon} />
        </Tooltip>
      )
  }

  function RemoveIndicator() {
    if (!isEditing) return

    if (removing && !removeComplete) return <Spinner />
    if (removeComplete) return <Icon as={CircleCheckIcon} boxSize={5} />

    return (
      <Icon
        as={Trash2Icon}
        boxSize={5}
        _hover={{ cursor: 'pointer ' }}
        onClick={onButtonClick}
      />
    )
  }

  function EditNameIconButton() {
    if (!isEditing) return
    if (nameEditing)
      return (
        <Icon
          as={PencilOffIcon}
          onClick={setNameEditing.off}
          cursor='pointer'
        />
      )

    return <Icon as={PencilIcon} onClick={setNameEditing.on} cursor='pointer' />
  }

  function ContainsPIICheckbox() {
    if (!isEditing) return
    return (
      <Card size='sm'>
        <CardBody>
          <HStack>
            <Checkbox
              name={`attachment-pii-flag-${file?.file_name}`}
              onChange={e => onFlagChange(e, index)}
              isChecked={containsPII}
              isDisabled={file?.status === 'remove'}
            />
            <Text variant='subheading-sm' whiteSpace='nowrap'>
              Contains PII
            </Text>
          </HStack>
        </CardBody>
      </Card>
    )
  }
  return (
    <Flex
      direction='row'
      w={width}
      color={textColor}
      fontWeight={textWeight}
      bg={bgColor}
      borderRadius='lg'
      p={2}
      _hover={onClick && { cursor: 'pointer' }}
      onClick={onClick}
      flexDirection='column'
    >
      <Stack direction='row' align='center' w='100%'>
        <Icon as={FileTextIcon} boxSize={5} />
        {nameEditing ? (
          <Input
            id={`file-name-${index}`}
            borderColor='transparent'
            _hover={{ borderColor: 'transparent' }}
            ref={nameRef}
            value={fileName}
            onChange={e => onNameChange(e.target.value, index)}
            onBlur={onNameBlur}
            p={0}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault()
                setNameEditing.off()
              }
            }}
          />
        ) : (
          <Tooltip
            label={fileName !== file?.file_name ? file?.file_name : ''}
            hasArrow
            openDelay={250}
          >
            <Text
              pl={1}
              onClick={onNameClick}
              overflow='hidden'
              textOverflow='ellipsis'
              display='inline-block'
              whiteSpace='nowrap'
              textDecoration={strikethrough ? 'line-through' : ''}
            >
              {fileName}
            </Text>
          </Tooltip>
        )}
        <Spacer />
        <HStack>
          <Text color={textColor} fontWeight='normal' whiteSpace='nowrap'>
            {convertFileSizeToString(fileSize)}
          </Text>
          <ContainsPIIIndicator />
          <RemoveIndicator />
          <EditNameIconButton />
          <ContainsPIICheckbox />
        </HStack>
      </Stack>
      {(uploading || uploadComplete) && (
        <Progress
          borderRadius='xl'
          size='xs'
          colorScheme='teal'
          isIndeterminate={uploading && !uploadComplete}
          value={uploadComplete ? 100 : undefined}
        />
      )}
    </Flex>
  )
}

export default File
