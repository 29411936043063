import React, { cloneElement } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  ButtonGroup,
  Spacer,
} from '@chakra-ui/react'
import { Formik } from 'formik'

import CreateTopicFormInputs from './CreateTopicFormInputs'
import {
  initialTopicValues,
  topicValidationSchema,
} from '../../../../constants'
import { capitalize, toTitleCase } from '../../../../utils'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const NewTopicModalButton = ({
  button,
  onCreate = () => {},
  initialValues = initialTopicValues,
}) => {
  const { handleCreateTopic } = useTopicsContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: onOpen })
      ) : (
        <Button onClick={onOpen}>Add New Topic</Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <Formik
          initialValues={initialValues}
          validationSchema={topicValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const formattedValues = {
              ...values,
              name: toTitleCase(values.name),
              issue: capitalize(values.issue),
            }
            handleCreateTopic(formattedValues, () => {
              onClose()
              onCreate()
            })
            setSubmitting(false)
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <ModalContent>
              <ModalHeader>Create Topic</ModalHeader>
              <ModalBody>
                <CreateTopicFormInputs />
              </ModalBody>
              <ModalFooter>
                <ButtonGroup w='100%'>
                  <Button onClick={onClose} variant='ghost' colorScheme='red'>
                    Cancel
                  </Button>
                  <Spacer />
                  <Button
                    isLoading={isSubmitting}
                    type='submit'
                    onClick={handleSubmit}
                  >
                    Create Topic
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default NewTopicModalButton
