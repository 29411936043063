import React, { useMemo } from 'react'

import { Center, Stack } from '@chakra-ui/react'

import Issue from './Issue'
import { Loading, NoResults } from '../../../ui'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const TopicList = () => {
  const { topicsByIssue, isLoading } = useTopicsContext()

  const issues = useMemo(
    () => Object.entries(topicsByIssue).sort(([a], [b]) => a.localeCompare(b)),
    [topicsByIssue]
  )

  if (isLoading) {
    return (
      <Center w='100%'>
        <Loading />
      </Center>
    )
  }

  if (!topicsByIssue || topicsByIssue?.length === 0)
    return (
      <Center w='100%'>
        <NoResults />
      </Center>
    )

  return (
    <Stack>
      {issues.map(([issue, topics], index) => (
        <Issue key={`issue-${index}`} issue={issue} topics={topics} />
      ))}
    </Stack>
  )
}

export default TopicList
