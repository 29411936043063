import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

import CompendiumContent from './CompendiumContent'
import { CompendiumContextProvider } from './ContextProvider/CompendiumContext'
import Sidebar from './Sidebar'

const Compendium = () => {
  return (
    <CompendiumContextProvider>
      <Flex h='100%' maxH='90vh' overflowY='hidden'>
        <Box p={8}>
          <Sidebar />
        </Box>
        <Box flex='1' overflow='hidden' p={8} shadow='lg'>
          <CompendiumContent />
        </Box>
      </Flex>
    </CompendiumContextProvider>
  )
}

export default Compendium
