import React from 'react'

import { Box, HStack, Text, useColorModeValue } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { CircleHelpIcon } from 'lucide-react'

import { useCompendiumContext } from './ContextProvider/CompendiumContext'

const SectionButton = ({ children, icon, hideText }) => {
  const { section, setSection } = useCompendiumContext()

  const borderColor = useColorModeValue(
    'rgba(0, 0, 0, 0.2)',
    'rgba(255, 255, 255, 0.2)'
  )

  const selectedStyle = {
    boxShadow: 'base',
    borderColor: borderColor,
    _dark: { bg: 'gray.100', shadow: 'inner' },
  }

  return (
    <Box
      onClick={() => setSection(children)}
      cursor='pointer'
      p={3}
      borderRadius='xl'
      border='1px solid transparent'
      sx={section === children && selectedStyle}
    >
      <HStack gap={3} _dark={{ color: 'blue.400' }}>
        <Box>{icon || <CircleHelpIcon />}</Box>
        <AnimatePresence>
          {!hideText && (
            <motion.div
              key={`section-button-${children}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ enter: { delay: 0.5 } }}
            >
              <Text>{children}</Text>
            </motion.div>
          )}
        </AnimatePresence>
      </HStack>
    </Box>
  )
}

export default SectionButton
