import React from 'react'

import {
  Card,
  CardBody,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'

const CaseworkCount = ({
  cases_opened_count,
  cases_closed_count,
  cases_updated_count,
}) => (
  <Card>
    <CardBody>
      <StatGroup>
        <Stat>
          <StatLabel>
            <span border='1px solid'> Cases Opened</span>
          </StatLabel>
          <StatNumber>{cases_opened_count}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Cases Closed</StatLabel>
          <StatNumber>{cases_closed_count}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Updated Cases</StatLabel>
          <StatNumber>{cases_updated_count}</StatNumber>
        </Stat>
      </StatGroup>
    </CardBody>
  </Card>
)

export default CaseworkCount
