import React from 'react'

import {
  Card,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import EmployeesTable from './EmployeesTable'
import { Loading, NotFound } from '../../ui'

const AgencyModal = ({ isOpen, onClose }) => {
  const agencyData = useSelector(state => state.agencies.data)

  const loading = useSelector(state => state.agencies.loading)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader casing='uppercase'>
          {agencyData[0]?.agency_name || 'Agency'} information
        </ModalHeader>
        <ModalCloseButton />
        {loading ? (
          <Loading />
        ) : (
          <ModalBody>
            {!agencyData ? (
              <NotFound />
            ) : (
              <Card maxH='80vh' overflowY='auto'>
                <EmployeesTable employees={agencyData} />
              </Card>
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default AgencyModal
