import React, { useMemo } from 'react'

import {
  Box,
  Text,
  Stack,
  Textarea,
  Select,
  Icon,
  HStack,
} from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { InfoIcon } from 'lucide-react'

import {
  AddressField,
  ContactInfoField,
  DistrictLockField,
  GroupsField,
  LanguagesField,
  TagsField,
} from './fields'
import BusinessAddressField from './fields/BusinessAddressField'
import { FormField, ShowHideFormField } from '../../../ui'

const Section = ({ heading, isRequired, children }) => {
  return (
    <Box>
      {typeof heading === 'string' ? (
        <Text variant='subheading-blue' mb={1}>
          {heading} {isRequired && <span color='red'>*</span>}
        </Text>
      ) : (
        heading
      )}
      <Box>{children}</Box>
    </Box>
  )
}

const ConstituentFormInputs = ({ field = '' }) => {
  const { values: formikValues } = useFormikContext()

  // formikField is not used if field is empty, so name is just a placeholder
  const [formikField] = useField(field || 'name')
  const values = useMemo(
    () => (field ? formikField.value : formikValues) || {},
    [field, formikValues, formikField]
  )

  field = field ? field + '.' : field

  return (
    <Box p={1} overflowY='auto'>
      <Stack gap={5}>
        <Section heading='Category'>
          <FormField
            field={field + 'category'}
            placeholder={null}
            inputType={Select}
            options={['Individual', 'Business']}
          />
        </Section>
        <Section heading='Privacy'>
          <DistrictLockField field={field + 'visible_to_district_only'} />
        </Section>

        {/* Constituent Name */}
        {values.category === 'Individual' ? (
          <Section heading='Personal Information'>
            <Stack>
              <Box>
                <FormField field={field + 'name'} placeholder='Full Name' />

                <HStack
                  color='blackAlpha.700'
                  _dark={{ color: 'whiteAlpha.700' }}
                  pl={1}
                >
                  <Icon as={InfoIcon} />
                  <Text>Leave name blank if anonymous</Text>
                </HStack>
              </Box>

              <HStack direction={{ base: 'column', sm: 'row' }}>
                <FormField
                  field={field + 'title'}
                  placeholder='Title'
                  shouldOptimize
                />
                <FormField
                  field={field + 'suffix'}
                  placeholder='Suffix'
                  shouldOptimize
                />
              </HStack>
              <HStack>
                <FormField
                  field={field + 'preferred_name'}
                  placeholder='Preferred Name'
                  shouldOptimize
                />
                <FormField
                  field={field + 'pronouns'}
                  placeholder='Pronouns'
                  shouldOptimize
                />
              </HStack>
            </Stack>
          </Section>
        ) : (
          <Section heading='Business Information'>
            <FormField field={field + 'name'} placeholder='Business Name' />
          </Section>
        )}

        {/* Constituent Contact Information */}
        <Section heading='Contact Information'>
          <ContactInfoField field={field + 'contact_info'} />
        </Section>

        {/* Constituent Address Information */}
        {/* HOME ADDRESS */}
        {values.category === 'Individual' && (
          <Section heading='Home Address Information'>
            <AddressField field={field} type='home' />
          </Section>
        )}
        {/* BUSINESS ADDRESS */}
        {values.category === 'Individual' ? (
          <BusinessAddressField field={field} />
        ) : (
          <Section heading='Business Address Information'>
            <AddressField field={field} type='business' />
          </Section>
        )}

        {/* LANGUAGES */}
        <LanguagesField field={field + 'languages'} />

        {/* Tags */}
        <TagsField field={field + 'tags'} />

        {/* GROUPS */}
        <Box>
          <GroupsField field={field + 'groups'} />
        </Box>

        {/* NOTES */}
        <ShowHideFormField heading='Notes' defaultShow={!!values.notes}>
          <FormField
            field={field + 'notes'}
            placeholder='Record any additional notes about constituent, if needed'
            inputType={Textarea}
            shouldOptimize
          />
        </ShowHideFormField>
      </Stack>
    </Box>
  )
}

export default ConstituentFormInputs
