import React from 'react'

import { Box, HStack, Icon, Text } from '@chakra-ui/react'

const Vote = ({ icon, count, isActive, ...props }) => {
  return (
    <HStack>
      <Icon
        {...props}
        as={icon}
        fontSize='lg'
        cursor='pointer'
        _hover={{ color: 'blue.400' }}
        color={isActive ? 'blue.400' : undefined}
      />
      <Box w='40px'>
        <Text userSelect='none'>{count}</Text>
      </Box>
    </HStack>
  )
}

export default Vote
