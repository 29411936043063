import React from 'react'

import { Center, Flex, Image, Text } from '@chakra-ui/react'

const LandingContent = () => {
  return (
    <Center as={Flex} direction='column-reverse' h='100%'>
      <Image src='/assets/tools/landing.png' h='400px' alt='Bookstack' />
      <Text variant='subheading-blue'>Select an option from the sidebar</Text>
    </Center>
  )
}

export default LandingContent
