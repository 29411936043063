import React from 'react'

import { Flex } from '@chakra-ui/react'

import { SearchBar } from '../../../ui'
import { useTagsContext } from '../ContextProvider/TagsContext'
const TagSearchBar = () => {
  const { searchValue, setSearchValue } = useTagsContext()
  return (
    <Flex w='100%'>
      <SearchBar
        id='tag-search'
        placeholder='Search for a tag'
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </Flex>
  )
}

export default TagSearchBar
