import React, { useCallback, useEffect } from 'react'

import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Icon,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline'
import { useFormikContext } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { ConstituentField } from './fields'
import { statusColors } from '../../../../constants'
import { loadConstituentInstance } from '../../../../redux/features/constituentsSlice'
import { formatDistrict } from '../../../../utils'
import { ConstituentCard, ConstituentFormInputs } from '../../../directory'
import { Loading } from '../../../ui'
import { formatCaseNum, renderTextLines } from '../../../utils'

/** On this page, select a constituent or create a new one. */
const ConstituentSelectPage = ({ mode = 'select', setMode, isEditing }) => {
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext()

  const user = useSelector(state => state.auth.user)
  const constituent = useSelector(state => state.constituents.instance)
  const constituentLoading = useSelector(state => state.constituents.loading)

  useEffect(() => {
    if (values.constituent) {
      dispatch(loadConstituentInstance({ id: values.constituent.id }))
    }
  }, [values.constituent])

  const revertConstituent = useCallback(
    () => setFieldValue('constituent', null),
    [setFieldValue]
  )

  return (
    <>
      {!isEditing && (
        <Center mb={5}>
          <ButtonGroup>
            <Button
              variant={mode === 'select' ? 'solid' : 'outline'}
              onClick={() => setMode('select')}
            >
              Select from Directory
            </Button>
            <Button
              variant={mode === 'create' ? 'solid' : 'outline'}
              onClick={() => setMode('create')}
            >
              Create New Constituent
            </Button>
          </ButtonGroup>
        </Center>
      )}

      {/* EXISTING CONSTITUENT INFORMATION HERE */}
      {mode === 'select' && (
        <Stack gap={2}>
          {!values.constituent && <ConstituentField />}

          {constituentLoading ? (
            <Loading />
          ) : constituent && values.constituent ? (
            <Stack>
              {!isEditing && (
                <Box align='end'>
                  <Button
                    variant='link'
                    leftIcon={<Icon as={ArrowUturnLeftIcon} />}
                    onClick={revertConstituent}
                  >
                    Select another constituent
                  </Button>
                </Box>
              )}
              {/* Constituent Info Card */}

              <ConstituentCard
                constituent={values.constituent}
                variant='selected'
                showDescriptions={true}
                showDistrict={false}
              />

              {/* Constituent Casework Card */}
              <Card variant='filled'>
                {constituent.casework.length > 0 ? (
                  <>
                    <CardHeader>
                      <Flex direction='column'>
                        <Text as='b'>Existing Constituent Casework</Text>
                        <Text as='i' color='gray.500'>
                          Contact appropriate offices to inquire about casework
                          from another district.
                        </Text>
                      </Flex>
                    </CardHeader>
                    <CardBody overflowY='auto'>
                      <Stack divider={<StackDivider />}>
                        {constituent.casework?.map((c, index) => (
                          <Box key={index}>
                            <Flex align='center'>
                              <Text as='b'>#{formatCaseNum(c)}</Text>
                              <Spacer />
                              {(c.district !== user.district.toString() ||
                                user.is_admin) && (
                                <Badge p={1} mr={0.5}>
                                  District {formatDistrict(c.district)}
                                </Badge>
                              )}
                              {c.status === 'In Progress' && (
                                <Badge
                                  bgColor={statusColors[c.status.toUpperCase()]}
                                  variant='solid'
                                  p={1}
                                  mr={1}
                                >
                                  {c.status}
                                </Badge>
                              )}
                              {(c.district === user.district ||
                                user.is_admin) && (
                                <ExternalLinkIcon
                                  cursor='pointer'
                                  onClick={() =>
                                    window.open(
                                      `/casework/${c.case_num}`,
                                      '_blank'
                                    )
                                  }
                                />
                              )}
                            </Flex>
                            {(c.district === user.district ||
                              user.is_admin) && (
                              <Box>
                                <Text>
                                  <b>Issue(s):</b>&nbsp;
                                  {c.topics.length > 0
                                    ? c.topics
                                        .map(topic => topic.name)
                                        .join(', ')
                                    : 'Unassigned'}
                                </Text>
                                <Box
                                  maxH={150}
                                  overflowY='auto'
                                  cursor='text'
                                  userSelect='text'
                                >
                                  {renderTextLines(c.details)}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Stack>
                    </CardBody>
                  </>
                ) : (
                  <CardBody>
                    <Text as='b'>
                      This constituent does not have any casework in this
                      district
                    </Text>
                  </CardBody>
                )}
              </Card>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      )}

      {/* CREATE CONSTITUENT FORM HERE */}
      {mode === 'create' && <ConstituentFormInputs field='newConstituent' />}
    </>
  )
}

export default ConstituentSelectPage
