import { extendTheme } from '@chakra-ui/react'

import {
  buttonTheme,
  checkboxTheme,
  formTheme,
  inputTheme,
  popoverTheme,
  selectTheme,
  tagTheme,
  textareaTheme,
  textTheme,
} from './assets/themes'
import { cardTheme } from './assets/themes/Card'
import { tableTheme } from './assets/themes/Table'

const customTheme = extendTheme({
  fonts: {
    heading: `'Gotham', sans-serif`,
    body: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
  },

  colors: {
    lightBlue: '#62b9fc33',
    offWhite: '#fafafa',
    nyccBlue: {
      100: '#e8effc',
      200: '#D0E0FF',
      300: '#C2D3FF',
      700: '#415696',
      800: '#23417D',
      900: '#00023B',
    },
    defaultBg: 'blackAlpha.100',
  },
  styles: {
    global: {
      'html, body': {
        fontSize: 'md',
      },
    },
  },
  components: {
    Input: inputTheme,
    Textarea: textareaTheme,
    Select: selectTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Popover: popoverTheme,
    Tag: tagTheme,
    Table: tableTheme,
    Text: textTheme,
    Form: formTheme,
    Card: cardTheme,
  },
})

export default customTheme
