import React from 'react'

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import TagsManager from './TagsManager'
import { TagsContextProvider } from '../ContextProvider/TagsContext'

const TagsContent = () => {
  return (
    <Box h='100%'>
      <Tabs isFitted isLazy variant='enclosed'>
        <TabList>
          <Tab>Casework Tags</Tab>
          <Tab>Constituent Tags</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TagsContextProvider mode='casework'>
              <TagsManager />
            </TagsContextProvider>
          </TabPanel>
          <TabPanel>
            <TagsContextProvider mode='constituents'>
              <TagsManager />
            </TagsContextProvider>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default TagsContent
