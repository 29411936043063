import React from 'react'

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import CreateTagFormInputs from './CreateTagFormInputs'
import { useTagsContext } from '../ContextProvider/TagsContext'

const CreateTagModal = ({ isOpen, onClose }) => {
  const user = useSelector(state => state.auth.user)
  const { createTag } = useTagsContext()

  const initialTagValues = {
    label: '',
    district: '',
  }

  const tagValidationSchema = Yup.object().shape({
    label: Yup.string().required('Tag label is required'),
    district: Yup.string().required('District is required'),
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
      <ModalOverlay />
      <Formik
        initialValues={{
          ...initialTagValues,
          district: user.is_admin ? '' : user.district,
        }}
        validationSchema={tagValidationSchema}
        onSubmit={(values, { setFieldValue, setTouched, setSubmitting }) => {
          createTag(values, () => {
            setFieldValue('label', '')
            setTouched({})
          })
          setSubmitting(false)
        }}
      >
        {({ isSubmitting, handleSubmit }) => (
          <ModalContent>
            <ModalHeader>New Tag</ModalHeader>
            <ModalBody>
              <CreateTagFormInputs />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup w='100%'>
                <Button onClick={onClose} variant='ghost' colorScheme='gray'>
                  Close
                </Button>
                <Spacer />
                <Button isLoading={isSubmitting} onClick={handleSubmit}>
                  Create Tag
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateTagModal
