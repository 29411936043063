import React, { useRef } from 'react'

import { EmailIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
} from '@chakra-ui/react'

import { formatDistrict } from '../../../utils'
import { NoValue } from '../../ui'

const StaffCard = ({ openStaff, staff, index }) => {
  const [hovered, setHovered] = useBoolean(false)
  const newTabButtonRef = useRef(null)

  const altBg = useColorModeValue('lightBlue', 'blue.800')
  const altColor = useColorModeValue('gray.800', 'white')
  return (
    <Box
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      my='2'
      p='5'
      position='relative'
      bg={index % 2 === 0 && altBg}
      color={index % 2 === 0 && altColor}
      _hover={{ bg: 'blue.200' }}
      cursor='pointer'
      onMouseOver={setHovered.on}
      onMouseLeave={setHovered.off}
      onClick={e => {
        if (newTabButtonRef.current?.contains(e.target)) return
        openStaff(e, staff)
      }}
    >
      {hovered && (
        <IconButton
          ref={newTabButtonRef}
          icon={<ExternalLinkIcon />}
          variant='unstyled'
          borderRadius='full'
          position='absolute'
          top='2px'
          right='2px'
          color={hovered ? 'gray.800' : undefined}
          onClick={() =>
            window.open(`/directory/staff/${staff.user?.id}`, '_blank')
          }
        />
      )}
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
        gap={{ base: 1, sm: 4 }}
      >
        <GridItem
          gridColumn='1/-1'
          mb={{ base: 2, sm: 0 }}
          color={hovered ? 'gray.800' : undefined}
        >
          <Heading fontSize='xl' cursor='text'>
            {staff.user.full_name}
          </Heading>
          <Text variant='subheading-gray'>{staff.role}</Text>
        </GridItem>
        <GridItem my='auto' color={hovered ? 'gray.800' : undefined}>
          <VStack align='left'>
            <HStack>
              <EmailIcon />
              <Text cursor='text'> {staff.user?.email} </Text>
            </HStack>

            <HStack align='top'>
              <Text cursor='text'>
                District&nbsp;
                {formatDistrict(staff.district) || <NoValue />}
              </Text>
            </HStack>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

export default StaffCard
