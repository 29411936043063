import React from 'react'

import { Table, Tbody, Text } from '@chakra-ui/react'

import ContactInfoRow from './ContactInfoRow'
import { AddressText } from '../../../../ui'
const ContactInfoBox = ({
  address,
  home_address,
  business_address,
  primary_address,
  contact_info,
}) =>
  contact_info?.length || address || home_address || business_address ? (
    <Table variant='unstyled'>
      <Tbody>
        {home_address && (
          <ContactInfoRow
            category={`Home Address ${
              primary_address === 'home' && business_address ? ' (Primary)' : ''
            }`}
          >
            <AddressText address={home_address} />
          </ContactInfoRow>
        )}
        {business_address && (
          <ContactInfoRow
            category={`Business Address ${
              primary_address === 'business' && home_address ? ' (Primary)' : ''
            }`}
          >
            <AddressText address={business_address} />
          </ContactInfoRow>
        )}

        {contact_info?.map((contact, index) => (
          <ContactInfoRow
            key={`contact-info-${index}`}
            category={contact.contact_type}
            isPref={contact.is_preferred_method}
          >
            <Text>{contact.contact_data}</Text>
            <Text>{contact.description}</Text>
          </ContactInfoRow>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Text> No contact information provided for this constituent.</Text>
  )

export default ContactInfoBox
