import React from 'react'

import { ThumbsUpIcon } from 'lucide-react'

import Vote from './Vote'

const Upvote = props => {
  return <Vote icon={ThumbsUpIcon} {...props} />
}

export default Upvote
