import React from 'react'

import { Heading } from '@chakra-ui/react'

const ReportSectionHeading = ({ children, ...props }) => (
  <Heading whiteSpace='nowrap' color='blue.400' fontSize='lg' {...props}>
    {children}
  </Heading>
)

export default ReportSectionHeading
