import React, { useMemo } from 'react'

import {
  Icon,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { StarIcon } from 'lucide-react'

import BaseArrayField from './BaseArrayField'
import { LanguageSelect } from '../../../../ui/select'

const LanguagesField = ({ field = 'languages' }) => {
  const { values, setFieldValue } = useFormikContext()

  const selectedLanguages = useMemo(
    () =>
      values.languages?.map(language => ({ label: language, value: language })),
    [values.languages]
  )

  const setPreferredLanguage = index => {
    if (!values.languages) return
    if (index <= 0 || index >= values.languages.length) return

    let languages = values.languages
    const [element] = languages.splice(index, 1)
    languages.unshift(element)
    setFieldValue('languages', languages)
  }

  const removeLanguage = index => {
    if (!values.languages) return
    if (index < 0 || index >= values.languages.length) return

    setFieldValue(
      'languages',
      values.languages.filter((_, i) => i !== index)
    )
  }

  return (
    <BaseArrayField label='Languages' field={field}>
      {({ onSelect, onRemove, onClear }) => (
        <>
          <LanguageSelect
            onSelect={onSelect}
            onRemove={onRemove}
            onClear={onClear}
            initialSelected={selectedLanguages}
            dropdownHeight={150}
            inputProps={{
              id: 'language-select',
            }}
            showTags={false}
          />
          <Wrap mt={1}>
            {values.languages?.map((language, index) => (
              <WrapItem key={language}>
                {index === 0 ? (
                  <Tooltip label='Preferred language'>
                    <Tag colorScheme='yellow'>
                      <TagLabel userSelect='none'>{language}</TagLabel>
                      <TagCloseButton onClick={() => removeLanguage(index)} />
                    </Tag>
                  </Tooltip>
                ) : (
                  <Tag>
                    <TagLabel userSelect='none'>{language}</TagLabel>
                    <Tooltip label='Set as preferred language'>
                      <Icon
                        as={StarIcon}
                        cursor='pointer'
                        pl={1}
                        onClick={() => setPreferredLanguage(index)}
                      />
                    </Tooltip>
                    <TagCloseButton onClick={() => removeLanguage(index)} />
                  </Tag>
                )}
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </BaseArrayField>
  )
}

export default LanguagesField
