import React from 'react'

import { capitalize } from '../../../../utils'
import { ConfirmActionDialog } from '../../../ui'

const ConfirmDeleteTopicSuggestion = ({ topic, ...props }) => {
  return (
    <ConfirmActionDialog scheme='delete' {...props}>
      Deleting topic suggestion <b>{capitalize(topic)}</b>. Are you sure you
      want to continue?
    </ConfirmActionDialog>
  )
}

export default ConfirmDeleteTopicSuggestion
