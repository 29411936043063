import React, { useState } from 'react'

import {
  Card,
  CardBody,
  Flex,
  Spacer,
  Text,
  Center,
  useColorMode,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react'
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'

import RecolorButton from './RecolorButton'
import { generateShades } from '../../utils'

const IntakeMethods = ({ intakeMethods }) => {
  if (!intakeMethods) return <></>
  const { colorMode } = useColorMode()
  const [colors, setColors] = useState(
    generateShades(intakeMethods.length, colorMode)
  )

  const legendText = (value, color) => <Text color={color}>{value}</Text>
  const legendTextColor = useColorModeValue('black', '#fff')

  return (
    <Card h='100%' w='100%'>
      <CardBody align='center'>
        <Flex align='center'>
          <Heading fontSize='lg' color='blue.400'>
            Intake Methods
          </Heading>

          <Spacer />
          <RecolorButton
            setColors={setColors}
            numShades={intakeMethods.length}
          />
        </Flex>
        {intakeMethods.length === 0 ? (
          <Text>No casework created during this report period.</Text>
        ) : (
          <Center py={3}>
            <PieChart width={400} height={400}>
              <Pie data={intakeMethods} dataKey='count' nameKey='name'>
                {intakeMethods.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>

              <Legend formatter={value => legendText(value, legendTextColor)} />

              <Tooltip />
            </PieChart>
          </Center>
        )}
      </CardBody>
    </Card>
  )
}

export default IntakeMethods
