import React from 'react'

import { Button } from '@chakra-ui/react'

const NewCaseworkButton = ({ onClick = () => {} }) => {
  return (
    <Button onClick={onClick} variant='solid'>
      New Casework
    </Button>
  )
}

export default NewCaseworkButton
