import React from 'react'

import { Switch, Text } from '@chakra-ui/react'

import { useTopicsContext } from '../ContextProvider/TopicsContext'

const ActiveOnlyFilterSwitch = () => {
  const { showActiveOnly, setShowActiveOnly } = useTopicsContext()

  return (
    <Text variant='subheading-sm'>
      Show active topics only
      <Switch
        id='switch-show-active-only'
        colorScheme='green'
        ml={2}
        isChecked={showActiveOnly}
        onChange={e => setShowActiveOnly(e.target.checked)}
      />
    </Text>
  )
}

export default ActiveOnlyFilterSwitch
