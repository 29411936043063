import React from 'react'

import {
  Button,
  useDisclosure,
  ButtonGroup,
  Spacer,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerFooter,
  useToast,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'

import SuggestTopicFormInputs from './SuggestTopicFormInputs'
import TopicSuggestionsList from './TopicSuggestionsList'
import {
  initialTopicSuggestionValues,
  topicSuggestionValidationSchema,
} from '../../../../constants'
import { createTopicSuggestion } from '../../../../redux/features/topicsSlice'

const SuggestTopicDrawer = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onCreateSuccess = resetForm => {
    resetForm()
    toast({
      title: 'Topic Suggestion Created',
      description: 'Your topic suggestion has been submitted.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  const onCreateFailure = errors => {
    if (errors?.non_field_errors?.includes('unique set'))
      toast({
        title: 'Could not create topic suggestion',
        description: 'This topic suggestion already exists.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    else
      toast({
        title: 'Could not create topic suggestion',
        description: `Something went wrong, ${JSON.stringify(errors)}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
  }

  return (
    <>
      <Button onClick={onOpen}> Suggest New Topic</Button>
      <Drawer isOpen={isOpen} onClose={onClose} size='xl'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Suggest Topic</DrawerHeader>
          <Formik
            initialValues={initialTopicSuggestionValues}
            validationSchema={topicSuggestionValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(false)
              dispatch(
                createTopicSuggestion({
                  suggestion: values,
                  callbackSuccess: () => onCreateSuccess(resetForm),
                  callbackFailure: onCreateFailure,
                })
              )
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <DrawerBody>
                <SuggestTopicFormInputs />
                <ButtonGroup w='100%' mt={3}>
                  <Button onClick={onClose} variant='ghost' colorScheme='red'>
                    Cancel
                  </Button>
                  <Spacer />
                  <Button isLoading={isSubmitting} onClick={handleSubmit}>
                    Suggest Topic
                  </Button>
                </ButtonGroup>
              </DrawerBody>
            )}
          </Formik>
          <DrawerFooter>
            <TopicSuggestionsList />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default SuggestTopicDrawer
