import React from 'react'

import { ButtonGroup, Flex, Spacer } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import ActiveOnlyFilterSwitch from './ActiveOnlyFilterSwitch'
import CreateTopicModalButton from './CreateTopicModalButton'
import ExportTopicsButton from './ExportTopicsButton'
import SuggestTopicDrawer from './SuggestTopicDrawer'
import TopicCount from './TopicCount'
import TopicList from './TopicList'
import TopicSearchBar from './TopicSearchBar'
import { TopicsContextProvider } from '../ContextProvider/TopicsContext'

const TopicsContent = () => {
  const user = useSelector(state => state.auth.user)

  return (
    <TopicsContextProvider>
      <Flex gap={4} align='center' mb={3}>
        <TopicSearchBar />
        <ButtonGroup>
          <ExportTopicsButton />
          {user.is_admin && <CreateTopicModalButton />}
          <SuggestTopicDrawer />
        </ButtonGroup>
      </Flex>
      <Flex align='center' w='100%' mb={3}>
        <TopicCount />
        <Spacer />
        {user.is_admin && <ActiveOnlyFilterSwitch />}
      </Flex>

      <Flex h='74vh' overflow='auto'>
        <TopicList />
      </Flex>
    </TopicsContextProvider>
  )
}

export default TopicsContent
