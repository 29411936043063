export const changes = [
  {
    version: '1.10.24',
    date: 'October 2024',
    notes: [
      'PII Flag added to Casework Attachments',
      'Preferred Language added to Constituents',
      'Pagination updated for casework',
    ],
  },
  {
    version: '1.7.24',
    date: 'July 2024',
    notes: ['Export constituents no longer available', 'Disclaimer Updated'],
  },
  {
    version: '1.6.24',
    date: 'June 2024',
    notes: [
      'Merging constituents now available! Select constituents under the three dots and merge via the menu next to Filters',
      'Filtering casework by unassigned staff now available',
      'Filtering casework and constituents by tags now available',
      'When filtering by a field, casework list will now display the field value (e.g. tags, agencies)',
      'Casework can now be directly created for a constituent in the directory table via the three dots menu',
      'Resizing directory columns now possible',
      'Constituent and staff filters now persistent',
      'Exporting constituents, staff, casework, and casework reports to excel sheets now available',
      'Fixed an issue where removing closed at date would prevent casework submission',
      'Redesigned assigned casework and added sorting functionality',
    ],
  },
  {
    version: '1.3.24',
    date: 'March 2024',
    notes: [
      'Reports added - Check it out under Tools',
      'Activity can now be deleted and created even when a case is closed',
      'Editing activity can now be cancelled',
      'Languages based on US Census Data added to constituent language list',
      'Constituent search logic improved and provides more targeted results',
      'Fixed an issue with removing casework assigned staff, topics, and address',
      'Fixed an issue where casework older than 90 days could not be reopened',
      'Casework can now be filtered by unassigned under Topics',
      'Added new "Pending" status for casework that are entered but not started',
      'Casework and Directory filters stay persistent per tab (except Assigned Staff & Constituent)',
      'Casework assignment emails now includes constituent info',
      'Transferring cases automatically changes constituent visibility',
      'Various UI Improvements and Changes',
    ],
  },
]
