import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useDisclosure, useToast } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  createCaseworkTag,
  deleteCaseworkTag,
  loadCaseworkTags,
  setCaseworkTags,
  updateCaseworkTag,
} from '../../../../redux/features/caseworkSlice'
import {
  addConstituentToTag,
  clearConstituents,
  createConstituentTag,
  deleteConstituentTag,
  loadConstituentsByTag,
  loadConstituentTags,
  removeConstituentFromTag,
  setConstituentTags,
  updateConstituentTag,
} from '../../../../redux/features/constituentsSlice'

const TagsContext = createContext()

export const useTagsContext = () => useContext(TagsContext)

export const TagsContextProvider = ({ mode, children }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [searchValue, setSearchValue] = useState('')

  const user = useSelector(state => state.auth.user)
  const tags = useSelector(state => state[mode].tag_options) || []
  const count = useSelector(state => state[mode].tags_count) || 0
  const isLoading = useSelector(state => state[mode].tags_loading) || false
  const constituentsByTag = useSelector(state => state.constituents.data)

  const [selectedTag, setSelectedTag] = useState(undefined)
  const {
    isOpen: constituentsIsOpen,
    onOpen: constituentsOnOpen,
    onClose: constituentsOnClose,
  } = useDisclosure()

  const filteredTags = useMemo(() => {
    if (!tags) return []
    if (!searchValue) return tags

    if (user.is_admin)
      return tags.filter(
        ({ label, district }) =>
          label.includes(searchValue.toUpperCase()) ||
          district.includes(searchValue.toUpperCase())
      )

    return tags.filter(({ label }) => label.includes(searchValue.toUpperCase()))
  }, [tags, searchValue])

  const tagsByDistrict = useMemo(
    () =>
      filteredTags.reduce((groupedTags, tag) => {
        const { district } = tag
        if (!groupedTags[district]) {
          groupedTags[district] = []
        }
        groupedTags[district].push(tag)
        return groupedTags
      }, {}),
    [filteredTags]
  )

  const updateTagsInStore = useCallback((updatedTags, updatedCount) => {
    if (mode === 'casework')
      dispatch(setCaseworkTags({ tags: updatedTags, count: updatedCount }))
    else if (mode === 'constituents')
      dispatch(setConstituentTags({ tags: updatedTags, count: updatedCount }))
  }, [])

  const getTags = useCallback(() => {
    const payload = {
      params: {
        limit: 50000,
      },
    }
    if (mode === 'casework') dispatch(loadCaseworkTags(payload))
    else if (mode === 'constituents') dispatch(loadConstituentTags(payload))
  }, [])

  useEffect(() => {
    getTags()
  }, [])

  const getConstituentsByTag = tagId => {
    dispatch(clearConstituents())
    if (tagId) dispatch(loadConstituentsByTag({ tagId }))
  }

  const openConstituentsDrawer = tag => {
    constituentsOnOpen()
    getConstituentsByTag(tag.id)
    setSelectedTag(tag)
  }

  const closeConstituentsDrawer = () => {
    constituentsOnClose()
    getConstituentsByTag()
  }

  const addConstituent = (constituent, tag) => {
    if (user.district === constituent.district) {
      dispatch(
        addConstituentToTag({ tagId: tag.id, constituentId: constituent.id })
      )

      updateTagsInStore(
        tags?.map(t => (t.id === tag.id ? { ...tag, count: t.count + 1 } : t))
      )
    }
  }

  const removeConstituent = (constituent, tag) => {
    dispatch(
      removeConstituentFromTag({ tagId: tag.id, constituentId: constituent.id })
    )

    updateTagsInStore(
      tags?.map(t => (t.id === tag.id ? { ...tag, count: t.count - 1 } : t))
    )
  }

  const toastMessage = (message, description = '', status) => {
    toast({
      title: message,
      description: description,
      status: status,
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    })
  }

  const createTag = useCallback((tag, onCreate) => {
    const onSuccess = () => {
      toastMessage(`${tag.label} added to tags`, '', 'success')
      onCreate()
      getTags()
    }

    const onFailure = error => {
      toastMessage('Failed to add tag', error, 'error')
    }

    const payload = {
      tag,
      callbackSuccess: onSuccess,
      callbackFailure: onFailure,
    }

    if (mode === 'casework') dispatch(createCaseworkTag(payload))
    else if (mode === 'constituents') dispatch(createConstituentTag(payload))
  }, [])

  const updateTag = useCallback(
    tag => {
      const onSuccess = () => {
        toastMessage(`${tag.label} saved`, '', 'success')
        const updatedTags = tags.map(t =>
          t.id === tag.id ? { ...t, ...tag } : t
        )
        updateTagsInStore(updatedTags)
      }

      const onFailure = () => {
        toastMessage('Failed to edit tag', 'error')
      }

      const payload = {
        tag,
        callbackSuccess: onSuccess,
        callbackFailure: onFailure,
      }
      if (mode === 'casework') dispatch(updateCaseworkTag(payload))
      else if (mode === 'constituents') dispatch(updateConstituentTag(payload))
    },
    [tags, count]
  )

  const deleteTag = useCallback(
    tag => {
      const onSuccess = () => {
        toastMessage(`${tag.label} deleted`, '', 'success')
        const updatedTags = tags.filter(t => t.id !== tag.id)
        updateTagsInStore(updatedTags, count - 1)
      }

      const onFailure = () => {
        toastMessage('Failed to delete tag', 'error')
      }

      const payload = {
        tag,
        callbackSuccess: onSuccess,
        callbackFailure: onFailure,
      }
      if (mode === 'casework') dispatch(deleteCaseworkTag(payload))
      else if (mode === 'constituents') dispatch(deleteConstituentTag(payload))
    },
    [tags, count]
  )

  return (
    <TagsContext.Provider
      value={{
        searchValue,
        setSearchValue,

        tags,
        tagsByDistrict,
        count,
        filteredTags,
        isLoading,
        mode,

        getTags,
        createTag,
        updateTag,
        deleteTag,

        constituentsByTag,
        getConstituentsByTag,

        selectedTag,
        setSelectedTag,
        constituentsIsOpen,
        openConstituentsDrawer,
        closeConstituentsDrawer,
        addConstituent,
        removeConstituent,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}
