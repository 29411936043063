import React, { useCallback, useEffect } from 'react'

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import {
  loadTopicInstance,
  updateTopic,
} from '../../../../redux/features/topicsSlice'

const ActiveStatusChangeModalSwitch = ({
  id,
  isActive,
  setIsActive,

  cancelEditing,
}) => {
  const dispatch = useDispatch()
  const toast = useToast()

  const topic = useSelector(state => state.topics.instance)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isOpen) dispatch(loadTopicInstance({ id }))
  }, [isOpen, id])

  const handleClose = () => {
    setIsActive.toggle()
    onClose()
  }

  const onConfirmSuccess = useCallback(() => {
    toast({
      title: isActive
        ? 'Successfully re-activated topic'
        : 'Successfully deactivated topic',
      status: isActive ? 'success' : 'warning',
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    })
    cancelEditing()
    onClose()
  }, [isActive])

  const onConfirmFailure = useCallback(() => {
    toast({
      title: 'Failed to update status',
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: 'bottom-right',
    })
  }, [])

  const handleConfirm = () => {
    dispatch(
      updateTopic({
        topic: { ...topic, is_active: isActive },
        callbackSuccess: onConfirmSuccess,
        callbackFailure: onConfirmFailure,
      })
    )
  }

  return (
    <>
      <Switch
        isChecked={isActive}
        onChange={() => {
          onOpen()
          setIsActive.toggle()
        }}
        colorScheme='green'
      />

      <Modal
        isOpen={isOpen && topic && topic.id === id}
        onClose={handleClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Active Status Change</ModalHeader>
          <ModalBody>
            <Box>
              <Text>
                Are you sure you want to{' '}
                {isActive ? 're-activate' : 'deactivate'} <b>{topic?.name}</b>?
                This topic has <b>{topic?.casework_count}</b> cases.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex w='100%'>
              <Button onClick={handleClose} variant='ghost' colorScheme='red'>
                Cancel
              </Button>
              <Spacer />
              <Button onClick={handleConfirm} colorScheme='green'>
                Confirm
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ActiveStatusChangeModalSwitch
