import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  results: null,
  filters: {},
}

export const reportSlice = createSlice({
  initialState,
  name: 'reports',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload
    },

    setLoading(state, action) {
      state.loading = action.payload
    },
    setResults(state, action) {
      state.loading = false
      state.results = action.payload
      state.generated = true
    },
    generateReport(state) {
      state.loading = true
    },
    exportReport(state) {
      state.exporting = true
    },
    completeExport(state) {
      delete state.exporting
    },
  },
})

export default reportSlice.reducer

export const {
  setFilters,
  setLoading,
  setResults,
  generateReport,
  exportReport,
  completeExport,
} = reportSlice.actions
