import React, { useState, useEffect } from 'react'

import { useMediaQuery, Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import ConstituentCard from './ConstituentCard'
import TableRow from './TableRow'
import {
  clearConstituents,
  loadConstituents,
  paginate,
} from '../../../../redux/features/constituentsSlice'
import { Loading, NoResults, ResizableTable } from '../../../ui'
import { throttle } from '../../../utils'

const ConstituentsTable = ({ tableRef }) => {
  // const tableRef = useRef(null)
  const dispatch = useDispatch()
  const [onClose, setOnClose] = useState(false)

  const loading = useSelector(state => state.constituents.loading)
  const constituents = useSelector(state => state.constituents.data)

  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')

  // Define table headers for ResizableTable
  const headers = [
    { name: 'Name', value: 'name' },
    { name: 'Home Address' },
    { name: 'Business Address' },
    { name: 'Email' },
    { name: 'Phone Number(s)' },
    { name: 'Groups' },
    { name: 'Associated Topics' },
    { name: 'District', value: 'district' },
    { name: '' },
  ]

  const handleScroll = () => {
    const container = tableRef.current

    if (
      container?.scrollTop + container?.clientHeight >=
      container?.scrollHeight - 50
    ) {
      dispatch(paginate())
    }
  }

  useEffect(() => {
    dispatch(loadConstituents())

    // Load more data when reaching the end of the container
    const throttledHandleScroll = throttle(handleScroll, 200)

    const container = tableRef.current
    container?.addEventListener('scroll', throttledHandleScroll, false)
    return () => {
      container?.removeEventListener('scroll', throttledHandleScroll, false)
      dispatch(clearConstituents())
    }
  }, [dispatch])

  useEffect(() => {
    const closeOptionsOnScroll = () => {
      setOnClose(true)
    }
    const endCloseOptionsOnScroll = () => {
      setOnClose(false)
    }

    const container = tableRef.current
    container?.addEventListener('scroll', closeOptionsOnScroll, true)
    container?.addEventListener('scrollend', endCloseOptionsOnScroll, true)
    return () => {
      container?.removeEventListener('scroll', closeOptionsOnScroll, true)
      container?.removeEventListener('scrollend', endCloseOptionsOnScroll, true)
    }
  }, [])

  const tableContent = isLargerThan992 ? (
    constituents.map((constituent, index) => (
      <TableRow
        constituent={constituent}
        key={index}
        index={index}
        onClose={onClose}
      />
    ))
  ) : (
    <Stack>
      {constituents.map((constituent, index) => (
        <ConstituentCard key={index} constituent={constituent} index={index} />
      ))}
    </Stack>
  )

  return (
    <>
      {isLargerThan992 ? (
        <ResizableTable
          localStorageKeyName={'ConstituentsTable'}
          headers={headers}
          tableContent={tableContent}
          minCellWidth={150}
        />
      ) : (
        tableContent
      )}
      {loading && <Loading />}
      {!loading && constituents?.length === 0 && <NoResults />}
    </>
  )
}

export default ConstituentsTable
