import { call, cancel, put, takeLatest } from 'redux-saga/effects'

import { extractCaseNum } from '../../components/utils'
import backendAPI from '../axiosConfig'
import { quickSearch, storeSearchResults } from '../features/homeSlice'

function* quickSearchSaga(action) {
  try {
    const searchQuery = action.payload
    if (searchQuery.length === 0) {
      yield put(storeSearchResults(null))
      cancel()
    }
    let caseworkResults, constituentResults

    const case_num = parseFloat(extractCaseNum(searchQuery))
    if (!isNaN(case_num)) {
      const caseworkResponse = yield call(() => {
        return backendAPI.get(`/api/casework/?case_num=${case_num}`)
      })
      caseworkResults = caseworkResponse.data.results.slice(0, 5)
    } else {
      const caseworkResponse = yield call(() => {
        return backendAPI.get(
          `/api/casework/?search=${searchQuery}&order_by=-last_updated`
        )
      })
      caseworkResults = caseworkResponse.data.results.slice(0, 5)
    }

    const constituentResponse = yield call(() => {
      return backendAPI.get(`/api/constituents/?search=${searchQuery}`)
    })
    constituentResults = constituentResponse.data.results.slice(0, 5)

    yield put(
      storeSearchResults({
        casework: caseworkResults || [],
        constituents: constituentResults,
      })
    )
  } catch (error) {
    console.error(error)
  }
}
function* homeAPI() {
  yield takeLatest(quickSearch, quickSearchSaga)
}

export default homeAPI
