import React, { useMemo } from 'react'

import { Box, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import EditableTopic from './EditableTopic'
import Topic from './Topic'

const Issue = ({ issue, topics }) => {
  const user = useSelector(state => state.auth.user)
  const isInactive = useMemo(
    () => (user.is_admin ? topics.every(topic => !topic.is_active) : false),
    [user, topics]
  )

  return (
    <Box>
      <Box
        pb={2}
        position='sticky'
        zIndex='1'
        top='0'
        bg='var(--chakra-colors-chakra-body-bg)'
      >
        <Text fontSize='xl' variant='subheading-blue'>
          {issue}
        </Text>
        {isInactive && (
          <Text as='span' color='var(--chakra-colors-red-500)'>
            Issue Inactive
          </Text>
        )}
      </Box>
      {user.is_admin
        ? topics.map((topic, index) => (
            <EditableTopic key={`issue-topic-${index}`} {...topic} />
          ))
        : topics.map((topic, index) => (
            <Topic key={`issue-topic-${index}`}>{topic.name}</Topic>
          ))}
    </Box>
  )
}

export default Issue
