import { React, useRef } from 'react'

import { useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from 'react-router-dom'

import {
  Home,
  Login,
  ResetPassword,
  CaseworkDetail,
  Directory,
  ConstituentDetail,
  StaffProfile,
  Casework,
  AdminPortal,
  Tools,
  CreateCasework,
} from './components/pages'
import EditCasework from './components/pages/EditCasework'
import { AddressLookupTool } from './components/tools'
import { Compendium } from './components/tools/compendium'
import { Reports } from './components/tools/reports'
import { SiteDisclaimer } from './components/ui'
import ErrorBoundary from './ErrorBoundary'
import Layout from './Layout'
import { loginWithToken, logout } from './redux/features/authSlice'
import { calculateMS } from './utils'

const IDLE_TIMEOUT = calculateMS(2, 'h')

const ProtectedRoute = ({ children }) => {
  const toast = useToast()
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.auth.loading)
  const token = Cookies.get('api_token')

  const idleToastRef = useRef(null)

  function onIdle() {
    // handle logout here
    if (token) {
      dispatch(
        logout({
          callback: () => {
            idleToastRef.current = toast({
              title: 'Logged out',
              status: 'warning',
              description: 'Your session has expired, please log in again.',
              duration: null,
              isClosable: true,
              position: 'top',
            })
          },
        })
      )
    }
  }

  const onAction = e => {
    if (idleToastRef.current && e.type.includes('mouse')) {
      toast.close(idleToastRef.current)
    }
  }

  useIdleTimer({
    onIdle,
    onAction,
    timeout: IDLE_TIMEOUT,
    debounce: 250, // check every 250 ms
  })

  if (!token) {
    return <Login />
  }

  return isLoading ? (
    <Outlet />
  ) : (
    <Layout>
      <SiteDisclaimer />
      {children}
    </Layout>
  )
}

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const token = Cookies.get('api_token')

  if (token && !user) {
    dispatch(loginWithToken({ token }))
  }

  const router = createBrowserRouter([
    {
      path: '/reset_password/:token',
      element: <ResetPassword />,
    },
    {
      path: '/',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
    },
    {
      path: '/directory',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <Directory />
        </ProtectedRoute>
      ),
    },
    {
      path: '/casework',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <Casework />
        </ProtectedRoute>
      ),
    },
    {
      path: '/casework/create',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <CreateCasework />
        </ProtectedRoute>
      ),
    },
    {
      path: '/directory/constituents/:id',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <ConstituentDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: '/tools',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <Tools />
        </ProtectedRoute>
      ),
    },
    {
      path: '/tools/address-lookup',
      element: (
        <ProtectedRoute>
          <AddressLookupTool />
        </ProtectedRoute>
      ),
    },
    {
      path: '/tools/reports',
      element: (
        <ProtectedRoute>
          <Reports />
        </ProtectedRoute>
      ),
    },
    {
      path: '/tools/compendium',
      element: (
        <ProtectedRoute>
          <Compendium />
        </ProtectedRoute>
      ),
    },
    {
      path: '/casework/:case_num',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <CaseworkDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: '/casework/:case_num/edit',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <EditCasework />
        </ProtectedRoute>
      ),
    },
    {
      path: '/casework/archive/:case_num',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <CaseworkDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: '/directory/staff/:id',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <StaffProfile />
        </ProtectedRoute>
      ),
    },
    {
      path: '/admin',
      ErrorBoundary: ErrorBoundary,
      element: (
        <ProtectedRoute>
          <AdminPortal />
        </ProtectedRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
