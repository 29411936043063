import React from 'react'

import { LockIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardBody,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Building2Icon, UserRoundIcon } from 'lucide-react'
import { useSelector } from 'react-redux'

import ConstituentMenu from './ConstituentMenu'
import { EditConstituent } from '../..'
import { formatDistrict } from '../../../../utils'
import { AnonymousPlaceholder, NotFound } from '../../../ui'

const NameBox = () => {
  const constituent = useSelector(state => state.constituents.instance)
  if (!constituent) return <NotFound />

  const {
    name,
    district,
    category,
    title,
    casework,
    visible_to_district_only,
  } = constituent

  return (
    <Stack direction={{ base: 'column', sm: 'row' }}>
      <Center>
        <Avatar
          bg='blue.400'
          size='xl'
          icon={
            <Icon
              as={category === 'Business' ? Building2Icon : UserRoundIcon}
              fontSize='3.5rem'
              strokeWidth='1.5px'
            />
          }
        />
      </Center>
      <Card w='100%' boxShadow='0px 1px 3px 1px rgba(0, 0, 0, 0.1)'>
        <CardBody>
          <Flex direction={{ base: 'column', sm: 'row' }} align='center'>
            <HStack>
              {name ? (
                <Heading>{name}</Heading>
              ) : (
                <AnonymousPlaceholder size='xl' />
              )}
            </HStack>

            <Spacer />
            <Box align='bottom'>
              {visible_to_district_only && (
                <Tooltip label='Constituent is visible to district only'>
                  <IconButton
                    icon={<LockIcon boxSize={6} />}
                    variant='unstyled'
                  />
                </Tooltip>
              )}
              <EditConstituent
                constituent={constituent}
                button={
                  <IconButton
                    icon={<Icon as={PencilSquareIcon} boxSize={6} />}
                    variant='ghost'
                  />
                }
              />

              <ConstituentMenu casework={casework} district={district} />
            </Box>
          </Flex>
          {(title || category) && (
            <Text variant='subheading-gray'>{title || category}</Text>
          )}

          <Badge> District {formatDistrict(district)} </Badge>
        </CardBody>
      </Card>
    </Stack>
  )
}

export default NameBox
