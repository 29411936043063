import React, { useMemo } from 'react'

import { useField } from 'formik'

import BaseArrayField from './BaseArrayField'
import { ConstituentTagSelect } from '../../../../ui/select'

const TagsField = ({ field = 'tags' }) => {
  const [formikField] = useField(field)
  const { value: tags } = formikField

  const selectedTags = useMemo(
    () => tags?.map(tag => ({ label: tag.label, value: tag })),
    [tags]
  )

  return (
    <BaseArrayField label='Tags' field={field}>
      {({ onSelect, onRemove, onClear }) => (
        <ConstituentTagSelect
          onSelect={onSelect}
          onRemove={onRemove}
          onClear={onClear}
          initialSelected={selectedTags}
          dropdownHeight={150}
          inputProps={{
            id: 'tag-select',
          }}
        />
      )}
    </BaseArrayField>
  )
}

export default TagsField
