import React from 'react'

import { Avatar, Stack, Text, Tooltip } from '@chakra-ui/react'

import AnonymousPlaceholder from './AnonymousPlaceholder'

const Person = ({ link, name, hideAvatar }) => (
  <Tooltip
    label={link ? 'Click to open profile in new tab' : ''}
    placement='bottom-start'
    openDelay={200}
  >
    <Stack
      direction='row'
      align='center'
      sx={{ userSelect: 'none' }}
      _hover={
        link
          ? {
              cursor: 'pointer',
              textShadow: '0px 0px 1px currentColor',
            }
          : undefined
      }
      onClick={link ? () => window.open(link, '_blank') : () => {}}
    >
      {!hideAvatar && <Avatar name={name} size='sm' />}
      {name ? <Text align='center'> {name} </Text> : <AnonymousPlaceholder />}
    </Stack>
  </Tooltip>
)
export default Person
