import React from 'react'

import { Stack, useColorModeValue } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import { File } from '../../ui'

const Attachments = ({
  attachments,
  isEditing,
  onCancelAdd,
  onRemoveAttachment,
  onOpenAttachment,
  handleFlagChange = () => {},
  handleFileNameChange = () => {},
}) => {
  const uploadWaiting = useSelector(
    state => state.casework.attachment_uploading
  )
  const uploadComplete = useSelector(
    state => state.casework.attachment_upload_complete
  )
  const removeWaiting = useSelector(state => state.casework.attachment_removing)
  const removeComplete = useSelector(
    state => state.casework.attachment_remove_complete
  )

  const attachmentBgColor = useColorModeValue('blue.500', 'blue.200')
  const attachmentTextColor = useColorModeValue('gray.100', 'blue.800')

  const fileProps = {
    new: {
      textColor: 'blue.800',
      bgColor: 'green.100',
    },
    remove: {
      textColor: attachmentTextColor,
      bgColor: 'red.200',
    },
    undefined: {
      textColor: attachmentTextColor,
      bgColor: attachmentBgColor,
    },
  }

  const onButtonClick = (index, file) => {
    let status = file.status
    if (status === 'new' && onCancelAdd) return onCancelAdd(index)
    if (onRemoveAttachment) return onRemoveAttachment(file)
  }

  return (
    <Stack my={2}>
      {attachments?.map((file, index) => (
        <File
          key={`attachment-file-${index}`}
          index={index}
          isEditing={isEditing}
          file={file}
          fileName={file.file_name}
          containsPII={file.contains_pii}
          textWeight='bold'
          strikethrough={file.status === 'remove' ? 's' : ''}
          {...fileProps[file.status]}
          uploading={file.status === 'new' && uploadWaiting === index}
          uploadComplete={
            file.status === 'new' && uploadComplete?.includes(index)
          }
          removing={
            removeWaiting &&
            file.status === 'remove' &&
            removeWaiting === file.id
          }
          removeComplete={
            removeComplete &&
            file.status === 'remove' &&
            removeComplete?.includes(file.id)
          }
          onFlagChange={e => handleFlagChange(e, index)}
          onNameChange={handleFileNameChange}
          onButtonClick={() => onButtonClick(index, file)}
          onClick={onOpenAttachment && (() => onOpenAttachment(file))}
        />
      ))}
    </Stack>
  )
}

export default Attachments
