import React, { useRef, useState } from 'react'
import { useEffect } from 'react'

import { HamburgerIcon, LockIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowsRightLeftIcon,
  FolderOpenIcon,
  LockClosedIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { loadCaseworkInstance } from '../../redux/features/caseworkSlice'
import { ActivityCard, CaseworkDetailTable } from '../casework'
import {
  ArchiveDialog,
  CloseDialog,
  DeleteDialog,
  ReopenDialog,
  TransferDialog,
  UnarchiveDialog,
} from '../casework/casework/actionDialogs'
import { Loading, NotFound, Person, Section } from '../ui'
import { daysSinceDate, formatCaseNum } from '../utils'

const CaseworkDetail = () => {
  const { case_num } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.casework.loading)
  const caseworkInstance = useSelector(
    state => state.casework.casework_instance
  )

  const [actionStatus, setActionStatus] = useState('')
  const headingRef = useRef(null)

  const caseworkTextColor = useColorModeValue('#23417D', 'white.900')

  useEffect(() => {
    dispatch(
      loadCaseworkInstance({
        case_num,
        archive: location.pathname.includes('/archive'),
      })
    )
  }, [dispatch, case_num])

  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onClose: confirmOnClose,
  } = useDisclosure()

  if (loading) return <Loading />

  if (!caseworkInstance) return <NotFound />
  document.title = `Casework #${formatCaseNum(
    caseworkInstance
  )} | Council Connect`

  const {
    intake_staff,
    assigned_staff,
    status,
    opened_at,
    topics,
    visible_to_district_only,
  } = caseworkInstance

  /** Add export later */
  const additionalActions = []
  if (
    status !== 'Archived' &&
    user.permissions.includes('casework.archive_casework')
  ) {
    additionalActions.push('archive')
  }
  if (user.permissions.includes('casework.delete_casework')) {
    additionalActions.push('delete')
  }
  if (
    !['Transferred', 'Closed', 'Archived'].includes(status) &&
    user.permissions.includes('casework.transfer_casework')
  ) {
    additionalActions.push('transfer')
  }

  const dialogProps = {
    isOpen: confirmIsOpen,
    onClose: confirmOnClose,
    leastDestructiveRef: headingRef,
    case_num,
  }

  return (
    <Container maxW='8xl' p={10}>
      <Flex align='center' mb={4}>
        <Box>
          <Heading
            size='2xl'
            fontWeight={900}
            color={caseworkTextColor}
            ref={headingRef}
          >
            #{formatCaseNum(caseworkInstance)}
          </Heading>
        </Box>
        <Spacer />
        <ButtonGroup>
          {visible_to_district_only && (
            <Tooltip label='Casework is visible to district only'>
              <IconButton icon={<LockIcon boxSize={6} />} variant='unstyled' />
            </Tooltip>
          )}
          {status === 'Archived' &&
            user.permissions.includes('casework.unarchive_casework') && (
              <Button
                leftIcon={<Icon as={ArchiveBoxXMarkIcon} />}
                textTransform='uppercase'
                variant='outline'
                onClick={() => {
                  setActionStatus('Unarchive')
                  confirmOnOpen()
                }}
              >
                Unarchive
              </Button>
            )}

          {!['Closed', 'Archived', 'Transferred'].includes(status) && (
            <>
              <Tooltip
                label={
                  topics?.length > 0
                    ? ''
                    : 'Topic must be assigned to close this case!'
                }
              >
                <Button
                  leftIcon={<Icon as={LockClosedIcon} />}
                  textTransform='uppercase'
                  variant='outline'
                  onClick={() => {
                    setActionStatus('Closed')
                    confirmOnOpen()
                  }}
                  isDisabled={topics?.length === 0}
                >
                  Close
                </Button>
              </Tooltip>
              <Button
                leftIcon={<Icon as={PencilSquareIcon} />}
                textTransform='uppercase'
                variant='outline'
                onClick={() => navigate(`/casework/${case_num}/edit`)}
              >
                Edit
              </Button>
            </>
          )}
          {status === 'Closed' &&
            (daysSinceDate(opened_at) <= 90 ||
              user.permissions.includes('casework.reopen_90d_casework')) && (
              <Button
                leftIcon={<Icon as={FolderOpenIcon} />}
                textTransform='uppercase'
                variant='outline'
                onClick={() => {
                  setActionStatus('Reopen')
                  confirmOnOpen()
                }}
              >
                Reopen
              </Button>
            )}
          {additionalActions.length > 0 && (
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                aria-label='Additional Casework Actions'
                icon=<HamburgerIcon />
                variant='outline'
              />
              <Box>
                <MenuList>
                  {additionalActions.includes('transfer') && (
                    <MenuItem
                      icon={<Icon as={ArrowsRightLeftIcon} />}
                      onClick={() => {
                        setActionStatus('Transfer')
                        confirmOnOpen()
                      }}
                    >
                      TRANSFER
                    </MenuItem>
                  )}
                  {additionalActions.includes('archive') && (
                    <MenuItem
                      icon={<Icon as={ArchiveBoxIcon} />}
                      onClick={() => {
                        setActionStatus('Archive')
                        confirmOnOpen()
                      }}
                    >
                      ARCHIVE
                    </MenuItem>
                  )}
                  {additionalActions.includes('delete') && (
                    <MenuItem
                      icon={<Icon as={TrashIcon} />}
                      onClick={() => {
                        setActionStatus('Delete')
                        confirmOnOpen()
                      }}
                      color='red'
                    >
                      DELETE
                    </MenuItem>
                  )}
                </MenuList>
              </Box>
            </Menu>
          )}
        </ButtonGroup>
      </Flex>

      <Flex gap={3} direction={{ base: 'column', lg: 'row' }}>
        <CaseworkDetailTable {...caseworkInstance} detailView />

        <Spacer />
        <Flex direction='column' gap={5}>
          <Section heading='Assigned Staff'>
            {assigned_staff?.length > 0 ? (
              assigned_staff.map((staff, index) => (
                <Person
                  key={index}
                  name={staff.full_name}
                  link={`/directory/staff/${staff.id}`}
                />
              ))
            ) : (
              <Text as='em'>No staff has been assigned to this casework.</Text>
            )}
          </Section>
          {intake_staff && (
            <Section heading='Intake By'>
              <Person
                name={intake_staff.full_name}
                link={`/directory/staff/${intake_staff.id}`}
              />
            </Section>
          )}
          <ActivityCard />
        </Flex>
      </Flex>
      {actionStatus === 'Delete' && <DeleteDialog {...dialogProps} />}
      {actionStatus === 'Transfer' && <TransferDialog {...dialogProps} />}
      {actionStatus === 'Archive' && <ArchiveDialog {...dialogProps} />}
      {actionStatus === 'Unarchive' && <UnarchiveDialog {...dialogProps} />}
      {actionStatus === 'Closed' && <CloseDialog {...dialogProps} />}
      {actionStatus === 'Reopen' && <ReopenDialog {...dialogProps} />}
    </Container>
  )
}

export default CaseworkDetail
