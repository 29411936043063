import React from 'react'

import { Badge } from '@chakra-ui/react'

const AnonymousPlaceholder = ({ size }) => {
  return (
    <Badge fontSize={size} colorScheme='orange'>
      Name not provided
    </Badge>
  )
}

export default AnonymousPlaceholder
