import { call, put, select, takeLatest } from 'redux-saga/effects'

import { generateDistrictsSaga } from './directorySaga'
import { downloadFile, formatDistrict, getFilterQueryString } from '../../utils'
import backendAPI from '../axiosConfig'
import {
  cancelLoading,
  createConstituent,
  deleteConstituent,
  loadConstituentInstance,
  loadConstituentOptions,
  loadConstituentTags,
  loadConstituents,
  paginate,
  storeConstituentInstance,
  storeConstituentOptions,
  storeConstituentTags,
  storeConstituents,
  updateConstituent,
  setErrors,
  paginateConstituentOptions,
  startExportConstituents,
  completeExportConstituents,
  paginateConstituentTags,
  mergeConstituents,
  createConstituentTag,
  deleteConstituentTag,
  updateConstituentTag,
  loadConstituentsByTag,
  removeConstituentFromTag,
  clearConstituents,
  addConstituentToTag,
  setConstituentTags,
} from '../features/constituentsSlice'
import {
  applyDirectoryFilters,
  setDirectoryFilters,
} from '../features/directorySlice'

const CONSTITUENT_ENDPOINT = '/api/constituents/'
function* loadConstituentsSaga() {
  try {
    const filters = yield select(state => state.directory.filters)
    const user = yield select(state => state.auth.user)

    if (filters.district__in === undefined) {
      yield put(
        setDirectoryFilters({
          ...filters,
          district__in: [formatDistrict(user.district)],
        })
      )
    }
    yield put(applyDirectoryFilters({ filters_changed: true }))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Loading constituents list failed', error))
  }
}

function* populateConstituentDistrictsSaga(constituent) {
  if (!constituent) return constituent

  if (constituent.home_address)
    constituent.home_address = yield generateDistrictsSaga(
      constituent.home_address
    )

  if (constituent.business_address)
    constituent.business_address = yield generateDistrictsSaga(
      constituent.business_address
    )

  return constituent
}

function* loadConstituentInstanceSaga(action) {
  const { id } = action.payload
  try {
    const baseUrl = CONSTITUENT_ENDPOINT + `${id}/`
    const response = yield call(() => {
      return backendAPI.get(baseUrl)
    })

    const constituent = response.data

    const caseworkResponse = yield call(() => {
      return backendAPI.get(baseUrl + 'casework/')
    })

    constituent.casework = caseworkResponse.data

    yield populateConstituentDistrictsSaga(constituent)
    yield put(storeConstituentInstance(constituent))
  } catch (error) {
    console.error(error)
    yield put(storeConstituentInstance(null))
    yield put(setErrors('Constituent not found', error))
  }
}

function* loadConstituentOptionsSaga(action) {
  try {
    const { value: query, districtOnly, constituentType } = action.payload
    const user = yield select(state => state.auth.user)
    let endpoint = CONSTITUENT_ENDPOINT + `select/?name=${query}`
    if (districtOnly) endpoint += '&district=' + user.district
    if (constituentType) endpoint += '&constituent_type=' + constituentType
    const response = yield call(() => {
      return backendAPI.get(endpoint)
    })
    const { results, next } = response.data
    yield put(storeConstituentOptions({ results, next }))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Loading constituent names failed'))
  }
}

function* paginateConstituentOptionsSaga() {
  const nextUrl = yield select(state => state.constituents.options_next)
  if (!nextUrl) return

  try {
    const response = yield call(() => {
      return backendAPI.get(nextUrl)
    })
    const { results, next } = response.data
    yield put(storeConstituentOptions({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* loadConstituentTagsSaga(action) {
  try {
    const params = action.payload?.params
    let url = CONSTITUENT_ENDPOINT + 'tags/'
    if (params) {
      url += `?${getFilterQueryString(params)}`
    }
    const response = yield call(() => {
      return backendAPI.get(url)
    })
    const { results, next, count } = response.data
    yield put(storeConstituentTags({ results, next, count }))
  } catch (error) {
    console.error(error)
  }
}

function* paginateConstituentTagsSaga() {
  const nextUrl = yield select(state => state.constituents.tags_next)
  if (!nextUrl) return

  try {
    const response = yield call(() => {
      return backendAPI.get(nextUrl)
    })
    const { results, next } = response.data
    yield put(storeConstituentTags({ results, next }))
  } catch (error) {
    console.error(error)
  }
}

function* createConstituentTagSaga(action) {
  const { tag, callbackSuccess, callbackFailure } = action.payload
  try {
    let url = CONSTITUENT_ENDPOINT + 'tags/'

    yield call(() => {
      return backendAPI.post(url, tag)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    yield call(callbackFailure, error?.response?.data?.non_field_errors?.[0])

    console.error(error)
  }
}
function* updateConstituentTagSaga(action) {
  const { tag, callbackSuccess, callbackFailure } = action.payload
  try {
    let url = CONSTITUENT_ENDPOINT + `tags/${tag.id}/`

    yield call(() => {
      return backendAPI.patch(url, tag)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    if (callbackFailure)
      yield call(callbackFailure, error?.response?.data?.non_field_errors?.[0])
    console.error(error)
  }
}
function* deleteConstituentTagSaga(action) {
  const { tag, callbackSuccess, callbackFailure } = action.payload
  try {
    let url = CONSTITUENT_ENDPOINT + `tags/${tag.id}/`

    yield call(() => {
      return backendAPI.delete(url)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    if (callbackFailure) yield call(callbackFailure)
    console.error(error)
  }
}

function* paginateSaga() {
  try {
    const paginateUrl = yield select(state => state.constituents.next)
    if (!paginateUrl) {
      yield put(cancelLoading())
      return
    }

    const response = yield call(() => {
      return backendAPI.get(paginateUrl)
    })
    const { count, next, results } = response.data
    yield put(storeConstituents({ count, next, results }))
  } catch (error) {
    console.error(error)
    yield put(setErrors('Paginating constituents failed', error))
  }
}

function* createConstituentSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.post(CONSTITUENT_ENDPOINT, values)
    })
    const { id } = response.data
    if (callbackSuccess) yield call(callbackSuccess, id)
  } catch (error) {
    console.error(error)
    yield put(setErrors('Create constituent failed', error))
    if (callbackFailure) yield call(callbackFailure, error.response?.data)
  }
}

function* updateConstituentSaga(action) {
  const { id, values, callbackSuccess, callbackFailure } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.patch(CONSTITUENT_ENDPOINT + `${id}/`, values)
    })
    const instance = yield select(state => state.constituents.instance)
    const updatedConstituent = { ...instance, ...response.data }
    yield populateConstituentDistrictsSaga(updatedConstituent)
    yield put(storeConstituentInstance(updatedConstituent))

    if (callbackSuccess) yield call(callbackSuccess, id)
  } catch (error) {
    console.error('Updating constituent failed', error)
    yield put(setErrors('Updating constituent failed', error))
    if (callbackFailure) yield call(callbackFailure, error.response.data)
  }
}

function* deleteConstituentSaga(action) {
  const { id, callbackSuccess, callbackFailure } = action.payload
  try {
    yield call(() => {
      return backendAPI.delete(CONSTITUENT_ENDPOINT + `${id}/`)
    })
    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* mergeConstituentsSaga(action) {
  const { values, callbackSuccess, callbackFailure } = action.payload

  try {
    yield call(() => {
      return backendAPI.post(CONSTITUENT_ENDPOINT + 'merge/', values)
    })

    if (callbackSuccess) yield call(callbackSuccess)
  } catch (error) {
    console.error(error)
    if (callbackFailure) yield call(callbackFailure)
  }
}

function* startExportConstituentsSaga(action) {
  try {
    const { useFilters } = action.payload
    let url = CONSTITUENT_ENDPOINT + 'export/'
    if (useFilters) {
      const filters = yield select(state => state.directory.filters)
      url += `?${getFilterQueryString(filters)}`
    }

    const response = yield call(() => {
      return backendAPI.get(url, { responseType: 'arraybuffer' })
    })

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    yield call(downloadFile, blob, 'exported_constituents.xlsx')
  } catch (error) {
    console.error(error)
  }
  yield put(completeExportConstituents())
}

function* loadConstituentsByTagSaga(action) {
  const { tagId } = action.payload
  try {
    const response = yield call(() => {
      return backendAPI.get(CONSTITUENT_ENDPOINT + `?tags=${tagId}&limit=600`)
    })
    const { results, next, count } = response.data
    yield put(storeConstituents({ results, next, count }))
  } catch (error) {
    console.error(error)
  }
}

function* addConstituentToTagSaga(action) {
  const { tagId, constituentId } = action.payload
  try {
    const response = yield call(() =>
      backendAPI.post(CONSTITUENT_ENDPOINT + constituentId + '/add_tag/', {
        tag_id: tagId,
      })
    )
    const constituent = response.data
    const constituents = yield select(state => state.constituents.data)
    const updatedConstituents = [constituent, ...constituents]
    yield put(clearConstituents())
    yield put(
      storeConstituents({
        results: updatedConstituents,
        count: updatedConstituents.length,
      })
    )
    yield put(setConstituentTags({ count: updatedConstituents.length }))
  } catch (error) {
    console.error(error)
  }
}

function* removeConstituentFromTagSaga(action) {
  const { tagId, constituentId } = action.payload
  try {
    yield call(() =>
      backendAPI.post(CONSTITUENT_ENDPOINT + constituentId + '/remove_tag/', {
        tag_id: tagId,
      })
    )
    const constituents = yield select(state => state.constituents.data)
    const updatedConstituents = constituents.filter(c => c.id !== constituentId)
    yield put(clearConstituents())
    yield put(
      storeConstituents({
        results: updatedConstituents,
        count: updatedConstituents.length,
      })
    )
    yield put(setConstituentTags({ count: updatedConstituents.length }))
  } catch (error) {
    console.error(error)
  }
}

function* constituentAPI() {
  yield takeLatest(loadConstituents, loadConstituentsSaga)
  yield takeLatest(loadConstituentInstance, loadConstituentInstanceSaga)
  yield takeLatest(loadConstituentOptions, loadConstituentOptionsSaga)
  yield takeLatest(paginateConstituentOptions, paginateConstituentOptionsSaga)
  yield takeLatest(createConstituentTag, createConstituentTagSaga)
  yield takeLatest(deleteConstituentTag, deleteConstituentTagSaga)
  yield takeLatest(updateConstituentTag, updateConstituentTagSaga)
  yield takeLatest(loadConstituentTags, loadConstituentTagsSaga)

  yield takeLatest(loadConstituentsByTag, loadConstituentsByTagSaga)
  yield takeLatest(addConstituentToTag, addConstituentToTagSaga)
  yield takeLatest(removeConstituentFromTag, removeConstituentFromTagSaga)

  yield takeLatest(paginateConstituentTags, paginateConstituentTagsSaga)
  yield takeLatest(paginate, paginateSaga)

  yield takeLatest(createConstituent, createConstituentSaga)
  yield takeLatest(updateConstituent, updateConstituentSaga)
  yield takeLatest(deleteConstituent, deleteConstituentSaga)
  yield takeLatest(mergeConstituents, mergeConstituentsSaga)

  yield takeLatest(startExportConstituents, startExportConstituentsSaga)
}

export default constituentAPI

export { CONSTITUENT_ENDPOINT }
