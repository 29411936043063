import React from 'react'

import { Input, useToast } from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import {
  FILE_SIZE_LIMIT_BYTES,
  FILE_SIZE_LIMIT_MB,
} from '../../../constants/casework'
import { calculateMS } from '../../../utils'
import { Attachments } from '../../casework'
const AttachmentsField = () => {
  const toast = useToast()

  const { values, setFieldValue } = useFormikContext()
  const attachments = values.attachments || []

  const cancelAdd = index => {
    setFieldValue(
      'attachments',
      attachments.filter((_, i) => i !== index)
    )
  }

  const removeAttachment = file => {
    let existingAttachment = attachments.find(a => a.id === file.id)
    if (existingAttachment) {
      let updatedAttachment = { ...existingAttachment, status: 'remove' }
      setFieldValue(
        'attachments',
        attachments.map(a => (a.id === file.id ? updatedAttachment : a))
      )
    } else {
      setFieldValue(
        'attachments',
        attachments.filter(a => a.id !== file.id)
      )
    }
  }

  const togglePIIFlag = (e, index) => {
    let checked = e.target.checked
    setFieldValue(
      `attachments`,
      attachments.map((a, idx) =>
        idx === index ? { ...a, contains_pii: checked } : a
      )
    )
  }

  const handleFileNameChange = (value, index) => {
    setFieldValue(`attachments.${index}.file_name`, value)
  }

  return (
    <>
      <Input
        type='file'
        multiple
        p={1}
        onChange={e => {
          let added_files = []
          for (let file of e.target.files) {
            if (file.size > FILE_SIZE_LIMIT_BYTES) {
              toast({
                title: 'Maximum file size exceeded!',
                description: `${file.name} is too large. Maximum file size of ${FILE_SIZE_LIMIT_MB} MB.`,
                status: 'warning',
                duration: calculateMS(5),
                isClosable: true,
              })
            } else {
              added_files.push({
                file: file,
                file_name: file.name,
                contains_pii: false,
                status: 'new',
              })
            }
          }
          setFieldValue('attachments', [...attachments, ...added_files])
        }}
      />
      <Attachments
        isEditing
        attachments={attachments}
        onCancelAdd={cancelAdd}
        onRemoveAttachment={removeAttachment}
        handleFlagChange={togglePIIFlag}
        handleFileNameChange={handleFileNameChange}
      />
    </>
  )
}

export default AttachmentsField
