import React, { useCallback, useMemo } from 'react'

import {
  Button,
  Checkbox,
  Flex,
  Select,
  Spacer,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import { useField } from 'formik'
import { useSelector } from 'react-redux'

import FormField from './FormField'
import { initialAddressValues, states } from '../../../constants'
import { isAddressEmpty } from '../../utils'

const AddressForm = ({
  field = 'address',
  fromTools,
  isPrimary,
  setPrimary,
}) => {
  const [formikField, , helpers] = useField(field)

  const { value } = formikField
  const { setValue } = helpers

  const [showLabel, setShowLabel] = useBoolean(!!value?.label)
  const [showPOBox, setShowPOBox] = useBoolean(!!value?.po_box)

  const stateOptions = useMemo(
    () => states?.map(state => state.abbreviation) || [],
    [states]
  )
  const errors = useSelector(state => state.directory.errors)

  const invalidAddress = errors === 'Generate districts failed'

  const valueIsEmpty = useMemo(() => isAddressEmpty(value), [value])

  const handleChangePrimary = useCallback(
    e => {
      if (e.target.checked) setPrimary()
    },
    [setPrimary]
  )

  const handleClearAddress = useCallback(
    () => setValue(initialAddressValues, false),
    [setValue]
  )

  const handlePOBoxBlur = useCallback(
    e => {
      const poBox = e.target.value
      const regex = /P\.?O\.?\s*Box\s*(\d+)/i
      const match = poBox.match(regex)
      if (match) {
        setValue({ ...value, po_box: `PO Box ${match[1]}` })
        return
      }
      if (/^\d+$/.test(poBox.trim())) {
        setValue({ ...value, po_box: `PO Box ${poBox.trim()}` })
      }
    },
    [setValue, value]
  )

  if (!value) return null

  return (
    <>
      {invalidAddress && fromTools && (
        <Text as='em' color='red'>
          Address yielded no results
        </Text>
      )}
      <Stack as={Flex} direction='column' flex='2'>
        {!fromTools && (
          <>
            <Stack direction={{ base: 'column', sm: 'row' }}>
              <Checkbox
                id={`show-po-box-${field}`}
                isChecked={showPOBox}
                onChange={setShowPOBox.toggle}
              >
                Add PO Box
              </Checkbox>
              <Checkbox
                id={`label-${field}`}
                isChecked={showLabel}
                onChange={setShowLabel.toggle}
              >
                Add Label
              </Checkbox>
              {!valueIsEmpty && setPrimary && (
                <Checkbox
                  id={`primary-address-${field}`}
                  isChecked={isPrimary}
                  onChange={handleChangePrimary}
                >
                  Primary Address
                </Checkbox>
              )}

              <Spacer />

              <Button
                visibility={!valueIsEmpty ? 'visible' : 'hidden'}
                variant='outline'
                size='sm'
                onClick={handleClearAddress}
              >
                Clear
              </Button>
            </Stack>
          </>
        )}
        <FormField
          field={`${field}.line1`}
          placeholder='Line 1'
          shouldOptimize
        />
        <FormField
          field={`${field}.line2`}
          placeholder='Line 2'
          shouldOptimize
        />
        {showPOBox && (
          <FormField
            field={`${field}.po_box`}
            placeholder='PO Box'
            onBlur={handlePOBoxBlur}
            shouldOptimize
          />
        )}
        <Stack direction={{ base: 'column', sm: 'row' }}>
          <FormField
            field={`${field}.city`}
            placeholder={fromTools ? 'City or Borough' : 'City'}
            shouldOptimize
          />
          <FormField
            field={`${field}.state`}
            placeholder='State'
            inputType={Select}
            options={stateOptions}
            isDisabled={fromTools}
            shouldOptimize
          />
          <FormField
            field={`${field}.zipcode`}
            placeholder='Zipcode'
            shouldOptimize
          />
        </Stack>
        {showLabel && (
          <FormField
            field={`${field}.label`}
            placeholder='Address label (e.g. Mailing Address, etc)'
            shouldOptimize
          />
        )}
      </Stack>
    </>
  )
}

export default AddressForm
