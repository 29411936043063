import React, { createContext, useContext, useState } from 'react'

import ComingSoon from '../ComingSoon'
import LandingContent from '../LandingContent'
import TagsContent from '../tags/TagsContent'
import TopicsContent from '../topics/TopicsContent'

const CompendiumContext = createContext()

export const useCompendiumContext = () => useContext(CompendiumContext)

export const CompendiumContextProvider = ({ children }) => {
  const sectionContentMap = {
    '': <LandingContent />,
    'Topics': <TopicsContent />,
    'Tags': <TagsContent />,
    'Glossary': <ComingSoon />,
    'Manual': <ComingSoon />,
  }

  const [section, setSection] = useState('')

  const sectionContent = sectionContentMap[section]

  return (
    <CompendiumContext.Provider
      value={{
        sectionContent,
        section,
        setSection,
      }}
    >
      {children}
    </CompendiumContext.Provider>
  )
}
