import React from 'react'

import { StarIcon } from '@chakra-ui/icons'
import { Flex, Icon, Text } from '@chakra-ui/react'

const PrimaryAddress = () => {
  return (
    <Flex direction='row' align='center'>
      <Icon as={StarIcon} color='blue.400' fontSize='xs' mr={1} />
      <Text variant='subheading-sm'>Primary</Text>
    </Flex>
  )
}

export default PrimaryAddress
