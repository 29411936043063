import React from 'react'

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Field } from 'formik'

import { capitalize } from '../../../utils'

const FloatingFormField = ({
  field,
  label,
  inputType = Input,
  errors,
  showErrors = true,
  validate,
  ...inputProps
}) => {
  return (
    <FormControl variant='floating' id={field} name={field} {...inputProps}>
      <Field
        placeholder=' '
        id={field}
        name={field}
        as={inputType}
        autoComplete='off'
        validate={validate}
      />
      <FormLabel htmlFor={field}>{label || capitalize(field)}</FormLabel>
      {showErrors && <FormErrorMessage>{errors}</FormErrorMessage>}
    </FormControl>
  )
}

export default FloatingFormField
