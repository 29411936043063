import React, { useCallback, useEffect } from 'react'

import {
  Badge,
  Box,
  Button,
  Icon,
  IconButton,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ExternalLinkIcon } from 'lucide-react'

import CaseworkAttachments from './assets/casework-attachments.png'
import PaginationImage from './assets/pagination.png'
import PatchNotesSection from './PatchNotesSection'

const PatchNotesModal = ({ version }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const removePreviousViewed = useCallback(() => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)

      if (key.startsWith('patchNotes-') && key.endsWith('-viewed')) {
        localStorage.removeItem(key)
      }
    }
  }, [])

  const setViewed = useCallback(() => {
    removePreviousViewed()
    localStorage.setItem(`patchNotes-${version}-viewed`, true)
    onClose()
  }, [])

  const getViewed = useCallback(() => {
    return localStorage.getItem(`patchNotes-${version}-viewed`)
  }, [])

  useEffect(() => {
    if (!getViewed()) onOpen()
  }, [])

  return (
    <>
      <IconButton
        icon={<Icon as={InformationCircleIcon} />}
        fontSize='2xl'
        onClick={onOpen}
      >
        What&apos;s New
      </IconButton>
      <Modal isOpen={isOpen} size='2xl' scrollBehavior='inside'>
        <ModalOverlay />
        <ModalCloseButton />
        <ModalContent>
          <ModalHeader>What&apos;s New</ModalHeader>
          <ModalBody>
            <Stack divider={<StackDivider />}>
              <PatchNotesSection
                title='Compendium Added'
                description=<Box>
                  <Badge
                    as={Link}
                    href='/tools/compendium'
                    target='_blank'
                    align='center'
                  >
                    Check it out now!
                    <Icon as={ExternalLinkIcon} />
                  </Badge>

                  <UnorderedList>
                    <ListItem>View and suggest topics</ListItem>
                    <ListItem>Manage casework and constituent tags</ListItem>
                  </UnorderedList>
                </Box>
              />
              <PatchNotesSection
                title='Constituents Updated'
                description=<Box>
                  <UnorderedList>
                    <ListItem>
                      Name fields consolidated into full name field
                    </ListItem>
                    <ListItem>
                      Leaving name blank will render constituent anonymous
                    </ListItem>
                    <ListItem>
                      Add category to constituent: Business or Individual
                      constituent
                    </ListItem>
                    <ListItem>
                      Added preferred method of communication option to contact
                      info
                    </ListItem>
                    <ListItem>
                      Removed extra email field and combined into contact info
                      as Email & preferred method of communication
                    </ListItem>
                    <ListItem>Added preferred language</ListItem>
                  </UnorderedList>
                </Box>
              />
              <PatchNotesSection
                title='Casework Attachments Updated'
                description=<Box pl={2}>
                  <UnorderedList>
                    <ListItem>Option to edit file name added</ListItem>
                    <ListItem>PII flag added in compliance with OGC.</ListItem>
                    <Box pl={5}>
                      <ListItem>
                        On casework closing, transferring, or archiving,
                        attachments containing PII will be deleted.
                      </ListItem>
                    </Box>
                  </UnorderedList>
                </Box>
                preview=<Box>
                  <Image
                    src={CaseworkAttachments}
                    alt='casework attachments updated'
                  />
                </Box>
              />
              <PatchNotesSection
                title='Casework Pagination Updated'
                description='Casework pagination now allows for better navigation.'
                preview=<Image
                  src={PaginationImage}
                  alt='new casework pagination bar'
                />
              />
              <PatchNotesSection
                title='Bug fixes & QOL changes'
                description=<Box pl={2}>
                  <UnorderedList>
                    <ListItem>Reports now account for updates</ListItem>
                    <ListItem>Added intake staff to casework</ListItem>
                    <ListItem>
                      Search now available for assigned casework
                    </ListItem>
                    <ListItem>
                      Casework last updated now reflects when an activity is
                      created/updated.
                    </ListItem>
                    <ListItem>
                      Added constituent filters: created at, category
                    </ListItem>
                    <ListItem>
                      Added casework filters: last updated, intake by
                    </ListItem>
                    <ListItem>
                      &quot;0 days ago&quot; now shows as &quot;Today&quot;
                      instead
                    </ListItem>
                    <ListItem>Updated language list</ListItem>
                  </UnorderedList>
                </Box>
              />
              <PatchNotesSection
                title='Developer Notes'
                description=<Box>
                  <Text>
                    Thank you for using Council Connect for your council needs.
                    The Web Development Unit strives to provide the user
                    experience you desire!
                  </Text>
                  <Box>
                    If you have any questions, concerns, or requests, contact:
                    <UnorderedList>
                      <ListItem>
                        Yogi Rosario -{' '}
                        <Link
                          color='blue.600'
                          href='mailto: yrosario-doran@council.nyc.gov'
                        >
                          yrosario-doran@council.nyc.gov
                        </Link>
                      </ListItem>
                      <ListItem>
                        Mandy Yu (Lead Developer) -{' '}
                        <Link
                          color='blue.600'
                          href='mailto: myu@council.nyc.gov'
                        >
                          myu@council.nyc.gov
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </Box>
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' onClick={setViewed}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default PatchNotesModal
