import React, { useMemo, useRef } from 'react'

import {
  Box,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { UserRoundMinusIcon } from 'lucide-react'

import { Loading, Person } from '../../../ui'
import { ConstituentSelect } from '../../../ui/select'
import { useTagsContext } from '../ContextProvider/TagsContext'

const ConstituentsDrawer = ({ isOpen, onClose }) => {
  const {
    constituentsByTag: constituents,
    selectedTag: tag,
    addConstituent,
    removeConstituent,
  } = useTagsContext()

  const constituentsRef = useRef(null)

  const selectedConstituents = useMemo(
    () => constituents.map(c => ({ label: c.name, value: c })),
    [constituents]
  )

  const handleAddConstituent = constituent => {
    addConstituent(constituent, tag)
    let constituentStack = constituentsRef.current
    if (constituentStack) {
      constituentStack.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  function ConstituentCard(constituent) {
    return (
      <Card>
        <CardBody>
          <Flex align='center'>
            <Person
              link={`/directory/constituents/${constituent.id}`}
              name={constituent.name}
              hideAvatar
            />
            <Spacer />
            <Icon
              as={UserRoundMinusIcon}
              onClick={() => removeConstituent(constituent, tag)}
              _hover={{ color: 'red', cursor: 'pointer' }}
            />
          </Flex>
        </CardBody>
      </Card>
    )
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Text variant='subheading'>{tag?.label}</Text>
          <Text variant='subheading-sm'>
            {constituents.length} Constituents
          </Text>
        </DrawerHeader>
        <DrawerCloseButton />

        <DrawerBody ref={constituentsRef}>
          {constituents?.length ? (
            <Stack>
              {constituents.map((constituent, index) => (
                <ConstituentCard
                  key={`constituent-${index}`}
                  {...constituent}
                />
              ))}
            </Stack>
          ) : constituents?.length === 0 ? (
            <></>
          ) : (
            <Loading />
          )}
        </DrawerBody>
        <DrawerFooter>
          <Box w='full'>
            <ConstituentSelect
              inputProps={{
                id: 'add-constituent-search',
                placeholder: 'Search for a constituent to add',
              }}
              onSelect={handleAddConstituent}
              districtOnly
              initialSelected={selectedConstituents}
              showTags={false}
              enableClear={false}
            />
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default ConstituentsDrawer
