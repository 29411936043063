import React from 'react'

import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import { TextSearchIcon, XIcon } from 'lucide-react'

const SearchBar = ({
  searchValue,
  setSearchValue = () => {},
  placeholder,
  id,
  inputProps,
}) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents='none' color='gray.400' fontSize='1.2em'>
        <TextSearchIcon />
      </InputLeftElement>
      <Input
        id={id}
        placeholder={placeholder}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        {...inputProps}
      />
      {searchValue !== '' && (
        <InputRightElement>
          <XIcon onClick={() => setSearchValue('')} cursor='pointer' />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default SearchBar
