import { createSlice } from '@reduxjs/toolkit'

import {
  ARCHIVED_CASEWORK_TAB,
  CASEWORK_TAB,
  STORED_CASEWORK_TAB,
  STORED_CASEWORK_FILTERS,
  STORED_ARCHIVED_CASEWORK_FILTERS,
} from '../../constants'

const previousTab = JSON.parse(sessionStorage.getItem(STORED_CASEWORK_TAB)) || 0

const getStoredFiltersKey = tab => {
  if (tab === CASEWORK_TAB) return STORED_CASEWORK_FILTERS
  if (tab === ARCHIVED_CASEWORK_TAB) return STORED_ARCHIVED_CASEWORK_FILTERS
}

const storedFilters =
  JSON.parse(sessionStorage.getItem(getStoredFiltersKey(previousTab))) || {}

const nonPersistentFilterKeys = ['search']
nonPersistentFilterKeys.forEach(key => delete storedFilters[key])

const initialState = {
  casework: [],
  requests: [],
  casework_instance: null,
  tab_index: previousTab, // casework, requests
  count: 0,
  loading: false,
  errors: null,
  page: 1,
  previous: null,
  next: null,
  filters: storedFilters,
  filters_changed: false,
}

export const caseworkSlice = createSlice({
  initialState,
  name: 'casework',
  reducers: {
    // Dashboard
    loadAssignedCasework(state) {
      state.loading = true
      state.casework = []
      state.page = 1
      state.count = 0
      state.previous = null
      state.next = null
    },

    // Casework Page
    loadCasework(state) {
      state.loading = true
      state.casework = []
    },
    storeCasework(state, action) {
      const { count, results } = action.payload
      state.loading = false
      state.casework = [...state.casework, ...results]
      state.count = count
      state.filters_changed = false
    },

    // Clean up functions
    clearCasework(state) {
      state.casework = []
      state.page = 1
      state.count = 0
      state.previous = null
      state.next = null
    },
    clearCaseworkInstance(state) {
      state.casework_instance = null
    },

    // CRUD actions for single casework
    loadCaseworkInstance(state) {
      state.loading = true
      state.errors = null
    },
    createCaseworkInstance() {},
    updateCaseworkInstance() {},
    deleteCaseworkInstance() {},
    archiveCaseworkInstance() {},
    transferCaseworkInstance() {},
    storeCaseworkInstance(state, action) {
      state.casework_instance = action.payload
      state.loading = false
      state.errors = null
    },

    startExportCasework(state) {
      state.exporting = true
    },
    completeExportCasework(state) {
      delete state.exporting
    },
    // Activity actions
    loadCaseworkActivity() {},
    createCaseworkActivity() {},
    updateCaseworkActivity() {},
    deleteCaseworkActivity() {},
    storeCaseworkActivity(state, action) {
      state.casework_instance.activity = action.payload
      state.loading = false
      state.errors = null
    },

    // Filter & Search for casework
    setCaseworkOrderBy(state, action) {
      state.loading = true
      state.filters.order_by = action.payload
      state.casework = []
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(state.filters)
      )
      state.filters_changed = true
    },
    setCaseworkSearch(state, action) {
      state.loading = true
      state.filters_changed = true
      state.filters.search = action.payload
    },
    clearCaseworkSearch(state) {
      state.loading = true
      state.filters_changed = true
      delete state.filters.search
    },
    applyCaseworkFilters(state, action) {
      state.filters_changed =
        action.payload?.filters_changed || state.filters_changed
      if (state.filters_changed) {
        state.loading = true
        state.page = 1
      }
    },
    setCaseworkFilters(state, action) {
      let updatedFilters = action.payload
      state.filters_changed = true
      Object.keys(updatedFilters).forEach(key => {
        if (
          !updatedFilters[key] ||
          (Array.isArray(updatedFilters[key]) &&
            updatedFilters[key].length === 0)
        )
          delete updatedFilters[key]
      })
      state.filters = updatedFilters
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(updatedFilters)
      )
    },
    clearCaseworkFilters(state) {
      const { order_by, search } = state.filters

      const filteredObj = {}

      if (order_by !== undefined) filteredObj.order_by = order_by

      if (search !== undefined) filteredObj.search = search

      state.filters = filteredObj
      sessionStorage.setItem(
        getStoredFiltersKey(state.tab_index),
        JSON.stringify(state.filters)
      )
      state.filters_changed = true
    },

    // Upload states
    setUploadWaiting(state, action) {
      state.attachment_uploading = action.payload // show loading for index of added casework
    },
    setUploadComplete(state, action) {
      // add index to completed uploads
      state.attachment_upload_complete = [
        ...(state.attachment_upload_complete || []),
        action.payload,
      ]
      state.attachment_uploading = null
    },
    setRemoveWaiting(state, action) {
      state.attachment_removing = action.payload
    },
    setRemoveComplete(state, action) {
      state.attachment_remove_complete = [
        ...(state.attachment_remove_complete || []),
        action.payload,
      ]
    },

    loadCaseworkTags(state) {
      state.tag_options = []
      state.tags_loading = true
      delete state.tags_count
      delete state.tags_next
    },
    createCaseworkTag() {},
    updateCaseworkTag() {},
    deleteCaseworkTag() {},
    paginateCaseworkTags() {},
    setCaseworkTags(state, action) {
      const { tags, count } = action.payload
      if (tags) state.tag_options = tags
      if (count) state.tags_count = count
    },
    storeCaseworkTags(state, action) {
      const { results, next, count } = action.payload
      state.tag_options = [...(state.tag_options || []), ...results]
      state.tags_next = next
      state.tags_count = count
      state.tags_loading = false
    },
    clearCaseworkTags(state) {
      state.tag_options = []
      delete state.tags_next
      delete state.tags_count
    },

    // Topic actions
    loadTopics() {},
    updateTopic() {},
    createTopic() {},
    storeTopics(state, action) {
      let unassignedTopic = {
        issue: '',
        topics: [{ id: -1, name: 'Unassigned' }],
      }
      state.topics = [unassignedTopic, ...action.payload]
    },

    // Global state actions
    setSuccess(state) {
      state.loading = false
      state.errors = null
      delete state.attachment_uploading
      delete state.attachment_upload_complete
      delete state.attachment_removing
      delete state.attachment_remove_complete
    },
    setErrors(state, action) {
      state.loading = false
      state.errors = action.payload
    },
    cancelLoading(state) {
      state.loading = false
    },
    paginate(state, action) {
      const { page } = action.payload
      if (typeof page === 'number' && !isNaN(page) && page > 0)
        state.page = page
      else if (page === 'next') state.page++
      else if (page === 'previous' && state.page >= 1) state.page--
      state.loading = true
      state.casework = []
    },
    setTabIndex(state, action) {
      const tab = action.payload
      state.tab_index = tab
      state.filters =
        JSON.parse(
          sessionStorage.getItem(getStoredFiltersKey(state.tab_index))
        ) || {}
      state.filters_changed = false
      sessionStorage.setItem(STORED_CASEWORK_TAB, tab)

      state.page = 1
    },
  },
})

export default caseworkSlice.reducer

export const {
  loadAssignedCasework,

  loadCasework,
  storeCasework,

  // Clean up functions
  clearCasework,
  clearCaseworkInstance,

  // CRUD actions for single casework
  loadCaseworkInstance,
  createCaseworkInstance,
  confirmCreateCasework,
  updateCaseworkInstance,
  deleteCaseworkInstance,
  archiveCaseworkInstance,
  transferCaseworkInstance,
  storeCaseworkInstance,

  startExportCasework,
  completeExportCasework,

  //CRUD actions for casework activity
  loadCaseworkActivity,
  createCaseworkActivity,
  updateCaseworkActivity,
  deleteCaseworkActivity,
  storeCaseworkActivity,

  // Filter & Search for casework
  setCaseworkOrderBy,
  setCaseworkSearch,
  clearCaseworkSearch,
  applyCaseworkFilters,
  setCaseworkFilters,
  clearCaseworkFilters,

  // Upload states
  setUploadWaiting,
  setUploadComplete,
  setRemoveWaiting,
  setRemoveComplete,

  // Casework Tag actions,
  loadCaseworkTags,
  paginateCaseworkTags,
  setCaseworkTags,
  createCaseworkTag,
  updateCaseworkTag,
  deleteCaseworkTag,
  storeCaseworkTags,
  clearCaseworkTags,

  // Topic actions,
  loadTopics,
  updateTopic,
  createTopic,
  storeTopics,

  // Global state actions
  setSuccess,
  setErrors,
  cancelLoading,
  paginate,
  setTabIndex,
} = caseworkSlice.actions
