import {
  EnvelopeIcon,
  InformationCircleIcon,
  MinusIcon,
  PencilIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline'

export const statusColors = {
  'PENDING': 'orange.400',
  'IN PROGRESS': 'purple.600',
  'ON HOLD - AGENCY': 'teal.500',
  'ON HOLD - CONSTITUENT': 'teal.500',
  'CLOSED': 'gray.700',
  'REQUESTED': 'blue.500',
  'ARCHIVED': 'gray.500',
  'NEW TRANSFER': 'green.500',
  'TRANSFERRED': 'gray.500',
}

export const darkModeStatusColors = {
  'PENDING': 'orange.500',
  'IN PROGRESS': 'purple.500',
}

export const priorityIndicatorColors = {
  'Low': {
    bgColor: 'yellow.300',
  },
  'Medium': {
    bgColor: 'orange.400',
  },
  'High': {
    bgColor: 'red.600',
    _dark: {
      bgColor: 'red.500',
    },
  },
}

export const priorityColorSchemes = {
  'Low': 'yellow',
  'Medium': 'orange',
  'High': 'red',
}

export const activityCategoryIcons = {
  'Internal': InformationCircleIcon,
  'Call': PhoneIcon,
  'Email': EnvelopeIcon,
  'Miscellaneous': MinusIcon,
  'Note': PencilIcon,
}
