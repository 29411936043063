import React, { useMemo } from 'react'

import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import ConstituentsDrawer from './ConstituentsDrawer'
import CreateTagModal from './CreateTagModal'
import TagSearchBar from './TagSearchBar'
import TagTable from './TagTable'
import { formatDistrict } from '../../../../utils'
import { Loading } from '../../../ui'
import { useTagsContext } from '../ContextProvider/TagsContext'

const TagsManager = () => {
  const user = useSelector(state => state.auth.user)
  const {
    filteredTags,
    tagsByDistrict,
    count,
    isLoading,
    constituentsIsOpen,
    closeConstituentsDrawer,
    selectedTag,
    mode,
  } = useTagsContext()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const tags = useMemo(
    () =>
      user.is_admin
        ? Object.entries(tagsByDistrict).sort(([a], [b]) => a.localeCompare(b))
        : [],
    [tagsByDistrict]
  )

  return (
    <Box>
      <Flex align='center' direction='row' w='100%' gap={3} mb={3}>
        <TagSearchBar />
        <Button onClick={onOpen}>Add New Tag</Button>
      </Flex>

      <Box w='100%' h='70vh' overflowY='auto'>
        {isLoading ? (
          <Loading />
        ) : filteredTags.length === 0 ? (
          <Text fontSize='lg' color='gray.600'>
            No tags found.
          </Text>
        ) : user.is_admin ? (
          <Stack gap={3}>
            {tags.map(([district, tags]) => (
              <Card
                key={`district-${district}-tags`}
                variant='outline'
                shadow='base'
              >
                <CardBody>
                  <Text variant='subheading-blue' fontSize='xl' pb={2}>
                    District {formatDistrict(district)}
                  </Text>
                  <TagTable tags={tags} />
                </CardBody>
              </Card>
            ))}
          </Stack>
        ) : (
          <TagTable tags={filteredTags} />
        )}
      </Box>
      <Box align='end'>
        <Text variant='subheading-gray'>{count} tags </Text>
      </Box>

      <CreateTagModal isOpen={isOpen} onClose={onClose} />
      {mode === 'constituents' && selectedTag && (
        <ConstituentsDrawer
          isOpen={constituentsIsOpen}
          onClose={() => {
            closeConstituentsDrawer()
          }}
        />
      )}
    </Box>
  )
}

export default TagsManager
