import React from 'react'

import { ThumbsDownIcon } from 'lucide-react'

import Vote from './Vote'

const Downvote = props => {
  return <Vote icon={ThumbsDownIcon} {...props} />
}

export default Downvote
