import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import TagSelect from './TagSelect'
import {
  clearCaseworkTags,
  loadCaseworkTags,
  paginateCaseworkTags,
} from '../../../redux/features/caseworkSlice'

const CaseworkTagSelect = props => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)
  useEffect(() => {
    dispatch(clearCaseworkTags())
    dispatch(
      loadCaseworkTags({
        params: {
          district: user.district,
        },
      })
    )
    return () => {
      dispatch(clearCaseworkTags())
    }
  }, [dispatch])

  const loadMoreTags = useCallback(() => {
    dispatch(paginateCaseworkTags())
  }, [])

  const tagOptions = useSelector(state => state.casework.tag_options) || []

  return (
    <TagSelect
      tagOptions={tagOptions}
      loadMoreOptions={loadMoreTags}
      {...props}
    />
  )
}

export default CaseworkTagSelect
