import React, { useMemo, useState } from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useFormikContext } from 'formik'

import { FloatingFormField } from '../../../ui/forms'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const CreateTopicFormInputs = () => {
  const { setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext()
  const { issues } = useTopicsContext()

  const issueOptions = useMemo(
    () => issues.map(issue => ({ value: issue, label: issue })),
    [issues]
  )

  const [issueMode, setIssueMode] = useState(
    issues?.find(issue => issue === values.issue) || values.issue === ''
      ? 'existing'
      : 'new'
  )

  const toggleMode = () => {
    setIssueMode(issueMode === 'existing' ? 'new' : 'existing')
  }

  return (
    <Stack gap={4}>
      <Flex justify='end' align='center' direction='row' gap={3}>
        <Text>Existing Issue</Text>
        <Switch
          isChecked={issueMode === 'new'}
          onChange={toggleMode}
          id='issue-switch'
        />
        <Text>New Issue</Text>
      </Flex>
      {issueMode === 'existing' ? (
        <Box zIndex={3}>
          <FormControl id='issue' isInvalid={errors?.issue && touched?.issue}>
            <Select
              id='issue'
              name='issue'
              placeholder='Existing Issue'
              isSearchable
              isClearable
              options={issueOptions}
              useBasicStyles
              value={
                values.issue && { label: values.issue, value: values.issue }
              }
              isInvalid={errors?.issue && touched?.issue}
              onChange={option => {
                if (option?.value) setFieldValue('issue', option.value)
                else setFieldValue('issue', '')
              }}
              onBlur={() => setFieldTouched('issue', true)}
            />
            <FormErrorMessage>{errors.issue}</FormErrorMessage>
          </FormControl>
        </Box>
      ) : (
        <FloatingFormField
          field='issue'
          label='New Issue'
          isInvalid={errors.issue && touched.issue && issueMode === 'new'}
          errors={errors.issue}
          onChange={e => setFieldValue('issue', e.target.value)}
        />
      )}
      <FloatingFormField
        field='name'
        label='Topic Name'
        isInvalid={errors.name && touched.name}
        errors={errors.name}
      />
    </Stack>
  )
}

export default CreateTopicFormInputs
