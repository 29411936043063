import React from 'react'

import { Button, useDisclosure } from '@chakra-ui/react'

import CreateOrEditConstituentDrawer from './CreateOrEditConstituentDrawer'

const CreateConstituent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button variant='solid' onClick={onOpen}>
        New Constituent
      </Button>
      <CreateOrEditConstituentDrawer
        mode='create'
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export default CreateConstituent
