import React from 'react'

import { Icon } from '@chakra-ui/icons'
import {
  IconButton,
  Tooltip as ChakraTooltip,
  useColorMode,
} from '@chakra-ui/react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

import { generateShades } from '../../utils'

const RecolorButton = ({ setColors, numShades }) => {
  const { colorMode } = useColorMode()
  return (
    <ChakraTooltip label='Recolor if hard to see'>
      <IconButton
        icon={<Icon as={ArrowPathIcon} />}
        variant='ghost'
        onClick={() => {
          setColors(generateShades(numShades, colorMode))
        }}
      />
    </ChakraTooltip>
  )
}

export default RecolorButton
