import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { caseworkSchema } from '../../constants'
import {
  loadCaseworkInstance,
  updateCaseworkInstance,
} from '../../redux/features/caseworkSlice'
import {
  AttachmentsPage,
  CaseworkFormStepper,
  CaseworkInfoPage,
  ConstituentSelectPage,
  IntakeInfoPage,
  ReviewPage,
} from '../casework'
import {
  formatFormValues,
  formatInitialValues,
} from '../casework/casework/caseworkForm/utils'
import { Loading, NotFound } from '../ui'

const EditCasework = () => {
  const { case_num } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const pageContentRef = useRef(null)
  const casework = useSelector(state => state.casework.casework_instance)
  const loading = useSelector(state => state.casework.loading)

  const [formPage, setFormPage] = useState(1) // start off with case details
  const [missingFields, setMissingFields] = useState(false)

  useEffect(() => {
    dispatch(loadCaseworkInstance({ case_num }))
  }, [dispatch])

  useEffect(() => {
    if (pageContentRef.current) pageContentRef.current.scroll({ top: 0 })
  }, [formPage, pageContentRef])

  const pages = [
    {
      title: 'Constituent',
      description: 'Review constituent',
      component: ConstituentSelectPage,
      componentProps: {
        isEditing: true,
      },
      validatePage: () => true,
    },
    {
      title: 'Casework Details',
      description: 'Provide casework information',
      component: CaseworkInfoPage,
      componentProps: {
        isEditing: true,
      },
      validatePage: (setErrors, values) => {
        let valid = true
        let errors = {}

        if (values.status === 'Closed' && values.topics.length === 0) {
          errors['topics'] = 'Topic required to close casework'
          valid = false
        }
        if (!values.details) {
          errors['details'] = 'Required field'
          valid = false
        }

        if (!valid) setErrors(errors)

        return valid
      },
    },
    {
      title: 'Intake Information',
      description: 'Provide intake information',
      component: IntakeInfoPage,
      componentProps: {},
      fields: [
        'intake_method',
        'reference_numbers',
        'referral_staff',
        'comments',
        'opened_at',
        'closed_at',
      ],
      validatePage: (setErrors, values) => {
        const { opened_at, closed_at } = values
        let valid = true
        if (!opened_at || !closed_at) return valid
        if (opened_at > closed_at) {
          valid = false
          setErrors({
            opened_at: 'Open date cannot be later than close date',
            closed_at: 'Close date cannot be earlier than open date',
          })
        }
        return valid
      },
    },
    {
      title: 'Attachments',
      description: 'Upload any relevant attachments',
      component: AttachmentsPage,
      componentProps: {},
      fields: ['attachments'],
      validatePage: () => true,
    },
    {
      title: 'Review',
      description: 'Review all details before submitting',
      component: ReviewPage,
      componentProps: {},
      fields: [],
      validatePage: () => true,
    },
  ]

  const CurrentPage = pages[formPage].component

  if (!casework && !loading) return <NotFound />

  if (loading) return <Loading />

  // Prevent uncontrolled state
  const initialValues = formatInitialValues(casework)

  const formikProps = {
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: caseworkSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const callbackSuccess = () => {
        navigate(`/casework/${case_num}`)
        setSubmitting(false)
      }

      const callbackFailure = () => {
        setSubmitting(false)
        toast({
          title: 'Failed to update casework',
          status: 'error',
          description: 'Required fields were not filled in',
        })
      }

      dispatch(
        updateCaseworkInstance({
          values: formatFormValues(values),
          callbackSuccess,
          callbackFailure,
        })
      )
    },
  }

  return (
    <>
      <Box m='auto' maxW='5xl' mt={5}>
        <Formik {...formikProps}>
          {({
            values,
            errors,
            isSubmitting,

            handleSubmit,
            setFieldValue,
            setErrors,
          }) => {
            const handleChangePage = page => {
              if (!pages[formPage].validatePage(setErrors, values))
                return setMissingFields(true)

              setMissingFields(false)
              setErrors({})

              setFormPage(page)
            }

            const handlePrevPage = () => {
              handleChangePage(formPage === 0 ? formPage : formPage - 1)
            }

            const handleNextPage = () => {
              handleChangePage(
                formPage === pages.length - 1 ? formPage : formPage + 1
              )
            }

            return (
              <>
                <CaseworkFormStepper
                  steps={pages}
                  activeStep={formPage}
                  onChangeStep={handleChangePage}
                  onNext={handleNextPage}
                  onPrevious={handlePrevPage}
                />
                <Form>
                  <Box
                    m='auto'
                    maxW='5xl'
                    my={2}
                    h='68vh'
                    overflowY='auto'
                    px={2}
                    ref={pageContentRef}
                  >
                    <CurrentPage {...pages[formPage].componentProps} />
                  </Box>

                  <Stack>
                    {formPage === pages.length - 1 &&
                      JSON.stringify(errors) !== '{}' && (
                        <Box align='end'>
                          <Text color='red'>Required fields are missing!</Text>
                        </Box>
                      )}
                    <Flex align='center' w='100%'>
                      <Button
                        visibility={formPage === 0 ? 'hidden' : 'visible'}
                        onClick={handlePrevPage}
                        variant='outline'
                      >
                        Previous Step
                      </Button>
                      <Spacer />
                      {formPage === pages.length - 1 ? (
                        <>
                          <HStack>
                            <Text>Close Casework</Text>
                            <Switch
                              id='status'
                              defaultChecked={
                                !!values.closed_at || values.status === 'Closed'
                              }
                              isDisabled={!!values.closed_at}
                              onChange={e => {
                                if (e.target.checked)
                                  setFieldValue('status', 'Closed')
                                else setFieldValue('status', casework.status)
                              }}
                            />
                          </HStack>
                          <Button
                            onClick={handleSubmit}
                            h={50}
                            isLoading={isSubmitting}
                            ml={3}
                          >
                            Update Casework
                          </Button>
                        </>
                      ) : (
                        <>
                          {missingFields && (
                            <Text color='red' mr={3}>
                              Required fields were not filled in.
                            </Text>
                          )}
                          <Button onClick={handleNextPage} variant='outline'>
                            Next Step
                          </Button>
                        </>
                      )}
                    </Flex>
                  </Stack>
                </Form>
              </>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}

export default EditCasework
