import React from 'react'

import { Card, CardBody } from '@chakra-ui/react'

import ReportSectionHeading from './ReportSectionHeading'
import SortableTable from './SortableTable'

const CaseworkIssues = ({ issues }) => {
  if (!issues || issues.length === 0) return <></>

  const valueKeys = [
    {
      label: 'Issue Category',
      value: 'issue_name',
    },
    {
      label: 'Opened',
      value: 'opened',
    },
    {
      label: 'Closed',
      value: 'closed',
    },
    {
      label: 'Updated',
      value: 'updated',
    },
  ]

  return (
    <Card maxH={625} overflowY='auto' h='100%'>
      <CardBody>
        <ReportSectionHeading>Issues</ReportSectionHeading>

        <SortableTable
          values={issues}
          valueKeys={valueKeys}
          defaultSort='opened'
        />
      </CardBody>
    </Card>
  )
}

export default CaseworkIssues
