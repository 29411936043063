import React from 'react'

import { SearchBar } from '../../../ui'
import { useTopicsContext } from '../ContextProvider/TopicsContext'

const TopicSearchBar = () => {
  const { searchValue, setSearchValue } = useTopicsContext()

  return (
    <SearchBar
      searchValue={searchValue}
      id='topics'
      setSearchValue={setSearchValue}
      placeholder='Search for a topic'
    />
  )
}

export default TopicSearchBar
