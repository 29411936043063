import React from 'react'

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import MergeConstituentForm from './MergeConstituentForm'
import MergeConstituentInfo from './MergeConstituentInfo'
import {
  constituentValidationSchema,
  formatConstituentValues,
  initialAddressValues,
} from '../../../../constants'
import {
  clearConstituents,
  loadConstituents,
  mergeConstituents,
} from '../../../../redux/features/constituentsSlice'

const MergeConstituentModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const selectedConstituents =
    useSelector(state => state.constituents.selected) || []

  const constituents = useSelector(state => state.constituents.data)
  if (!isOpen) return null

  let combination =
    selectedConstituents.length > 0
      ? { ...constituents[selectedConstituents[0]] }
      : null

  const combineConstituents = () => {
    if (selectedConstituents.length === 0) return {}
    for (let index of selectedConstituents.slice(1)) {
      let constituent = constituents[index]

      const selectOrKeepField = field => {
        combination[field] = combination[field] || constituent[field]
      }

      let nameFields = ['title', 'name', 'preferred_name', 'suffix', 'pronouns']
      nameFields.forEach(field => selectOrKeepField(field))

      if (constituent.home_address && !combination.home_address)
        combination.home_address = constituent.home_address

      if (constituent.business_address && !combination.business_address)
        combination.business_address = constituent.business_address

      constituent.languages?.forEach(language => {
        if (!combination.languages?.includes(language))
          combination.languages = [...combination.languages, language]
      })

      // Merge contact info
      constituent.contact_info?.forEach(info => {
        if (
          !combination.contact_info?.find(
            contact => contact.contact_data === info.contact_data
          )
        ) {
          combination.contact_info = [...combination.contact_info, { ...info }]
        }
      })

      // Handle Tags
      constituent.tags?.forEach(tag => {
        if (!combination.tags?.find(t => t.id === tag.id))
          combination.tags = [...combination.tags, tag]
      })
      // Handle Groups
      constituent.groups?.forEach(group => {
        if (!combination.groups?.find(t => t.id === group.id))
          combination.groups = [...combination.groups, group]
      })

      if (
        constituent.notes &&
        combination.notes.indexOf(constituent.notes) === -1
      )
        combination.notes +=
          (combination.notes?.length > 0 ? '\n-----\n' : '') + constituent.notes
    }

    if (!combination.home_address)
      combination.home_address = initialAddressValues
    if (!combination.business_address)
      combination.business_address = initialAddressValues

    if (combination.contacts?.length > 0) {
      combination.contacts = combination.contacts.map(contact => {
        let tempContact = { ...contact }
        if (!tempContact.home_address)
          tempContact.home_address = initialAddressValues
        if (!tempContact.business_address)
          tempContact.business_address = initialAddressValues
        return tempContact
      })
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    let formattedValues = formatConstituentValues(values)

    const callbackSuccess = () => {
      toast({
        title: 'Succesfully merged constituents',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
      dispatch(clearConstituents())
      dispatch(loadConstituents())
      setSubmitting(false)
    }

    const callbackFailure = () => {
      toast({
        title: 'Something went wrong trying to merge constituents',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      setSubmitting(false)
    }

    const dispatchData = {
      values: {
        merge_data: formattedValues,
        duplicates: selectedConstituents?.map(index => constituents[index].id),
      },
      callbackSuccess,
      callbackFailure,
    }

    dispatch(mergeConstituents(dispatchData))
  }

  combineConstituents()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      closeOnEsc={false}
      size='full'
      scrollBehavior='inside'
      isCentered
    >
      <ModalContent>
        <ModalHeader>
          <Heading fontSize='2xl'>Merge Constituents</Heading>
          <Text>
            Please review automatically merged information in the form. All
            casework will be moved to the merged constituent and duplicate
            constituents will be removed.
          </Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody overflowY='hidden'>
          <Formik
            validateOnChange={false}
            initialValues={combination}
            validationSchema={constituentValidationSchema}
            onSubmit={handleSubmit}
          >
            {formik => (
              <Flex direction='row' w='100%' maxH='85vh'>
                <Box w='40%' overflowY='auto' px={2}>
                  <Stack>
                    {selectedConstituents?.map(constituentIndex => (
                      <Box key={constituentIndex}>
                        <MergeConstituentInfo
                          constituent={constituents[constituentIndex]}
                          {...formik}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Box>

                <Box px={2} w='60%'>
                  <MergeConstituentForm {...formik} />
                </Box>
              </Flex>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MergeConstituentModal
