import React from 'react'

import {
  Text,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Grid,
  GridItem,
  Collapse,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { initialAddressValues } from '../../../constants'
import { addressValidationSchema } from '../../../constants/tools'
import { generateDistricts } from '../../../redux/features/toolsSlice'
import { AddressForm } from '../../ui'

const HoverableText = ({ children }) => {
  const toast = useToast()
  const target = children[0].split(':')[0]
  const copyText = value => {
    navigator.clipboard.writeText(value)
  }

  const onClick = () => {
    if (target === 'Coordinates') {
      copyText(`(${children.slice(1).join('')}`)
    } else {
      copyText(children[1])
    }
    toast({
      title: `Copied ${target} to clipboard!`,
      status: 'info',
    })
  }

  return (
    <Text fontSize='xl' _hover={{ fontWeight: 'bold' }} onClick={onClick}>
      {children}
    </Text>
  )
}

const AddressLookupTool = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.tools.loading)
  const districtInfo = useSelector(state => state.tools.district_info)
  const errors = useSelector(state => state.directory.errors)

  return (
    <Container maxW='7xl' py={10} alignContent='center'>
      <Card>
        <CardHeader
          bg='blue.400'
          borderTopRadius='xl'
          color='white'
          casing='uppercase'
          boxShadow='base'
        >
          Address Lookup Tool
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{ address: initialAddressValues }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values, { setErrors }) => {
              setErrors({})
              dispatch(generateDistricts({ values: values.address }))
            }}
            validationSchema={addressValidationSchema}
          >
            {() => (
              <Form>
                <AddressForm fromTools />
                <Button type='submit' isLoading={loading} mt={5} w='100%'>
                  Generate Districts
                </Button>
              </Form>
            )}
          </Formik>
          <Collapse in={!loading && districtInfo && !errors}>
            {!loading && districtInfo && (
              <Grid templateColumns='repeat(3, 1fr)' p={5}>
                <GridItem textTransform='uppercase' fontSize='xl'>
                  <Text> Showing Results for </Text>
                  <Text>{districtInfo.normalized_line1}</Text>
                  {districtInfo.line2 && <Text>{districtInfo.line2}</Text>}

                  <Text>
                    {districtInfo.normalized_city},&nbsp;{districtInfo.state}
                    &nbsp;
                    {districtInfo.normalized_zipcode}
                  </Text>
                </GridItem>
                <GridItem>
                  <HoverableText>
                    Coordinates: ({districtInfo?.latitude},&nbsp;
                    {districtInfo?.longitude})
                  </HoverableText>
                  <HoverableText>
                    Community Board District:&nbsp;
                    {districtInfo?.community_board_district}
                  </HoverableText>
                  <HoverableText>
                    Election District: {districtInfo.election_district}
                  </HoverableText>
                  <HoverableText>
                    Borough/Block/Lot: {districtInfo.boro_block_lot}
                  </HoverableText>
                  <HoverableText>
                    Congressional District:{' '}
                    {districtInfo.congressional_district}
                  </HoverableText>
                </GridItem>
                <GridItem>
                  <HoverableText>
                    Council District: {districtInfo.council_district}
                  </HoverableText>
                  <HoverableText>
                    School District: {districtInfo.school_district}
                  </HoverableText>
                  <HoverableText>
                    State Assembly District:&nbsp;
                    {districtInfo.state_assembly_district}
                  </HoverableText>
                  <HoverableText>
                    State Senate District: {districtInfo.state_senate_district}
                  </HoverableText>
                  <HoverableText>
                    Police Precinct: {districtInfo.police_precinct}
                  </HoverableText>
                </GridItem>
              </Grid>
            )}
          </Collapse>
        </CardBody>
      </Card>
    </Container>
  )
}

export default AddressLookupTool
