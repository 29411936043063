import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Heading,
  Stack,
  useColorModeValue,
  Center,
  Flex,
  Spacer,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import CaseworkCard from './AssignedCaseworkCard'
import AssignedCaseworkSortMenu from './AssignedCaseworkSortMenu'
import { loadAssignedCasework } from '../../redux/features/caseworkSlice'
import CaseworkPagination from '../casework/casework/CaseworkPagination'
import { Loading, NoAssignments, SearchBar } from '../ui'
import { formatCaseNum } from '../utils'

const AssignedCasework = () => {
  const dispatch = useDispatch()
  const casework = useSelector(state => state.casework.casework) || []
  const loading = useSelector(state => state.casework.loading)
  const headingColor = useColorModeValue('blue.500', 'black.100')

  const [orderBy, setOrderBy] = useState(
    localStorage.getItem('assignedCaseworkOrderBy') || '-last_updated'
  )
  const [searchValue, setSearchValue] = useState('')
  const handleOrderByChange = useCallback(value => {
    localStorage.setItem('assignedCaseworkOrderBy', value)
    setOrderBy(value)
  })
  useEffect(() => {
    dispatch(
      loadAssignedCasework({
        params: { order_by: orderBy, search: searchValue },
      })
    )
  }, [orderBy, searchValue])

  return (
    <Box>
      <Flex>
        <Heading as='h1' size='lg' mb='4' color={headingColor}>
          Assigned Casework
        </Heading>
        <Spacer />
        <AssignedCaseworkSortMenu
          orderBy={orderBy}
          setOrderBy={handleOrderByChange}
        />
      </Flex>
      <Box p={1}>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder='Search for assigned casework'
        />
      </Box>
      <Box h={{ lg: '70vh' }} maxH='70vh' overflowY='auto'>
        {loading ? (
          <Loading />
        ) : casework?.length === 0 ? (
          <Center my='auto' h='100%'>
            <NoAssignments />
          </Center>
        ) : (
          <Stack pr={2} py={2}>
            {casework?.map(c => (
              <CaseworkCard
                key={`casework-card-${c.case_num}`}
                {...c}
                formattedCaseNum={formatCaseNum(c)}
              />
            ))}
          </Stack>
        )}
      </Box>

      <Box pt={2}>
        <CaseworkPagination
          pageSize={8}
          type={'assigned'}
          params={{ order_by: orderBy }}
        />
      </Box>
    </Box>
  )
}

export default AssignedCasework
