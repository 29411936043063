import React, { useState } from 'react'

import { Flex, Text, Center, Image, Stack, HStack } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

import CaseworkList from './CaseworkBreakdown'
import CaseworkCount from './CaseworkCount'
import CaseworkIssues from './CaseworkIssues'
import DateBreakdown from './DateBreakdown'
import IntakeMethods from './IntakeMethods'
import StaffBreakdown from './StaffBreakdown'
import { Loading } from '../../ui'

const Report = () => {
  const loading = useSelector(state => state.reports.loading)
  const results = useSelector(state => state.reports.results)
  const generated = useSelector(state => state.reports.generated)
  const [caseType, setCaseType] = useState('cases')

  if (loading)
    return <Loading message={'Please be patient...Data processing...'} />
  if (!generated) return <></>
  if (!results || !results.cases.length)
    return (
      <Center as={Flex} direction='column' p={3}>
        <Image src='/assets/tools/no-data.jpg' alt='No data found' w='400px' />
        <Text variant='subheading-gray' color='red.400' p={5} align='center'>
          No data to generate report during these dates
        </Text>
      </Center>
    )

  return (
    <Stack overflow='hidden'>
      <CaseworkCount
        {...results}
        caseType={caseType}
        setCaseType={setCaseType}
      />

      <CaseworkList
        cases={results[caseType]}
        caseType={caseType}
        setCaseType={setCaseType}
      />

      <CaseworkIssues issues={results.issues_count} />

      <Flex
        justify='center'
        direction={{ base: 'column', lg: 'row' }}
        w='100%'
        as={HStack}
        bg='100%'
      >
        <IntakeMethods intakeMethods={results.intake_method_count} />

        <DateBreakdown dateBreakdown={results.date_breakdown} />
      </Flex>

      <StaffBreakdown staffBreakdown={results.staff_breakdown} />
    </Stack>
  )
}

export default Report
