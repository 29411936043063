import React from 'react'

import {
  Avatar,
  Box,
  Card,
  Flex,
  Heading,
  Show,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useSelector } from 'react-redux'

import WelcomeBannerBg from '../../assets/img/councilconnect-welcome-bg.png'
import { formatDistrict } from '../../utils'
const WelcomeBanner = () => {
  const bgGradient = useColorModeValue(
    'linear(to-r, blue.100, blue.200)',
    'linear(to-r, blue.900, blue.600)'
  )
  const textColor = useColorModeValue('nyccBlue.800', 'blue.200')
  const user = useSelector(state => state.auth.user)

  const formattedDate = format(new Date(), 'EEEE MMMM do, yyyy', {
    locale: enUS,
  })

  return (
    <Box bgGradient={bgGradient} borderRadius='md' shadow='lg'>
      <Card
        bg='transparent'
        bgImage={{ lg: `url(${WelcomeBannerBg})` }}
        bgPosition='left'
        bgRepeat='no-repeat'
        py={{ base: '4', lg: '8' }}
        ps={{ base: '4', lg: '13rem' }}
        bgSize='430px'
      >
        <Heading as='p' fontSize='3xl' lineHeight='1' mt={{ lg: 30 }}>
          Welcome to
        </Heading>
        <Heading as='p' fontSize='4xl' color={textColor} lineHeight='1'>
          Council Connect
        </Heading>

        <Flex m={{ base: 2, lg: 3 }}>
          <Show above='sm'>
            <Avatar />
          </Show>
          <Box ml={{ sm: 3 }}>
            <Text fontWeight='bold'>{user.full_name}</Text>
            <Text>
              District {formatDistrict(user.district)} - {user.role}
            </Text>
          </Box>
        </Flex>

        <Box
          position='absolute'
          bottom={0}
          right={0}
          p={{ lg: 2 }}
          visibility={{ base: 'hidden', lg: 'visible' }}
        >
          <Text variant='subheading-gray'>{formattedDate.toString()}</Text>
        </Box>
      </Card>
    </Box>
  )
}

export default WelcomeBanner
