import React, { useCallback, useEffect } from 'react'

import { Box, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import NoSearchResults from './NoSearchResults'
import SearchOption from './SearchOption'
import SearchOptions from './SearchOptions'
import SearchSelect from './SearchSelect'
import SearchSelectedTags from './SearchSelectedTags'
import AnonymousPlaceholder from '../../../components/ui/AnonymousPlaceholder'
import {
  clearConstituentOptions,
  loadConstituentOptions,
  paginateConstituentOptions,
} from '../../../redux/features/constituentsSlice'

const ConstituentSelect = ({
  onSelect = () => {},
  onRemove = () => {},
  onClear = () => {},
  districtOnly,
  constituentType,
  initialSelected,
  inputProps,
  showTags = true,
  enableClear,
}) => {
  const dispatch = useDispatch()
  const searchLoading = useSelector(state => state.constituents.options_loading)
  const constituentOptions =
    useSelector(state => state.constituents.options) || []

  useEffect(() => {
    dispatch(clearConstituentOptions())
    dispatch(
      loadConstituentOptions({
        value: '',
        districtOnly: districtOnly,
        constituentType,
      })
    )

    return () => {
      dispatch(clearConstituentOptions())
    }
  }, [])

  const debouncedHandleChange = useDebouncedCallback(e => {
    dispatch(clearConstituentOptions())
    dispatch(
      loadConstituentOptions({
        value: e.target.value,
        districtOnly: districtOnly,
        constituentType,
      })
    )
  }, 200)

  const loadMoreResults = useCallback(() => {
    dispatch(paginateConstituentOptions())
  }, [])

  const filterOptions = (searchTerm, selectedOptions) => {
    let unselectedOptions = constituentOptions.filter(
      ({ id }) => !selectedOptions.find(({ value }) => value.id === id)
    )

    if (searchTerm)
      unselectedOptions = unselectedOptions.filter(({ name }) =>
        name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    if (unselectedOptions.length === 0) return null
    return unselectedOptions
  }

  return (
    <SearchSelect>
      <SearchOptions
        loadMoreOptions={loadMoreResults}
        onClear={onClear}
        onSearch={debouncedHandleChange}
        enableClear={enableClear}
        isLoading={searchLoading}
        initialSelected={initialSelected}
        inputProps={inputProps}
      >
        {({ searchTerm, selectedOptions }) =>
          filterOptions(searchTerm, selectedOptions)?.map(
            (constituent, index) => {
              let identifier =
                constituent.home_address?.full_address ||
                constituent.business_address?.full_address ||
                constituent.contact_info.find(c => c.is_preferred_method)
                  ?.contact_data ||
                constituent.contact_info?.[0]?.contact_data
              {
                /* console.log(constituent, identifier) */
              }
              return (
                <SearchOption
                  key={`constituent-option-${index}`}
                  option={{
                    label: constituent.name || identifier || 'Anonymous',
                    value: constituent,
                  }}
                  onSelect={value =>
                    onSelect({
                      ...value,
                      identifier: constituent.name || identifier,
                    })
                  }
                >
                  <Box>
                    <Text>{constituent.name || <AnonymousPlaceholder />}</Text>
                    <Text variant='subheading-sm' casing='initial'>
                      {identifier}
                    </Text>
                  </Box>
                </SearchOption>
              )
            }
          ) || <NoSearchResults> No constituent found </NoSearchResults>
        }
      </SearchOptions>
      {showTags && <SearchSelectedTags onRemove={onRemove} />}
    </SearchSelect>
  )
}

export default ConstituentSelect
