import React from 'react'

import { Box, Center, Image, Text } from '@chakra-ui/react'
import { useRouteError } from 'react-router-dom'

const ErrorBoundary = () => {
  let error = useRouteError()
  console.error(error)
  return (
    <Center h='100vh'>
      <Box boxSize='600px'>
        <Image src='assets/error.jpg' />
        <Text align='center'>Something went wrong!</Text>
        <Text align='center'>
          Please notify the Council Connect administrators of the error.
        </Text>
      </Box>
    </Center>
  )
}

export default ErrorBoundary
