import React from 'react'

import { Icon } from '@chakra-ui/react'
import { StarIcon } from '@heroicons/react/24/outline'

const InactiveStarIcon = ({ size = 24 }) => {
  return <Icon as={StarIcon} fontSize={size} />
}

export default InactiveStarIcon
