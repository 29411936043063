import React, { useMemo } from 'react'

import { useField } from 'formik'

import { AddressField } from '.'
import { ShowHideFormField } from '../../../../ui'
import { isAddressEmpty } from '../../../../utils'

const BusinessAddressField = ({ field }) => {
  const [addressField] = useField(field + 'business_address')

  const defaultShowBusinessAddress = useMemo(
    () => !isAddressEmpty(addressField.value),
    [addressField.value]
  )

  return (
    <ShowHideFormField
      heading='Business Address Information'
      defaultShow={defaultShowBusinessAddress}
    >
      <AddressField field={field} type='business' />
    </ShowHideFormField>
  )
}

export default BusinessAddressField
